/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Handling Updates
-----------------------------------------------------------------*/
.bubble {
    position: relative;
    background: #ffffff;
    color: #000000;
    font-family: Arial;
    font-size: 16px;
    line-height: 20px;
    filter: drop-shadow(5px 5px 2px #adadad);
    text-align: center;
    width: 250px;
    height: 100px;
    border-radius: 10px;
    padding: 5%;
}
.bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: transparent transparent transparent #ffffff;
    top: 50%;
    right: -20px;
    margin-top: -10px;
}

@media (max-width: 650px) {
    .hideBGImageOnMobile 
    {
        background-image: none !important; 
    }
}

@media screen and (min-width: 1301px) {
    .rckEntranceGuardFontSize {
      font-size: 1.3vw;
    }
}

@media screen and (max-width: 1300px) {
    .rckEntranceGuardFontSize {
      font-size: 1.4vw;
    }
}


@media screen and (min-width: 1001px) {
    .rckFontSize {
      font-size: 1.5vw;
    }
}
  
@media screen and (max-width: 1000px) {
    .rckFontSize {
      font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .rckFontSize {
      font-size: 4vw;
    }
}

