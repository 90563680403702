/*-----------------------------------------------------------------------------------

	Theme Name: Canvas
	Theme URI: http://themes.semicolonweb.com/html/canvas
	Description: The Multi-Purpose Template
	Author: SemiColonWeb
	Author URI: http://themeforest.net/user/semicolonweb
	Version: 6.5.5

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------

	- Basic
	- Typography
	- Basic Layout Styles
	- Helper Classes
	- Sections
	- Columns & Grids
	- Flex Slider
	- Swiper Slider
	- Top Bar
	- Header
		- Logo
		- Primary Menu
		- Mega Menu
		- Top Search
		- Top Cart
		- Sticky Header
		- Page Menu
		- Side Header
	- Side Panel
	- Slider
	- Page Title
	- Content
	- Portfolio
	- Blog
	- Shop
	- Events
	- Shortcodes
		- Countdown
		- Buttons
		- Promo Boxes
		- Featured Boxes
		- Process Steps
		- Styled Icons
		- Toggles
		- Accordions
		- Tabs
		- Side Navigation
		- FAQ List
		- Clients
		- Testimonials
		- Team
		- Pricing Boxes
		- Counter
		- Animated Rounded Skills
		- Skills Bar
		- Dropcaps & Highlights
		- Quotes & Blockquotes
		- Text Rotater
	- Owl Carousel
	- Overlays
	- Forms
	- Google Maps
	- Heading Styles
	- Divider
	- Magazine Specific Classes
	- Go To Top
	- Error 404
	- Landing Pages
	- Preloaders
	- Footer
	- Widgets
		- Tag Cloud
		- Links
		- Testimonial & Twitter
		- Quick Contact Form
		- Newsletter
		- Twitter Feed
		- Navigation Tree
	- Wedding
	- Bootstrap Specific
	- Cookie Notification
	- Stretched Layout
	- Page Transitions
	- Youtube Video Backgrounds

---------------------------------------------------------------- */


/* ----------------------------------------------------------------
	Bootstrap Adjustments
-----------------------------------------------------------------*/

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: auto;
	}
}

@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
	}
	.card-columns.grid-2 {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}
	.card-columns > * {
		display: inline-block;
		width: 100%;
	}
}

.form-group {
	margin-bottom: 1rem;
}


/* ----------------------------------------------------------------
	Basic
-----------------------------------------------------------------*/

dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow:hidden;
}

::selection {
	background: #1ABC9C;
	color: #FFF;
	text-shadow: none;
}

::-moz-selection {
	background: #1ABC9C; /* Firefox */
	color: #FFF;
	text-shadow: none;
}

::-webkit-selection {
	background: #1ABC9C; /* Safari */
	color: #FFF;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }


/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


body {
	line-height: 1.5;
	color: #555;
	font-family: 'Lato', sans-serif;
}

a {
	text-decoration: none !important;
	color: #1ABC9C;
}

a:hover { color: #222; }

a img { border: none; }

img { max-width: 100%; }

iframe {
	width: 100%;
	border: 0 !important;
	overflow: hidden !important;
}


/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/


h1,
h2,
h3,
h4,
h5,
h6 {
	color: #444;
	font-weight: 600;
	line-height: 1.5;
	margin: 0 0 30px 0;
	font-family: 'Poppins', sans-serif;
}

h5,
h6 { margin-bottom: 20px; }

h1 { font-size: 36px; }

h2 { font-size: 30px; }

h3 { font-size: 1.5rem; }

h4 { font-size: 18px; }

h5 { font-size: 0.875rem; }

h6 { font-size: 12px; }

h4 { font-weight: 600; }

h5,
h6 { font-weight: bold; }

@media (max-width: 767.98px) {

	h1 {
		font-size: 1.75rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	h3 {
		font-size: 1.25rem;
	}

	h4 {
		font-size: 1rem;
	}

}

h1 > span:not(.nocolor):not(.badge),
h2 > span:not(.nocolor):not(.badge),
h3 > span:not(.nocolor):not(.badge),
h4 > span:not(.nocolor):not(.badge),
h5 > span:not(.nocolor):not(.badge),
h6 > span:not(.nocolor):not(.badge) { color: #1ABC9C; }

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: 30px; }

small { font-family: 'Lato', sans-serif; }


@media (max-width: 1200px) {
	.display-1 {
		font-size: calc( 1.725rem + 5.7vw );
	}
	.display-2 {
		font-size: calc( 1.675rem + 5.1vw );
	}
	.display-3 {
		font-size: calc( 1.575rem + 3.9vw );
	}
	.display-4 {
		font-size: calc( 1.475rem + 2.7vw );
	}
}


/* ----------------------------------------------------------------
	Helper Classes
-----------------------------------------------------------------*/

#wrapper {
	position: relative;
	float: none;
	width: 100%;
	margin: 0 auto;
	background-color: #FFF;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 0 10px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

@media (min-width: 1200px) {
	body:not(.stretched) #wrapper {
		max-width: 1200px;
	}
}

@media (min-width: 1440px) {
	body:not(.stretched) #wrapper {
		max-width: 1440px;
	}
}

.line,
.double-line {
	clear: both;
	position: relative;
	width: 100%;
	margin: 4rem 0;
	border-top: 1px solid #EEE;
}

.line.line-sm { margin: 2rem 0; }

span.middot {
	display: inline-block;
	margin: 0 5px;
}

.double-line { border-top: 3px double #E5E5E5; }

.nott { text-transform: none !important; }

.emptydiv {
	display: block !important;
	position: relative !important;
}

.allmargin { margin: 3rem !important; }

.leftmargin { margin-left: 3rem !important; }

.rightmargin { margin-right: 3rem !important; }

.topmargin { margin-top: 3rem !important; }

.bottommargin { margin-bottom: 3rem !important; }

.allmargin-sm { margin: 2rem !important; }

.leftmargin-sm { margin-left: 2rem !important; }

.rightmargin-sm { margin-right: 2rem !important; }

.topmargin-sm { margin-top: 2rem !important; }

.bottommargin-sm { margin-bottom: 2rem !important; }

.allmargin-lg { margin: 5rem !important; }

.leftmargin-lg { margin-left: 5rem !important; }

.rightmargin-lg { margin-right: 5rem !important; }

.topmargin-lg { margin-top: 5rem !important; }

.bottommargin-lg { margin-bottom: 5rem !important; }

.header-stick { margin-top: -3rem !important; }

.content-wrap .header-stick { margin-top: -5rem !important; }

.footer-stick { margin-bottom: -3rem !important; }

.content-wrap .footer-stick { margin-bottom: -5rem !important; }

.border-f5 { border-color: #F5F5F5 !important; }

.col-padding { padding: 4rem; }

.notextshadow { text-shadow: none !important; }

.inline-block {
	float: none !important;
	display: inline-block !important;
}

.center { text-align: center !important; }

.mx-auto {
	float: none !important;
}

.bg-color,
.bg-color #header-wrap { background-color: #1ABC9C !important; }

.color { color: #1ABC9C !important; }

.border-color { border-color: #1ABC9C !important; }

.ls0 { letter-spacing: 0px !important; }

.ls1 { letter-spacing: 1px !important; }

.ls2 { letter-spacing: 2px !important; }

.ls3 { letter-spacing: 3px !important; }

.ls4 { letter-spacing: 4px !important; }

.ls5 { letter-spacing: 5px !important; }

.noheight { height: 0 !important; }

.nolineheight { line-height: 0 !important; }

.font-body { font-family: 'Lato', sans-serif; }

.font-primary { font-family: 'Poppins', sans-serif; }

.font-secondary { font-family: 'PT Serif', serif; }

.fw-extralight {
	font-weight: 100 !important;
}

.fw-medium {
	font-weight: 500 !important;
}

.fw-semibold {
	font-weight: 600 !important;
}

.fst-normal {
	font-style: normal !important;
}

.bgicon {
	display: block;
	position: absolute;
	bottom: -60px;
	right: -50px;
	font-size: 210px;
	color: rgba(0,0,0,0.1);
}

.imagescale,
.imagescalein {
	display: block;
	overflow: hidden;
}

.imagescale img,
.imagescalein img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}

.imagescale:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.imagescalein img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.imagescalein:hover img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.grayscale {
	filter: brightness(80%) grayscale(1) contrast(90%);
	-webkit-filter: brightness(80%) grayscale(1) contrast(90%);
	-moz-filter: brightness(80%) grayscale(1) contrast(90%);
	-o-filter: brightness(80%) grayscale(1) contrast(90%);
	-ms-filter: brightness(80%) grayscale(1) contrast(90%);
	transition: 1s filter ease;
	-webkit-transition: 1s -webkit-filter ease;
	-moz-transition: 1s -moz-filter ease;
	-ms-transition: 1s -ms-filter ease;
	-o-transition: 1s -o-filter ease;
}

.grayscale:hover {
	filter: brightness(100%) grayscale(0);
	-webkit-filter: brightness(100%) grayscale(0);
	-moz-filter: brightness(100%) grayscale(0);
	-o-filter: brightness(100%) grayscale(0);
	-ms-filter: brightness(100%) grayscale(0);
}

/* MOVING BG -  TESTIMONIALS */
.bganimate {
	-webkit-animation:BgAnimated 30s infinite linear;
	 -moz-animation:BgAnimated 30s infinite linear;
		-ms-animation:BgAnimated 30s infinite linear;
		 -o-animation:BgAnimated 30s infinite linear;
			animation:BgAnimated 30s infinite linear;
}
 @-webkit-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
	}
 @-moz-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
	}
 @-ms-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
	}
 @-o-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
	}
 @keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
	}


.input-block-level {
	display: block;
	width: 100% !important;
	min-height: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.vertical-middle {
	z-index: 3;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-height: 100%;
	justify-content: center;
	flex-flow: column;
}

.vertical-middle-overlay {
	position: absolute;
	top: 0;
	left: 0;
}

.vertical-middle + .video-wrap { z-index: 2 !important; }

.magnific-max-width .mfp-content { max-width: 800px; }

.overflow-visible {
	overflow: visible !important;
}


/* ----------------------------------------------------------------
	Opacity Utility Classes
-----------------------------------------------------------------*/

.op-ts {
	-webkit-transition: opacity .5s ease;
	transition: opacity .5s ease;
}

.op-0,
.h-op-0:hover {
	opacity: 0 !important;
}

.op-01,
.h-op-01:hover {
	opacity: 0.1 !important;
}

.op-02,
.h-op-02:hover {
	opacity: 0.2 !important;
}

.op-03,
.h-op-03:hover {
	opacity: 0.3 !important;
}

.op-04,
.h-op-04:hover {
	opacity: 0.4 !important;
}

.op-05,
.h-op-05:hover {
	opacity: 0.5 !important;
}

.op-06,
.h-op-06:hover {
	opacity: 0.6 !important;
}

.op-07,
.h-op-07:hover {
	opacity: 0.7 !important;
}

.op-08,
.h-op-08:hover {
	opacity: 0.8 !important;
}

.op-09,
.h-op-09:hover {
	opacity: 0.9 !important;
}

.op-1,
.h-op-1:hover {
	opacity: 1 !important;
}


/* ----------------------------------------------------------------
	Text Color Utility Classes
-----------------------------------------------------------------*/

.text-ts {
	-webkit-transition: color .5s ease;
	transition: color .5s ease;
}

.h-text-color:hover,
a.h-text-color:hover {
	color: #1ABC9C !important;
}

.h-text-primary:hover,
a.h-text-primary:hover {
	color: #007bff !important;
}

.h-text-secondary:hover,
a.h-text-secondary:hover {
	color: #6c757d !important;
}

.h-text-success:hover,
a.h-text-success:hover {
	color: #28a745 !important;
}

.h-text-info:hover,
a.h-text-info:hover {
	color: #17a2b8 !important;
}

.h-text-warning:hover,
a.h-text-warning:hover {
	color: #ffc107 !important;
}

.h-text-danger:hover,
a.h-text-danger:hover {
	color: #dc3545 !important;
}

.h-text-light:hover,
a.h-text-light:hover {
	color: #f8f9fa !important;
}

.h-text-dark:hover,
a.h-text-dark:hover {
	color: #343a40 !important;
}


/* ----------------------------------------------------------------
	Text Size Utility Classes
-----------------------------------------------------------------*/

.text-smaller {
	font-size: 80% !important;
}

.text-larger {
	font-size: 120% !important;
}

.text-smaller small {
	font-size: 90% !important;
}


/* ----------------------------------------------------------------
	BG Color Utility Classes
-----------------------------------------------------------------*/

.bg-ts {
	-webkit-transition: background-color .5s ease;
	transition: background-color .5s ease;
}

.h-nobg:hover {
	background-color: transparent !important;
}

.h-bg-color:hover {
	background-color: #1ABC9C !important;
}

.h-bg-primary:hover {
	background-color: #007bff !important;
}

.h-bg-secondary:hover {
	background-color: #6c757d !important;
}

.h-bg-success:hover {
	background-color: #28a745 !important;
}

.h-bg-info:hover {
	background-color: #17a2b8 !important;
}

.h-bg-warning:hover {
	background-color: #ffc107 !important;
}

.h-bg-danger:hover {
	background-color: #dc3545 !important;
}

.h-bg-light:hover {
	background-color: #f8f9fa !important;
}

.h-bg-dark:hover {
	background-color: #343a40 !important;
}


.bg-clip {
	border: 1rem solid transparent;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
	transform: translate3d(0,0,0);
}

.bg-clip-sm {
	border-width: 0.5rem;
}

.bg-clip-lg {
	border-width: 2rem;
}


/* ----------------------------------------------------------------
	Gradient Utility Classes
-----------------------------------------------------------------*/

.gradient-light-grey,
.h-gradient-light-grey:hover {
	background-image: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7) !important;
	background-image: linear-gradient(to right, #2c3e50, #bdc3c7) !important;
}

.gradient-green-dark,
.h-gradient-green-dark:hover {
	background-image: -webkit-linear-gradient(to right, #99f2c8, #1f4037) !important;
	background-image: linear-gradient(to right, #99f2c8, #1f4037) !important;
}

.gradient-grey-brown,
.h-gradient-grey-brown:hover {
	background-image: -webkit-linear-gradient(to right, #C06C84, #6C5B7B, #355C7D) !important;
	background-image: linear-gradient(to right, #C06C84, #6C5B7B, #355C7D) !important;
}

.gradient-light-pink,
.h-gradient-light-pink:hover {
	background-image: -webkit-linear-gradient(to right, #ffdde1, #ee9ca7) !important;
	background-image: linear-gradient(to right, #ffdde1, #ee9ca7) !important;
}

.gradient-blue-purple,
.h-gradient-blue-purple:hover {
	background-image: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2) !important;
	background-image: linear-gradient(to right, #4A00E0, #8E2DE2) !important;
}
.gradient-multiples,
.h-gradient-multiples:hover {
	background-image: -webkit-linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%) !important;
	background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%) !important;
}

.gradient-blue-green,
.h-gradient-blue-green:hover {
	background-image: -webkit-linear-gradient(to right, #2af598 0%, #009efd 100%) !important;
	background-image: linear-gradient(to right, #2af598 0%, #009efd 100%) !important;
}

.gradient-light,
.h-gradient-light:hover {
	background-image: -webkit-linear-gradient(to top, #DDD 0%, #FFF 100%) !important;
	background-image: linear-gradient(to top, #DDD 0%, #FFF 100%) !important;
}

.gradient-grey-orange,
.h-gradient-grey-orange:hover {
	background-image: -webkit-linear-gradient(to right, #3B4371, #F3904F) !important;
	background-image: linear-gradient(to right, #3B4371, #F3904F) !important;
}

.gradient-sunset,
.h-gradient-sunset:hover {
	background-image: -webkit-linear-gradient(to top, #a8edea 0%, #fed6e3 100%) !important;
	background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%) !important;
}

.gradient-dark,
.h-gradient-dark:hover {
	background-image: -webkit-linear-gradient(to right, #535353, #000) !important;
	background-image: linear-gradient(to right, #535353, #000) !important;
}

.gradient-lemon,
.h-gradient-lemon:hover {
	background-image: -webkit-linear-gradient(to right, #cac531, #f3f9a7) !important;
	background-image: linear-gradient(to right, #cac531, #f3f9a7) !important;
}

.gradient-earth,
.h-gradient-earth:hover {
	background-image: -webkit-linear-gradient(to right, #649173, #dbd5a4) !important;
	background-image: linear-gradient(to right, #649173, #dbd5a4) !important;
}

.gradient-sky,
.h-gradient-sky:hover {
	background-image: -webkit-linear-gradient(to right, #2980b9, #6dd5fa, #F5F5F5) !important;
	background-image: linear-gradient(to right, #2980b9, #6dd5fa, #F5F5F5) !important;
}

.gradient-ocean,
.h-gradient-ocean:hover {
	background-image: -webkit-linear-gradient(to right, #000046, #1cb5e0) !important;
	background-image: linear-gradient(to right, #000046, #1cb5e0) !important;
}

.gradient-horizon,
.h-gradient-horizon:hover {
	background-image: -webkit-linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%) !important;
	background-image: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%) !important;
}

.gradient-green-blue,
.h-gradient-green-blue:hover {
	background-image: -webkit-linear-gradient(to right, #43cea2, #185a9d) !important;
	background-image: linear-gradient(to right, #43cea2, #185a9d) !important;
}

.gradient-purple-blue,
.h-gradient-purple-blue:hover {
	background-image: -webkit-linear-gradient(19deg, rgb(33, 212, 253) 0%, rgb(183, 33, 255) 100%) !important;
	background-image: linear-gradient(19deg, rgb(33, 212, 253) 0%, rgb(183, 33, 255) 100%) !important;
}

.gradient-text {
	text-shadow: none !important;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.gradient-underline {
	background-repeat: no-repeat;
	background-size: 100% 8px;
	background-position: 0 88%;
}


/* ----------------------------------------------------------------
	Border Utility Classes
-----------------------------------------------------------------*/

.border-width-0,
.h-border-width-0:hover {
	border-width: 0px !important;
}

.border-width-1,
.h-border-width-1:hover {
	border-width: 1px !important;
}

.border-width-2,
.h-border-width-2:hover {
	border-width: 2px !important;
}

.border-width-3,
.h-border-width-3:hover {
	border-width: 3px !important;
}

.border-width-4,
.h-border-width-4:hover {
	border-width: 4px !important;
}

.border-width-5,
.h-border-width-5:hover {
	border-width: 5px !important;
}

.border-width-6,
.h-border-width-6:hover {
	border-width: 6px !important;
}

.border-dotted {
	border-style: dotted !important;
}

.border-dashed {
	border-style: dashed !important;
}

.border-solid {
	border-style: solid !important;
}

.border-double {
	border-style: double !important;
}

.h-border-primary:hover {
	border-color: #007bff !important;
}

.h-border-secondary:hover {
	border-color: #6c757d !important;
}

.h-border-success:hover {
	border-color: #28a745 !important;
}

.h-border-info:hover {
	border-color: #17a2b8 !important;
}

.h-border-warning:hover {
	border-color: #ffc107 !important;
}

.h-border-danger:hover {
	border-color: #dc3545 !important;
}

.h-border-light:hover {
	border-color: #f8f9fa !important;
}

.h-border-dark:hover {
	border-color: #343a40 !important;
}

.border-transparent,
.h-border-transparent:hover {
	border-color: transparent !important;
}

.border-default,
.h-border-default:hover {
	border-color: #EEE !important;
}

.dark.border-default,
.dark .border-default,
.dark.h-border-default:hover
.dark .h-border-default:hover {
	border-color: rgba(255, 255, 255, 0.15) !important;
}

.border-ts {
	-webkit-transition: border 0.5s ease;
	transition: border 0.5s ease;
}


/* ----------------------------------------------------------------
	Rounded
-----------------------------------------------------------------*/

.rounded-4 {
	border-radius: 0.4rem !important;
}

.rounded-5 {
	border-radius: 0.5rem !important;
}

.rounded-6 {
	border-radius: 0.6rem !important;
}


/* ----------------------------------------------------------------
	Shadows
-----------------------------------------------------------------*/

.h-shadow-sm:hover {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.h-shadow:hover {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.h-shadow-lg:hover {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.h-shadow-none:hover {
	box-shadow: none !important;
}

.shadow-ts {
	-webkit-transition: box-shadow 0.5s ease;
	transition: box-shadow 0.5s ease;
}


/* ----------------------------------------------------------------
	Transform Utility Classes
-----------------------------------------------------------------*/

.translate-y-sm,
.h-translate-y-sm:hover {
	transform: translateY(-3px) !important;
}

.translate-y,
.h-translate-y:hover {
	transform: translateY(-6px) !important;
}

.translate-y-lg,
.h-translate-y-lg:hover {
	transform: translateY(-10px) !important;
}

.translate-x-sm,
.h-translate-x-sm:hover {
	transform: translateX(-3px) !important;
}

.translate-x,
.h-translate-x:hover {
	transform: translateX(-6px) !important;
}

.translate-x-lg,
.h-translate-x-lg:hover {
	transform: translateX(-10px) !important;
}

.translate-x-n-sm,
.h-translate-x-n-sm:hover {
	transform: translateX(3px) !important;
}

.translate-x-n,
.h-translate-x-n:hover {
	transform: translateX(6px) !important;
}

.translate-x-n-lg,
.h-translate-x-n-lg:hover {
	transform: translateX(10px) !important;
}

.scale-sm,
.h-scale-sm:hover {
	transform: scale(1.025) !important;
}

.scale,
.h-scale:hover {
	transform: scale(1.05) !important;
}

.scale-lg,
.h-scale-lg:hover {
	transform: scale(1.1) !important;
}

.scale-n-sm,
.h-scale-n-sm:hover {
	transform: scale(0.975) !important;
}

.scale-n,
.h-scale-n:hover {
	transform: scale(0.95) !important;
}

.scale-n-lg,
.h-scale-n-lg:hover {
	transform: scale(0.9) !important;
}

.transform-ts {
	-webkit-transition: transform 0.5s cubic-bezier(.215, .61, .355, 1);
	transition: transform 0.5s cubic-bezier(.215, .61, .355, 1);
}

.all-ts {
	-webkit-transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
	transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
}


/* ----------------------------------------------------------------
	Align Wide Utility Classes
-----------------------------------------------------------------*/

.align-wide-lg {
	margin-left: calc(50% - 480px) !important;
	margin-right: calc(50% - 480px) !important;
	max-width: 960px !important;
	width: 960px !important;
}

.align-wide-xl {
	margin-left: calc(50% - 570px) !important;
	margin-right: calc(50% - 570px) !important;
	max-width: 1140px !important;
	width: 1140px !important;
}

.align-wide-xxl {
	margin-left: calc(50% - 660px) !important;
	margin-right: calc(50% - 660px) !important;
	max-width: 1320px !important;
	width: 1320px !important;
}

@media (max-width: 991.98px) {
	.align-wide-lg,
	.align-wide-xl,
	.align-wide-xxl {
		margin-left: calc(50% - 50vw) !important;
		margin-right: calc(50% - 50vw) !important;
		max-width: 100vw !important;
		width: 100vw !important;
	}
}

.align-wide-full {
	margin-left: calc(50% - 50vw) !important;
	margin-right: calc(50% - 50vw) !important;
	max-width: 100vw !important;
	width: 100vw !important;
}


/* ----------------------------------------------------------------
	Z-Index Utility Classes
-----------------------------------------------------------------*/

.z-1 {
	z-index: 1 !important;
}

.z-2 {
	z-index: 2 !important;
}

.z-3 {
	z-index: 3 !important;
}

.z-4 {
	z-index: 4 !important;
}

.z-5 {
	z-index: 5 !important;
}

.z-6 {
	z-index: 6 !important;
}

.z-7 {
	z-index: 7 !important;
}

.z-8 {
	z-index: 8 !important;
}

.z-9 {
	z-index: 9 !important;
}

.z-10 {
	z-index: 10 !important;
}

.z-auto {
	z-index: auto !important;
}


/* ----------------------------------------------------------------
	Bootstrap Utility Classes: Extended
-----------------------------------------------------------------*/


/*	Spacing Utilities */

.m-6 {
	margin: 5rem !important;
}

.mt-6,
.my-6 {
	margin-top: 5rem !important;
}

.me-6,
.mx-6 {
	margin-right: 5rem !important;
}

.mb-6,
.my-6 {
	margin-bottom: 5rem !important;
}

.ms-6,
.mx-6 {
	margin-left: 5rem !important;
}

.p-6 {
	padding: 5rem !important;
}

.pt-6,
.py-6 {
	padding-top: 5rem !important;
}

.pe-6,
.px-6 {
	padding-right: 5rem !important;
}

.pb-6,
.py-6 {
	padding-bottom: 5rem !important;
}

.ps-6,
.px-6 {
	padding-left: 5rem !important;
}

@media (min-width: 576px) {

	.m-sm-6 {
		margin: 5rem !important;
	}

	.mt-sm-6,
	.my-sm-6 {
		margin-top: 5rem !important;
	}

	.me-sm-6,
	.mx-sm-6 {
		margin-right: 5rem !important;
	}

	.mb-sm-6,
	.my-sm-6 {
		margin-bottom: 5rem !important;
	}

	.ms-sm-6,
	.mx-sm-6 {
		margin-left: 5rem !important;
	}

	.p-sm-6 {
		padding: 5rem !important;
	}

	.pt-sm-6,
	.py-sm-6 {
		padding-top: 5rem !important;
	}

	.pe-sm-6,
	.px-sm-6 {
		padding-right: 5rem !important;
	}

	.pb-sm-6,
	.py-sm-6 {
		padding-bottom: 5rem !important;
	}

	.ps-sm-6,
	.px-sm-6 {
		padding-left: 5rem !important;
	}

}

@media (min-width: 768px) {

	.m-md-6 {
		margin: 5rem !important;
	}

	.mt-md-6,
	.my-md-6 {
		margin-top: 5rem !important;
	}

	.me-md-6,
	.mx-md-6 {
		margin-right: 5rem !important;
	}

	.mb-md-6,
	.my-md-6 {
		margin-bottom: 5rem !important;
	}

	.ms-md-6,
	.mx-md-6 {
		margin-left: 5rem !important;
	}

	.p-md-6 {
		padding: 5rem !important;
	}

	.pt-md-6,
	.py-md-6 {
		padding-top: 5rem !important;
	}

	.pe-md-6,
	.px-md-6 {
		padding-right: 5rem !important;
	}

	.pb-md-6,
	.py-md-6 {
		padding-bottom: 5rem !important;
	}

	.ps-md-6,
	.px-md-6 {
		padding-left: 5rem !important;
	}

}

@media (min-width: 992px) {

	.m-lg-6 {
		margin: 5rem !important;
	}

	.mt-lg-6,
	.my-lg-6 {
		margin-top: 5rem !important;
	}

	.me-lg-6,
	.mx-lg-6 {
		margin-right: 5rem !important;
	}

	.mb-lg-6,
	.my-lg-6 {
		margin-bottom: 5rem !important;
	}

	.ms-lg-6,
	.mx-lg-6 {
		margin-left: 5rem !important;
	}

	.p-lg-6 {
		padding: 5rem !important;
	}

	.pt-lg-6,
	.py-lg-6 {
		padding-top: 5rem !important;
	}

	.pe-lg-6,
	.px-lg-6 {
		padding-right: 5rem !important;
	}

	.pb-lg-6,
	.py-lg-6 {
		padding-bottom: 5rem !important;
	}

	.ps-lg-6,
	.px-lg-6 {
		padding-left: 5rem !important;
	}

}

@media (min-width: 1200px) {

	.m-xl-6 {
		margin: 5rem !important;
	}

	.mt-xl-6,
	.my-xl-6 {
		margin-top: 5rem !important;
	}

	.me-xl-6,
	.mx-xl-6 {
		margin-right: 5rem !important;
	}

	.mb-xl-6,
	.my-xl-6 {
		margin-bottom: 5rem !important;
	}

	.ms-xl-6,
	.mx-xl-6 {
		margin-left: 5rem !important;
	}

	.p-xl-6 {
		padding: 5rem !important;
	}

	.pt-xl-6,
	.py-xl-6 {
		padding-top: 5rem !important;
	}

	.pe-xl-6,
	.px-xl-6 {
		padding-right: 5rem !important;
	}

	.pb-xl-6,
	.py-xl-6 {
		padding-bottom: 5rem !important;
	}

	.ps-xl-6,
	.px-xl-6 {
		padding-left: 5rem !important;
	}

}


@media (min-width: 1400px) {

	.m-xxl-6 {
		margin: 5rem !important;
	}

	.mt-xxl-6,
	.my-xxl-6 {
		margin-top: 5rem !important;
	}

	.me-xxl-6,
	.mx-xxl-6 {
		margin-right: 5rem !important;
	}

	.mb-xxl-6,
	.my-xxl-6 {
		margin-bottom: 5rem !important;
	}

	.ms-xxl-6,
	.mx-xxl-6 {
		margin-left: 5rem !important;
	}

	.p-xxl-6 {
		padding: 5rem !important;
	}

	.pt-xxl-6,
	.py-xxl-6 {
		padding-top: 5rem !important;
	}

	.pe-xxl-6,
	.px-xxl-6 {
		padding-right: 5rem !important;
	}

	.pb-xxl-6,
	.py-xxl-6 {
		padding-bottom: 5rem !important;
	}

	.ps-xxl-6,
	.px-xxl-6 {
		padding-left: 5rem !important;
	}

}


/*	Custom Bootstrap Column */

[class*="col-"]:not([class*="sticky-"]):not([class*="position-"]) {
	position: relative;
}

.col-1-5 {
	flex: 0 0 auto;
	width: 20%;
}

@media (min-width: 576px) {
	.col-sm-1-5 {
		flex: 0 0 auto;
		width: 20%;
	}
}

@media (min-width: 768px) {
	.col-md-1-5 {
		flex: 0 0 auto;
		width: 20%;
	}
}

@media (min-width: 992px) {
	.col-lg-1-5 {
		flex: 0 0 auto;
		width: 20%;
	}
}

@media (min-width: 1200px) {
	.col-xl-1-5 {
		flex: 0 0 auto;
		width: 20%;
	}
}

@media (min-width: 1400px) {
	.col-xxl-1-5 {
		flex: 0 0 auto;
		width: 20%;
	}
}


/*	Gutters */
[class*="gutter-"] {
	--custom-gutter: var(--bs-gutter-x); /* Default Bootstrap */
	margin-right: calc(var(--custom-gutter) / -2);
	margin-left: calc(var(--custom-gutter) / -2);
	margin-top: calc(var(--custom-gutter) * -1);
}

[class*="gutter-"] > .col,
[class*="gutter-"] > [class*="col-"] {
	padding-right: calc(var(--custom-gutter) / 2);
	padding-left: calc(var(--custom-gutter) / 2);
	margin-top: var(--custom-gutter);
}

.gutter-10 {
	--custom-gutter: 10px;
}

.gutter-20 {
	--custom-gutter: 20px;
}

.gutter-30 {
	--custom-gutter: 30px;
}

.gutter-40 {
	--custom-gutter: 40px;
}

.gutter-50 {
	--custom-gutter: 50px;
}

[class*="col-mb-"] {
	margin-bottom: calc(var(--col-mb) / -1);
}

[class*="col-mb-"] > .col,
[class*="col-mb-"] > [class*="col-"] {
	padding-bottom: var(--col-mb);
}

.col-mb-30 {
	--col-mb: 30px;
}

.col-mb-50 {
	--col-mb: 50px;
}

.col-mb-80 {
	--col-mb: 80px;
}

@media (min-width: 576px) {
	.gutter-sm-10 {
		--custom-gutter: 10px;
	}

	.gutter-sm-20 {
		--custom-gutter: 20px;
	}

	.gutter-sm-30 {
		--custom-gutter: 30px;
	}

	.gutter-sm-40 {
		--custom-gutter: 40px;
	}

	.gutter-sm-50 {
		--custom-gutter: 50px;
	}

	.col-mb-sm-30 {
		--col-mb: 30px;
	}

	.col-mb-sm-50 {
		--col-mb: 50px;
	}

	.col-mb-sm-80 {
		--col-mb: 80px;
	}
}

@media (min-width: 768px) {
	.gutter-md-10 {
		--custom-gutter: 10px;
	}

	.gutter-md-20 {
		--custom-gutter: 20px;
	}

	.gutter-md-30 {
		--custom-gutter: 30px;
	}

	.gutter-md-40 {
		--custom-gutter: 40px;
	}

	.gutter-md-50 {
		--custom-gutter: 50px;
	}

	.col-mb-md-30 {
		--col-mb: 30px;
	}

	.col-mb-md-50 {
		--col-mb: 50px;
	}

	.col-mb-md-80 {
		--col-mb: 80px;
	}
}

@media (min-width: 992px) {
	.gutter-lg-10 {
		--custom-gutter: 10px;
	}

	.gutter-lg-20 {
		--custom-gutter: 20px;
	}

	.gutter-lg-30 {
		--custom-gutter: 30px;
	}
	.gutter-lg-40 {
		--custom-gutter: 40px;
	}
	.gutter-lg-50 {
		--custom-gutter: 50px;
	}

	.col-mb-lg-30 {
		--col-mb: 30px;
	}

	.col-mb-lg-50 {
		--col-mb: 50px;
	}

	.col-mb-lg-80 {
		--col-mb: 80px;
	}
}

@media (min-width: 1200px) {
	.gutter-xl-10 {
		--custom-gutter: 10px;
	}

	.gutter-xl-20 {
		--custom-gutter: 20px;
	}

	.gutter-xl-30 {
		--custom-gutter: 30px;
	}
	.gutter-xl-40 {
		--custom-gutter: 40px;
	}
	.gutter-xl-50 {
		--custom-gutter: 50px;
	}

	.col-mb-xl-30 {
		--col-mb: 30px;
	}

	.col-mb-xl-50 {
		--col-mb: 50px;
	}

	.col-mb-xl-80 {
		--col-mb: 80px;
	}
}

@media (min-width: 1400px) {
	.gutter-xxl-10 {
		--custom-gutter: 10px;
	}

	.gutter-xxl-20 {
		--custom-gutter: 20px;
	}

	.gutter-xxl-30 {
		--custom-gutter: 30px;
	}
	.gutter-xxl-40 {
		--custom-gutter: 40px;
	}
	.gutter-xxl-50 {
		--custom-gutter: 50px;
	}

	.col-mb-xxl-30 {
		--col-mb: 30px;
	}

	.col-mb-xxl-50 {
		--col-mb: 50px;
	}

	.col-mb-xxl-80 {
		--col-mb: 80px;
	}
}


/* ----------------------------------------------------------------
	Max Width Utility Classes
-----------------------------------------------------------------*/

.mw-xs {
	max-width: 36rem !important;
}

.mw-sm {
	max-width: 48rem !important;
}

.mw-md {
	max-width: 64rem !important;
}

.mw-lg {
	max-width: 78rem !important;
}

.mw-xl {
	max-width: 88rem !important;
}

.mw-xxl {
	max-width: 100rem !important;
}


/* ----------------------------------------------------------------
	Height Utility Classes
-----------------------------------------------------------------*/

.min-vh-75 {
	min-height: 75vh !important;
}

.min-vh-60 {
	min-height: 60vh !important;
}

.min-vh-50 {
	min-height: 50vh !important;
}

.min-vh-40 {
	min-height: 40vh !important;
}

.min-vh-25 {
	min-height: 25vh !important;
}

.min-vh-0 {
	min-height: 0 !important;
}

.vh-75 {
	height: 75vh !important;
}

.vh-60 {
	height: 60vh !important;
}

.vh-50 {
	height: 50vh !important;
}

.vh-40 {
	height: 40vh !important;
}

.vh-25 {
	height: 25vh !important;
}

.h-60 {
	height: 60% !important;
}

.h-40 {
	height: 40% !important;
}

.max-vh-100 {
	max-height: 100vh !important;
}

.max-vh-75 {
	max-height: 75vh !important;
}

.max-vh-60 {
	max-height: 60vh !important;
}

.max-vh-50 {
	max-height: 50vh !important;
}

.max-vh-40 {
	max-height: 40vh !important;
}

.max-vh-25 {
	max-height: 25vh !important;
}

.max-vh-none {
	max-height: none !important;
}

@media (min-width: 576px) {
	.min-vh-sm-100 {
		min-height: 100vh !important;
	}

	.min-vh-sm-75 {
		min-height: 75vh !important;
	}

	.min-vh-sm-60 {
		min-height: 60vh !important;
	}

	.min-vh-sm-50 {
		min-height: 50vh !important;
	}

	.min-vh-sm-40 {
		min-height: 40vh !important;
	}

	.min-vh-sm-25 {
		min-height: 25vh !important;
	}

	.min-vh-sm-0 {
		min-height: 0 !important;
	}

	.vh-sm-100 {
		height: 100vh !important;
	}

	.vh-sm-75 {
		height: 75vh !important;
	}

	.vh-sm-60 {
		height: 60vh !important;
	}

	.vh-sm-50 {
		height: 50vh !important;
	}

	.vh-sm-40 {
		height: 40vh !important;
	}

	.vh-sm-25 {
		height: 25vh !important;
	}

	.h-sm-100 {
		height: 100% !important;
	}

	.h-sm-75 {
		height: 75% !important;
	}

	.h-sm-60 {
		height: 60% !important;
	}

	.h-sm-50 {
		height: 50% !important;
	}

	.h-sm-40 {
		height: 40% !important;
	}

	.h-sm-25 {
		height: 25% !important;
	}

	.h-sm-auto {
		height: auto !important;
	}

	.max-vh-sm-100 {
		max-height: 100vh !important;
	}

	.max-vh-sm-75 {
		max-height: 75vh !important;
	}

	.max-vh-sm-60 {
		max-height: 60vh !important;
	}

	.max-vh-sm-50 {
		max-height: 50vh !important;
	}

	.max-vh-sm-40 {
		max-height: 40vh !important;
	}

	.max-vh-sm-25 {
		max-height: 25vh !important;
	}

	.max-vh-sm-none {
		max-height: none !important;
	}
}

@media (min-width: 768px) {
	.min-vh-md-100 {
		min-height: 100vh !important;
	}

	.min-vh-md-75 {
		min-height: 75vh !important;
	}

	.min-vh-md-60 {
		min-height: 60vh !important;
	}

	.min-vh-md-50 {
		min-height: 50vh !important;
	}

	.min-vh-md-40 {
		min-height: 40vh !important;
	}

	.min-vh-md-25 {
		min-height: 25vh !important;
	}

	.min-vh-md-0 {
		min-height: 0 !important;
	}

	.vh-md-100 {
		height: 100vh !important;
	}

	.vh-md-75 {
		height: 75vh !important;
	}

	.vh-md-60 {
		height: 60vh !important;
	}

	.vh-md-50 {
		height: 50vh !important;
	}

	.vh-md-40 {
		height: 40vh !important;
	}

	.vh-md-25 {
		height: 25vh !important;
	}

	.h-md-100 {
		height: 100% !important;
	}

	.h-md-75 {
		height: 75% !important;
	}

	.h-md-60 {
		height: 60% !important;
	}

	.h-md-50 {
		height: 50% !important;
	}

	.h-md-40 {
		height: 40% !important;
	}

	.h-md-25 {
		height: 25% !important;
	}

	.h-md-auto {
		height: auto !important;
	}

	.max-vh-md-100 {
		max-height: 100vh !important;
	}

	.max-vh-md-75 {
		max-height: 75vh !important;
	}

	.max-vh-md-60 {
		max-height: 60vh !important;
	}

	.max-vh-md-50 {
		max-height: 50vh !important;
	}

	.max-vh-md-40 {
		max-height: 40vh !important;
	}

	.max-vh-md-25 {
		max-height: 25vh !important;
	}

	.max-vh-md-none {
		max-height: none !important;
	}
}

@media (min-width: 992px) {
	.min-vh-lg-100 {
		min-height: 100vh !important;
	}

	.min-vh-lg-75 {
		min-height: 75vh !important;
	}

	.min-vh-lg-60 {
		min-height: 60vh !important;
	}

	.min-vh-lg-50 {
		min-height: 50vh !important;
	}

	.min-vh-lg-40 {
		min-height: 40vh !important;
	}

	.min-vh-lg-25 {
		min-height: 25vh !important;
	}

	.min-vh-lg-0 {
		min-height: 0 !important;
	}

	.vh-lg-100 {
		height: 100vh !important;
	}

	.vh-lg-75 {
		height: 75vh !important;
	}

	.vh-lg-60 {
		height: 60vh !important;
	}

	.vh-lg-50 {
		height: 50vh !important;
	}

	.vh-lg-40 {
		height: 40vh !important;
	}

	.vh-lg-25 {
		height: 25vh !important;
	}

	.h-lg-100 {
		height: 100% !important;
	}

	.h-lg-75 {
		height: 75% !important;
	}

	.h-lg-60 {
		height: 60% !important;
	}

	.h-lg-50 {
		height: 50% !important;
	}

	.h-lg-40 {
		height: 40% !important;
	}

	.h-lg-25 {
		height: 25% !important;
	}

	.h-lg-auto {
		height: auto !important;
	}

	.max-vh-lg-100 {
		max-height: 100vh !important;
	}

	.max-vh-lg-75 {
		max-height: 75vh !important;
	}

	.max-vh-lg-60 {
		max-height: 60vh !important;
	}

	.max-vh-lg-50 {
		max-height: 50vh !important;
	}

	.max-vh-lg-40 {
		max-height: 40vh !important;
	}

	.max-vh-lg-25 {
		max-height: 25vh !important;
	}

	.max-vh-lg-none {
		max-height: none !important;
	}
}

@media (min-width: 1200px) {
	.min-vh-xl-100 {
		min-height: 100vh !important;
	}

	.min-vh-xl-75 {
		min-height: 75vh !important;
	}

	.min-vh-xl-60 {
		min-height: 60vh !important;
	}

	.min-vh-xl-50 {
		min-height: 50vh !important;
	}

	.min-vh-xl-40 {
		min-height: 40vh !important;
	}

	.min-vh-xl-25 {
		min-height: 25vh !important;
	}

	.min-vh-xl-0 {
		min-height: 0 !important;
	}

	.vh-xl-100 {
		height: 100vh !important;
	}

	.vh-xl-75 {
		height: 75vh !important;
	}

	.vh-xl-60 {
		height: 60vh !important;
	}

	.vh-xl-50 {
		height: 50vh !important;
	}

	.vh-xl-40 {
		height: 40vh !important;
	}

	.vh-xl-25 {
		height: 25vh !important;
	}

	.h-xl-100 {
		height: 100% !important;
	}

	.h-xl-75 {
		height: 75% !important;
	}

	.h-xl-60 {
		height: 60% !important;
	}

	.h-xl-50 {
		height: 50% !important;
	}

	.h-xl-40 {
		height: 40% !important;
	}

	.h-xl-25 {
		height: 25% !important;
	}

	.h-xl-auto {
		height: auto !important;
	}

	.max-vh-xl-100 {
		max-height: 100vh !important;
	}

	.max-vh-xl-75 {
		max-height: 75vh !important;
	}

	.max-vh-xl-60 {
		max-height: 60vh !important;
	}

	.max-vh-xl-50 {
		max-height: 50vh !important;
	}

	.max-vh-xl-40 {
		max-height: 40vh !important;
	}

	.max-vh-xl-25 {
		max-height: 25vh !important;
	}

	.max-vh-xl-none {
		max-height: none !important;
	}
}

@media (min-width: 1400px) {
	.min-vh-xxl-100 {
		min-height: 100vh !important;
	}

	.min-vh-xxl-75 {
		min-height: 75vh !important;
	}

	.min-vh-xxl-60 {
		min-height: 60vh !important;
	}

	.min-vh-xxl-50 {
		min-height: 50vh !important;
	}

	.min-vh-xxl-40 {
		min-height: 40vh !important;
	}

	.min-vh-xxl-25 {
		min-height: 25vh !important;
	}

	.min-vh-xxl-0 {
		min-height: 0 !important;
	}

	.vh-xxl-100 {
		height: 100vh !important;
	}

	.vh-xxl-75 {
		height: 75vh !important;
	}

	.vh-xxl-60 {
		height: 60vh !important;
	}

	.vh-xxl-50 {
		height: 50vh !important;
	}

	.vh-xxl-40 {
		height: 40vh !important;
	}

	.vh-xxl-25 {
		height: 25vh !important;
	}

	.h-xxl-100 {
		height: 100% !important;
	}

	.h-xxl-75 {
		height: 75% !important;
	}

	.h-xxl-60 {
		height: 60% !important;
	}

	.h-xxl-50 {
		height: 50% !important;
	}

	.h-xxl-40 {
		height: 40% !important;
	}

	.h-xxl-25 {
		height: 25% !important;
	}

	.h-xxl-auto {
		height: auto !important;
	}

	.max-vh-xxl-100 {
		max-height: 100vh !important;
	}

	.max-vh-xxl-75 {
		max-height: 75vh !important;
	}

	.max-vh-xxl-60 {
		max-height: 60vh !important;
	}

	.max-vh-xxl-50 {
		max-height: 50vh !important;
	}

	.max-vh-xxl-40 {
		max-height: 40vh !important;
	}

	.max-vh-xxl-25 {
		max-height: 25vh !important;
	}

	.max-vh-xxl-none {
		max-height: none !important;
	}
}


/* ----------------------------------------------------------------
	Position Utility Classes: Extended
-----------------------------------------------------------------*/

@media (min-width: 576px) {
	.position-sm-static {
		position: static !important;
	}

	.position-sm-relative {
		position: relative !important;
	}

	.position-sm-absolute {
		position: absolute !important;
	}

	.position-sm-fixed {
		position: fixed !important;
	}

	.position-sm-sticky {
		position: -webkit-sticky !important;
		position: sticky !important;
	}
}

@media (min-width: 768px) {
	.position-md-static {
		position: static !important;
	}

	.position-md-relative {
		position: relative !important;
	}

	.position-md-absolute {
		position: absolute !important;
	}

	.position-md-fixed {
		position: fixed !important;
	}

	.position-md-sticky {
		position: -webkit-sticky !important;
		position: sticky !important;
	}
}

@media (min-width: 992px) {
	.position-lg-static {
		position: static !important;
	}

	.position-lg-relative {
		position: relative !important;
	}

	.position-lg-absolute {
		position: absolute !important;
	}

	.position-lg-fixed {
		position: fixed !important;
	}

	.position-lg-sticky {
		position: -webkit-sticky !important;
		position: sticky !important;
	}
}

@media (min-width: 1200px) {
	.position-xl-static {
		position: static !important;
	}

	.position-xl-relative {
		position: relative !important;
	}

	.position-xl-absolute {
		position: absolute !important;
	}

	.position-xl-fixed {
		position: fixed !important;
	}

	.position-xl-sticky {
		position: -webkit-sticky !important;
		position: sticky !important;
	}
}

@media (min-width: 1400px) {
	.position-xxl-static {
		position: static !important;
	}

	.position-xxl-relative {
		position: relative !important;
	}

	.position-xxl-absolute {
		position: absolute !important;
	}

	.position-xxl-fixed {
		position: fixed !important;
	}

	.position-xxl-sticky {
		position: -webkit-sticky !important;
		position: sticky !important;
	}
}


/* ----------------------------------------------------------------
	X/Y Positioning Classes
-----------------------------------------------------------------*/

.pos-x-start {
	left: 0 !important;
	right: auto !important;
}

.pos-y-start {
	top: 0 !important;
	bottom: auto !important;
}

.pos-x-end {
	left: auto !important;
	right: 0 !important;
}

.pos-y-end {
	top: auto !important;
	bottom: 0 !important;
}

.pos-x-center {
	left: 50% !important;
	right: auto !important;
	transform: translateX(-50%) !important;
}

.pos-y-center {
	top: 50% !important;
	bottom: auto !important;
	transform: translateY(-50%) !important;
}

.pos-x-center.pos-y-center {
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}


/* ----------------------------------------------------------------
	Square Sizing Classes
-----------------------------------------------------------------*/

.square-xs {
	width: 1rem !important;
	height: 1rem !important;
}

.square-sm {
	width: 2rem !important;
	height: 2rem !important;
}

.square-md {
	width: 4rem !important;
	height: 4rem !important;
}

.square-lg {
	width: 6rem !important;
	height: 6rem !important;
}

.square-xl {
	width: 8rem !important;
	height: 8rem !important;
}


/* ----------------------------------------------------------------
	Text Sizing Classes
-----------------------------------------------------------------*/

.text-size-xs {
	font-size: 0.875rem !important;
}

.text-size-sm {
	font-size: 1rem !important;
}

.text-size-md {
	font-size: 1.5rem !important;
}

.text-size-lg {
	font-size: 2rem !important;
}

.text-size-xl {
	font-size: calc(1rem + 2.25vw) !important;
}

.text-size-xxl {
	font-size: calc(1rem + 3.5vw) !important;
}


/* ----------------------------------------------------------------
	Lazy Loading
-----------------------------------------------------------------*/

.lazy {
	opacity: 0;
}

.lazy:not(.initial) {
	transition: opacity 1s;
}

.lazy.initial,
.lazy.lazy-loaded,
.lazy.lazy-error {
	opacity: 1;
}

img.lazy {
	max-width: 100%;
	height: auto;
}

img.lazy:not([src]) {
	visibility: hidden;
}


/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/


.section {
	position: relative;
	width: 100%;
	margin: 60px 0;
	padding: 60px 0;
	background-color: #F9F9F9;
	overflow: hidden;
}

.parallax {
	background-color: transparent;
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
	overflow: hidden;
	will-change: auto;
}

.mobile-parallax,
.video-placeholder {
	background-size: cover !important;
	background-attachment: scroll !important;
	background-position: center center !important;
}

.section .container { z-index: 2; }

.section .container + .video-wrap { z-index: 1; }

.revealer-image {
	position: relative;
	bottom: -100px;
	transition: bottom .3s ease-in-out;
	-webkit-transition: bottom .3s ease-in-out;
	-o-transition: bottom .3s ease-in-out;
}

.section:hover .revealer-image { bottom: -50px; }


/* ----------------------------------------------------------------
	Columns & Grids
-----------------------------------------------------------------*/


.postcontent,
.sidebar {
	position: relative;
}


/* ----------------------------------------------------------------
	Flex Slider
-----------------------------------------------------------------*/


.fslider,
.fslider .flexslider,
.fslider .slider-wrap,
.fslider .slide,
.fslider .slide > a,
.fslider .slide > img,
.fslider .slide > a > img {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
}

.fslider.h-100 .flexslider,
.fslider.h-100 .slider-wrap,
.fslider.h-100 .slide {
	height: 100% !important;
}

.fslider {
	min-height: 32px;
}


.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
	outline: none;
	border: none;
}

.slider-wrap,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
	border: none;
}

.flexslider {
	position: relative;
	margin: 0;
	padding: 0;
}

.flexslider .slider-wrap > .slide {
	display: none;
	-webkit-backface-visibility: hidden;
}

.flexslider .slider-wrap img {
	width: 100%;
	display: block;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

.slider-wrap::after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slider-wrap {
	display: block;
}

* html .slider-wrap {
	height: 1%;
}

.no-js .slider-wrap > .slide:first-child {display: block;}

.flex-viewport {
	max-height: 2000px;
	-webkit-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}


/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/


#top-bar {
	position: relative;
	border-bottom: 1px solid #EEE;
	font-size: 13px;
}


/* Top Links
---------------------------------*/

.top-links {
	position: relative;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-ms-flex-positive: 0;
	flex-grow: 0;
	border-bottom: 1px solid #EEE;
}

@media (min-width: 768px) {
	.top-links {
		border-bottom: 0;
	}
}

.top-links-container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	list-style: none;
	margin-bottom: 0;
}

.top-links-item {
	position: relative;
	border-left: 1px solid #EEE;
}

.top-links-item:first-child,
.top-links-sub-menu .top-links-item { border-left: 0 !important; }

.top-links-item > a {
	display: block;
	padding: 12px;
	font-size: 0.75rem;
	line-height: 20px;
	font-weight: 700;
	text-transform: uppercase;
	color: #666;
}

.top-links-item > a > i { vertical-align: top; }

.top-links-item > a > i.icon-angle-down { margin: 0 0 0 5px !important; }

.top-links-item > a > i:first-child { margin-right: 3px; }

.top-links-item.full-icon > a > i {
	top: 2px;
	font-size: 0.875rem;
	margin: 0;
}

.top-links-item:hover { background-color: #EEE; }

.top-links-sub-menu,
.top-links-section {
	position: absolute;
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
	list-style: none;
	z-index: -1;
	line-height: 1.5;
	background: #FFF;
	border: 0;
	top: 100%;
	left: -1px;
	width: 140px;
	margin-top: 10px;
	border: 1px solid #EEE;
	border-top-color: #1ABC9C;
	box-shadow: 0px 13px 42px 11px rgba(0, 0, 0, 0.05);
	transition: opacity .25s ease, margin .2s ease;
}

.top-links:not(.on-click) .top-links-item:hover > .top-links-sub-menu,
.top-links:not(.on-click) .top-links-item:hover > .top-links-section,
.top-links.on-click .top-links-sub-menu,
.top-links.on-click .top-links-section {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
	pointer-events: auto;
	z-index: 499;
}

.top-links.on-click .top-links-sub-menu,
.top-links.on-click .top-links-section {
	display: none;
}

.top-links-sub-menu .top-links-sub-menu {
	top: -1px;
	left: 100%;
}

.top-links-sub-menu .top-links-item:not(:first-child) {
	border-top: 1px solid #F5F5F5;
}

.top-links-sub-menu .top-links-item:hover { background-color: #F9F9F9; }

.top-links-sub-menu .top-links-item > a {
	padding-top: 9px;
	padding-bottom: 9px;
	font-size: 0.6875rem;
}

.top-links-sub-menu .top-links-item > a > img {
	display: inline-block;
	position: relative;
	top: -1px;
	width: 16px;
	height: 16px;
	margin-right: 4px;
}

.top-links-sub-menu.top-demo-lang .top-links-item > a > img {
	top: 4px;
	width: 16px;
	height: 16px;
}

.top-links-sub-menu .top-links-item > a > i.icon-angle-down {
	margin: 0 !important;
	position: absolute;
	top: 50%;
	left: auto;
	right: 10px;
	transform: translateY(-50%) rotate(-90deg);
}

.top-links-section {
	padding: 25px;
	left: 0;
	width: 280px;
}

.top-links-section.menu-pos-invert {
	left: auto;
	right: 0;
}


/* Top Social
-----------------------------------------------------------------*/

#top-social {
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	margin: 0;
}

#top-social li {
	position: relative;
	list-style: none;
}

#top-social li { border-left: 1px solid #EEE; }

#top-social li:first-child { border-left: 0 !important; }

#top-social a {
	display: -ms-flexbox;
	display: flex;
	width: auto;
	overflow: hidden;
	font-weight: bold;
	color: #666;
}

#top-social li a:hover {
	color: #FFF !important;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

#top-social li .ts-icon,
#top-social li .ts-text {
	display: block;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	height: 44px;
	line-height: 44px;
}

#top-social li .ts-icon {
	width: 40px;
	text-align: center;
	font-size: 0.875rem;
}

#top-social li .ts-text {
	max-width: 0;
	white-space: nowrap;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;
}

#top-social li a:hover .ts-text {
	max-width: 200px;
	padding-right: 12px;
	-webkit-transition: all .4s ease;
	transition: all .4s ease;
}


/* Top Login
-----------------------------------------------------------------*/

#top-login { margin-bottom: 0; }

#top-login .checkbox { margin-bottom: 10px; }

#top-login .form-control { position: relative; }

#top-login .form-control:focus { border-color: #CCC; }

#top-login .input-group#top-login-username { margin-bottom: -1px; }

#top-login #top-login-username input,
#top-login #top-login-username .input-group-addon {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

#top-login .input-group#top-login-password { margin-bottom: 10px; }

#top-login #top-login-password input,
#top-login #top-login-password .input-group-addon {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}


/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/


#header {
	position: relative;
	background-color: #FFF;
	border-bottom: 1px solid #F5F5F5;
}

#header-wrap {
	position: relative;
	background-color: #FFF;
	width: 100%;
}

.header-wrap-clone {
	display: none;
}

.header-row {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

@media (min-width: 992px) {

	#header-wrap {
		position: fixed;
		height: auto;
		z-index: 299;
		box-shadow: 0 0 0 rgba(0,0,0,0.1);
		transition: height .2s ease 0s, background-color .3s ease 0s;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		will-change: left, top, transform;
	}

	#top-bar + #header:not(.sticky-header) #header-wrap,
	#slider + #header:not(.sticky-header) #header-wrap,
	.floating-header:not(.sticky-header) #header-wrap,
	div + #header-wrap,
	.no-sticky #header-wrap,
	[data-sticky-offset]:not([data-sticky-offset="full"]):not(.sticky-header) #header-wrap,
	body:not(.stretched) #header:not(.sticky-header) #header-wrap {
		position: absolute;
	}

	.header-wrap-clone {
		display: block;
		height: calc( 100px + 1px );
	}

	.header-size-sm .header-wrap-clone {
		height: calc( 60px + 1px );
	}

	.header-size-md .header-wrap-clone {
		height: calc( 80px + 1px );
	}

	.header-size-lg .header-wrap-clone {
		height: calc( 120px + 1px );
	}

	.header-row {
		position: initial;
	}

	#header.transparent-header,
	#header.transparent-header:not(.sticky-header) #header-wrap {
		background: transparent;
		border-bottom: 1px solid transparent;
		z-index: 299;
	}

	#header.transparent-header.semi-transparent #header-wrap { background-color: rgba(255,255,255,0.8); }

	#header.transparent-header.floating-header { margin-top: 60px; }

	.transparent-header.floating-header .container {
		background-color: #FFF;
		padding: 0 30px;
	}

	.transparent-header.floating-header:not(.sticky-header) .container {
		position: relative;
	}

	#header + .include-header {
		margin-top: calc( -100px - 2px );
		display: inline-block;
		vertical-align: bottom;
		width: 100%;
	}

	#header.header-size-sm + .include-header {
		margin-top: calc( -60px - 2px );
	}

	#header.header-size-md + .include-header {
		margin-top: calc( -80px - 2px );
	}

	#header.header-size-lg + .include-header {
		margin-top: calc( -120px - 2px );
	}

	#header.floating-header + .include-header {
		margin-top: calc( -160px - 2px );
	}

	#header.transparent-header + .page-title-parallax.include-header .container {
		z-index: 5;
		padding-top: 100px;
	}

	#header.full-header:not(.transparent-header) { border-bottom-color: #EEE; }

	.stretched .full-header #header-wrap > .container {
		width: 100%;
		max-width: none;
		padding: 0 30px;
	}

	#header.transparent-header.full-header #header-wrap {
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}

	#slider + #header.transparent-header.full-header #header-wrap {
		border-top: 1px solid rgba(0,0,0,0.1);
		border-bottom: none;
	}

	/* ----------------------------------------------------------------
		Sticky Header
	-----------------------------------------------------------------*/

	#logo img {
		-webkit-transition: height .4s ease, padding .4s ease, margin .4s ease, opacity .3s ease;
		transition: height .4s ease, padding .4s ease, margin .4s ease, opacity .3s ease;
	}

	#logo img { max-height: 100%; }

	.sub-menu-container,
	.mega-menu-content {
		-webkit-transition: top .4s ease;
		transition: top .4s ease;
	}

	.sticky-header #header-wrap {
		position: fixed;
		top: 0;
		left: 0;
		background-color: #FFF;
		box-shadow: 0 0 10px rgba(0,0,0,0.1);
	}

	.sticky-header.full-header #header-wrap {
		border-bottom: 1px solid #EEE;
	}

	.sticky-header.semi-transparent { background-color: transparent; }

	.semi-transparent.sticky-header #header-wrap { background-color: rgba(255,255,255,0.8) }

	/* Primary Menu - Style 3
	-----------------------------------------------------------------*/

	.sticky-header .style-3 .menu-container > .menu-item > .menu-link {
		margin: 0;
		border-radius: 0;
	}

	/* Primary Menu - Style 4
	-----------------------------------------------------------------*/

	.sticky-header .style-4 .menu-container > .menu-item > .menu-link {
		padding-top: 8px;
		padding-bottom: 8px;
		margin: 10px 0;
	}

	/* Primary Menu - Style 5
	-----------------------------------------------------------------*/

	.sticky-header-shrink .style-5 .menu-container > .menu-item { margin-left: 2px; }

	.sticky-header-shrink .style-5 .menu-container > .menu-item:first-child { margin-left: 0; }

	.sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link {
		line-height: 22px;
		padding-top: 19px;
		padding-bottom: 19px;
	}

	.sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link > div { padding: 0; }

	.sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link i:not(.icon-angle-down) {
		display: inline-block;
		width: 16px;
		top: -1px;
		margin: 0 6px 0 0;
		font-size: 0.875rem;
		line-height: 22px;
	}

}

@media (max-width: 991.98px) {

	.sticky-header #header-wrap {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 299;
		background-color: #FFF;
		border-bottom: 1px solid #EEE;
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;
	}

	.sticky-header .header-wrap-clone {
		display: block;
	}

	.sticky-header .menu-container {
		max-height: 60vh;
		margin: 1rem 0;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	body:not(.primary-menu-open) .dark.transparent-header-responsive,
	body:not(.primary-menu-open) .transparent-header-responsive {
		background-color: transparent;
		border: none;
	}

	body:not(.primary-menu-open) .semi-transparent.transparent-header-responsive {
		background-color: rgba(255,255,255,0.8);
		z-index: 1;
	}

}


/* ----------------------------------------------------------------
	Logo
-----------------------------------------------------------------*/


#logo {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-ms-flex-align: center;
	margin-right: auto;
	max-height: 100%;
}

#logo a {
	color: #000;
	font-family: 'Poppins', sans-serif;
	font-size: 36px;
}

#logo img {
	display: block;
	max-width: 100%;
	max-height: 100%;
	height: 100px;
}

.header-size-sm #header-wrap #logo img {
	height: 60px;
}

.header-size-md #header-wrap #logo img {
	height: 80px;
}

.header-size-lg #header-wrap #logo img {
	height: 120px;
}

#logo a.standard-logo {
	display: -ms-flexbox;
	display: flex;
}

#logo a.retina-logo { display: none; }

@media (min-width: 992px) {

	.full-header #logo {
		padding-right: 30px;
		border-right: 1px solid #EEE;
	}

	#header.transparent-header.full-header #logo { border-right-color: rgba(0,0,0,0.1); }

	.sticky-header-shrink #header-wrap #logo img {
		height: 60px;
	}

}


/* ----------------------------------------------------------------
	Retina Logo
-----------------------------------------------------------------*/


@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {

	#logo a.standard-logo { display: none; }

	#logo a.retina-logo {
		display: -ms-flexbox;
		display: flex;
	}

}


/* ----------------------------------------------------------------
	Header Right Area
-----------------------------------------------------------------*/


.header-extras {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	margin-bottom: 0;
}

.header-extras li {
	display: -ms-flexbox;
	display: flex;
	margin-left: 1.25rem;
	overflow: hidden;
	list-style: none;
}

.header-extras li:first-child { margin-left: 0; }

.header-extras li .he-text {
	padding-left: 10px;
	font-weight: bold;
	font-size: 0.875rem;
}

.header-extras li .he-text span {
	display: block;
	font-weight: 400;
	color: #1ABC9C;
}


/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/

.primary-menu {
	display: -ms-flexbox;
	display: flex;
	flex-basis: 100%;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-align: center;
	align-items: center;
}

.primary-menu + .primary-menu {
	border-top: 1px solid #EEE;
}

#primary-menu-trigger,
#page-menu-trigger {
	display: -ms-flex;
	display: flex;
	opacity: 1;
	pointer-events: auto;
	z-index: 1;
	cursor: pointer;
	font-size: 0.875rem;
	width: 50px;
	height: 50px;
	line-height: 50px;
	justify-content: center;
	align-items: center;
	-webkit-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

body:not(.top-search-open) .top-search-parent #primary-menu-trigger {
	opacity: 1;
	-webkit-transition: opacity .2s .2s ease, top .4s ease;
	-o-transition: opacity .2s .2s ease, top .4s ease;
	transition: opacity .2s .2s ease, top .4s ease;
}

.top-search-open .top-search-parent #primary-menu-trigger {
	opacity: 0;
}

.svg-trigger {
	width: 30px;
	height: 30px;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transition: transform 400ms;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.svg-trigger path {
	fill: none;
	transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
	stroke: #333;
	stroke-width: 5;
	stroke-linecap:round;
}

.svg-trigger path:nth-child(1) { stroke-dasharray: 40 160; }

.svg-trigger path:nth-child(2) {
	stroke-dasharray: 40 142;
	transform-origin: 50%;
	transition: transform 400ms;
}

.svg-trigger path:nth-child(3) {
	stroke-dasharray: 40 85;
	transform-origin: 50%;
	transition: transform 400ms, stroke-dashoffset 400ms;
}

.primary-menu-open .svg-trigger { transform: rotate(45deg); }

.primary-menu-open .svg-trigger path:nth-child(1),
.primary-menu-open .svg-trigger path:nth-child(3) { stroke-dashoffset: -64px; }

.primary-menu-open .svg-trigger path:nth-child(2) { transform: rotate(90deg); }

.menu-container {
	display: none;
	list-style: none;
	margin: 0;
	width: 100%;
}

.menu-item {
	position: relative;
}

.menu-item:not(:first-child) {
	border-top: 1px solid #EEE;
}

.menu-link {
	display: block;
	line-height: 22px;
	padding: 14px 5px;
	color: #444;
	font-weight: 700;
	font-size: 0.8125rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
}

.menu-link span {
	display: none;
}

.menu-link i {
	position: relative;
	top: -1px;
	font-size: 0.875rem;
	width: 16px;
	text-align: center;
	margin-right: 6px;
	vertical-align: top;
}

.menu-item:hover > .menu-link,
.menu-item.current > .menu-link {
	color: #1ABC9C;
}

.menu-item.sub-menu > .menu-link > div { }

.sub-menu-container,
.mega-menu-content {
	position: relative;
	padding-left: 15px;
	border-top: 1px solid #EEE;
	list-style: none;
	margin-bottom: 0;
}

.sub-menu-container .menu-item:not(:first-child) {
	border-top: 1px solid #EEE;
}

.sub-menu-container .menu-item > .menu-link {
	position: relative;
	padding: 11px 5px;
	font-size: 0.75rem;
	font-weight: 400;
	color: #666;
	letter-spacing: 0;
	font-family: 'Lato', sans-serif;
}

.sub-menu-container .menu-item:hover > .menu-link {
	font-weight: 700;
}

.sub-menu-container .menu-link div {
	position: relative;
}

.menu-item .sub-menu-trigger {
	display: block;
	position: absolute;
	cursor: pointer;
	outline: none;
	border: none;
	background: none;
	padding: 0;
	top: 9px;
	right: 0;
	width: 32px;
	height: 32px;
	font-size: 12px;
	line-height: 32px;
	text-align: center;
	-webkit-transition: transform .3s ease;
	transition: transform .3s ease;
}

.menu-link div > i.icon-angle-down {
	display: none;
}

.sub-menu-container .sub-menu-trigger {
	font-size: 10px;
}

.sub-menu-container .menu-item .sub-menu-trigger {
	top: 6px;
}


/* ----------------------------------------------------------------
	Mega Menu
-----------------------------------------------------------------*/

.mega-menu:not(.mega-menu-full) .mega-menu-content .container {
	max-width: none;
}

.mega-menu-content {
	padding-left: 0;
}

@media (max-width: 991.98px) {

	.primary-menu {
		max-width: 100%;
	}

	.primary-menu > .container {
		max-width: none;
		padding: 0;
	}

	.mega-menu-content .sub-menu-container {
		border-top: 1px solid #EEE;
	}

	.mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
		display: block !important;
	}

	.mega-menu-content .mega-menu-column {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		padding-right: 0;
	}

	.mega-menu-column .widget {
		padding: 30px 0;
	}

}

.mega-menu-content .mega-menu-column:first-child {
	border-top: 0;
}

/* Off Canvas Menu
-----------------------------------------------------------------*/

@media (max-width: 991.98px) {

	#header:not(.sticky-header) .mobile-menu-off-canvas .menu-container {
		position: fixed;
		display: block;
		z-index: 499;
		width: 280px;
		height: 100%;
		height: calc(100vh);
		max-height: none !important;
		margin: 0 !important;
		left: 0 !important;
		top: 0 !important;
		padding: 35px 25px 60px !important;
		background-color: #FFF;
		border-right: 1px solid #EEE !important;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		-webkit-transform: translate(-280px,0);
		-o-transform: translate(-280px,0);
		transform: translate(-280px,0);
		-webkit-backface-visibility: hidden;
		transform-style: preserve-3d;
	}

	#header:not(.sticky-header) .mobile-menu-off-canvas.from-right .menu-container {
		left: auto !important;
		right: 0;
		border-right: 0 !important;
		border-left: 1px solid #EEE !important;
		-webkit-transform: translate(280px,0);
		-o-transform: translate(280px,0);
		transform: translate(280px,0);
	}

	#header:not(.sticky-header) .mobile-menu-off-canvas .menu-container {
		-webkit-transition: -webkit-transform .3s ease-in-out, opacity .2s ease !important;
		-o-transition: -o-transform .3s ease-in-out, opacity .2s ease !important;
		transition: transform .3s ease-in-out, opacity .2s ease !important;
	}

	#header:not(.sticky-header) .mobile-menu-off-canvas .menu-container.d-block {
		-webkit-transform: translate(0,0);
		-o-transform: translate(0,0);
		transform: translate(0,0);
	}

}

@media (min-width: 992px) {

	.primary-menu {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}

	.primary-menu + .primary-menu {
		border-top: 0;
	}

	#primary-menu-trigger,
	#page-menu-trigger {
		display: none;
		opacity: 0;
		pointer-events: none;
		z-index: 0;
	}

	.menu-container:not(.mobile-primary-menu) {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center;
	}

	.full-header .primary-menu .menu-container {
		padding-right: 0.5rem;
		margin-right: 0.5rem;
		border-right: 1px solid #EEE;
	}

	.transparent-header.full-header .primary-menu .menu-container { border-right-color: rgba(0,0,0,0.1); }

	.mega-menu { position: inherit; }

	.menu-container > .menu-item:not(:first-child) {
		margin-left: 2px;
	}

	.menu-item:not(:first-child) {
		border-top: 0;
	}

	.menu-item.menu-item-important .menu-link > div::after {
		content: '';
		display: inline-block;
		position: relative;
		top: -5px;
		left: 5px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #dc3545;
	}

	.menu-link {
		padding: 39px 15px;
		-webkit-transition: margin .4s ease, padding .4s ease;
		-o-transition: margin .4s ease, padding .4s ease;
		transition: margin .4s ease, padding .4s ease;
	}

	.header-size-sm .menu-container > .menu-item > .menu-link {
		padding-top: 19px;
		padding-bottom: 19px;
	}

	.header-size-md .menu-container > .menu-item > .menu-link {
		padding-top: 29px;
		padding-bottom: 29px;
	}

	.header-size-lg .menu-container > .menu-item > .menu-link {
		padding-top: 49px;
		padding-bottom: 49px;
	}

	.sticky-header-shrink .menu-container > .menu-item > .menu-link {
		padding-top: 19px;
		padding-bottom: 19px;
	}

	.with-arrows .menu-container > .menu-item > .menu-link div > i.icon-angle-down {
		display: inline-block;
	}

	.sub-menu-container,
	.mega-menu-content {
		position: absolute;
		visibility: hidden;
		background-color: #FFF;
		box-shadow: 0px 13px 42px 11px rgba(0, 0, 0, 0.05);
		border: 1px solid #EEE;
		border-top: 2px solid #1ABC9C;
		z-index: 399;
		top: 100%;
		left: 0;
		padding: 0;
	}

	.menu-container > .menu-item:not(.mega-menu) .sub-menu-container:not([class*='col']) {
		width: 220px;
	}

	/* Sub-Menu On-Hover Dropdown */
	.primary-menu:not(.on-click) .menu-item:not(.mega-menu) .sub-menu-container,
	.primary-menu:not(.on-click) .mega-menu-content {
		display: block;
		opacity: 0;
		margin-top: 15px;
		z-index: -9999;
		pointer-events: none;
		transition: opacity .3s ease, margin .25s ease;
	}

	.primary-menu:not(.on-click) .menu-item.mega-menu .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
		margin-top: 0;
		z-index: auto;
	}

	.primary-menu:not(.on-click) .menu-item.mega-menu:hover > .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
		pointer-events: auto;
	}

	.primary-menu:not(.on-click) .menu-item:hover > .sub-menu-container,
	.primary-menu:not(.on-click) .menu-item:hover > .mega-menu-content {
		opacity: 1;
		visibility: visible;
		margin-top: 0;
		z-index: 399;
		pointer-events: auto;
	}

	.primary-menu:not(.on-click) .menu-item:not(:hover) > .sub-menu-container > *,
	.primary-menu:not(.on-click) .menu-item:not(:hover) > .mega-menu-content > * {
		display: none;
	}

	.primary-menu:not(.on-click) .menu-item.mega-menu .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) > * {
		display: block;
	}

	.sub-menu-container .sub-menu-container {
		list-style: none;
		top: -2px !important;
		left: 100%;
	}

	.sub-menu-container.menu-pos-invert,
	.mega-menu-content.menu-pos-invert {
		left: auto;
		right: 0;
	}

	.sub-menu-container .sub-menu-container.menu-pos-invert {
		right: 100%;
	}

	.sub-menu-container .menu-item:not(:first-child) {
		border-top: 0;
	}

	.sub-menu-container .menu-item > .menu-link {
		padding: 10px 15px;
		font-weight: 700;
		border: 0;
		-webkit-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}

	.sub-menu-container .menu-item > .menu-link i { vertical-align: middle; }

	.sub-menu-container .menu-item:hover > .menu-link {
		background-color: #F9F9F9;
		padding-left: 18px;
		color: #1ABC9C;
	}

	.menu-link div > i.icon-angle-down {
		top: 0;
		font-size: 0.875rem;
		margin: 0 0 0 5px;
	}

	.sub-menu-container .menu-link div > i.icon-angle-down,
	.side-header .primary-menu:not(.on-click) .menu-link i.icon-angle-down {
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		font-size: 0.875rem;
		line-height: 1;
		margin: 0;
		transform: translateY(-50%) rotate(-90deg);
	}

	.menu-item .sub-menu-trigger,
	.side-header .on-click .sub-menu-container .menu-link i.icon-angle-down:last-child {
		display: none;
	}

	/* ----------------------------------------------------------------
		Mega Menu
	-----------------------------------------------------------------*/

	.mega-menu:not(.mega-menu-full) .mega-menu-content .container {
		max-width: none;
		padding: 0 var(--bs-gutter-x, 0.75rem) !important;
		margin: 0 auto !important;
	}

	.mega-menu-content {
		margin: 0;
		width: 100%;
		max-width: none;
		left: 50%;
		transform: translateX(-50%);
	}

	.mega-menu-full .mega-menu-content {
		width: 100%;
		left: 0;
		transform: none;
	}

	.mega-menu-small {
		position: relative;
	}

	.mega-menu-small .mega-menu-content {
		left: 0;
		width: 400px;
		transform: none;
	}

	.floating-header:not(.sticky-header) .mega-menu:not(.mega-menu-small) .mega-menu-content {
		max-width: 100%;
	}

	.sub-menu-container .mega-menu {
		position: relative;
	}

	.sub-menu-container .mega-menu-small .mega-menu-content {
		top: 0;
		left: 100%;
	}

	.sub-menu-container .mega-menu-small .mega-menu-content.menu-pos-invert {
		left: auto;
		right: 100%;
	}

	.mega-menu-content.mega-menu-style-2 {
		padding: 0 10px;
	}

	.mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
		position: relative;
		display: block !important;
		visibility: visible !important;
		top: 0 !important;
		left: 0;
		min-width: inherit;
		height: auto !important;
		list-style: none;
		border: 0;
		box-shadow: none;
		background-color: transparent;
		opacity: 1 !important;
		z-index: auto;
	}

	.mega-menu-content .sub-menu-container.mega-menu-dropdown {
		padding: 0.75rem;
		width: 220px;
	}

	.mega-menu-column {
		display: block !important;
		padding-left: 0;
		padding-right: 0;
	}

	.sub-menu-container.mega-menu-column:not(:first-child) {
		border-left: 1px solid #F2F2F2;
	}

	.mega-menu-style-2 .mega-menu-column {
		padding: 30px 20px;
	}

	.mega-menu-style-2 .mega-menu-title:not(:first-child) {
		margin-top: 20px;
	}

	.mega-menu-style-2 .mega-menu-title > .menu-link {
		font-size: 0.8125rem;
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		letter-spacing: 1px;
		text-transform: uppercase !important;
		margin-bottom: 15px;
		color: #444;
		padding: 0 !important;
		line-height: 1.3 !important;
	}

	.mega-menu-style-2 .mega-menu-title:hover > .menu-link {
		background-color: transparent;
	}

	.mega-menu-style-2 .mega-menu-title > .menu-link:hover {
		color: #1ABC9C;
	}

	.mega-menu-style-2 .sub-menu-container:not(.mega-menu-dropdown) .menu-item {
		border: 0;
	}

	.mega-menu-style-2 .sub-menu-container .menu-link {
		padding-left: 5px;
		padding-top: 7px;
		padding-bottom: 7px;
	}

	.mega-menu-style-2 .sub-menu-container .menu-item:hover > .menu-link { padding-left: 12px; }

	/* Sub-Menu On-Click Dropdown */
	.on-click .sub-menu-container,
	.on-click .mega-menu-content {
		display: none;
		visibility: visible;
	}

	.on-click .menu-item:not(.mega-menu-small) .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
		display: block;
	}


	/* Primary Menu - Style 2
	-----------------------------------------------------------------*/

	.style-2 #logo {
		margin-right: 1.5rem;
	}

	.style-2 .primary-menu {
		margin-right: auto;
	}

	.style-2.menu-center .primary-menu {
		margin-right: auto;
		margin-left: auto;
	}


	/* Primary Menu - Style 3
	-----------------------------------------------------------------*/

	.style-3 .menu-container > .menu-item > .menu-link {
		margin: 28px 0;
		padding-top: 11px;
		padding-bottom: 11px;
		border-radius: 2px;
	}

	.style-3 .menu-container > .menu-item:hover > .menu-link {
		color: #444;
		background-color: #F5F5F5;
	}

	.style-3 .menu-container > .menu-item.current > .menu-link {
		color: #FFF;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
		background-color: #1ABC9C;
	}


	/* Primary Menu - Style 4
	-----------------------------------------------------------------*/

	.style-4 .menu-container > .menu-item > .menu-link {
		margin: 28px 0;
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 2px;
		border: 1px solid transparent;
	}

	.style-4 .menu-container > .menu-item:hover > .menu-link,
	.style-4 .menu-container > .menu-item.current > .menu-link { border-color: #1ABC9C; }


	/* Primary Menu - Style 5
	-----------------------------------------------------------------*/

	.style-5 .menu-container {
		padding-right: 10px;
		margin-right: 5px;
		border-right: 1px solid #EEE;
	}

	.style-5 .menu-container > .menu-item:not(:first-child) { margin-left: 15px; }

	.style-5 .menu-container > .menu-item > .menu-link {
		padding-top: 27px;
		padding-bottom: 27px;
		line-height: 1;
	}

	.style-5 .menu-container > .menu-item > .menu-link i:not(.icon-angle-down) {
		display: block;
		width: auto;
		margin: 0 0 12px;
		font-size: 20px;
		line-height: 1;
		transition: all .3s ease;
	}


	/* Primary Menu - Style 6
	-----------------------------------------------------------------*/

	.style-6 .menu-container > .menu-item > .menu-link { position: relative; }

	.style-6 .menu-container > .menu-item > .menu-link::after,
	.style-6 .menu-container > .menu-item.current > .menu-link::after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		height: 2px;
		width: 0;
		border-top: 2px solid #1ABC9C;
		-webkit-transition: width .3s ease;
		-o-transition: width .3s ease;
		transition: width .3s ease;
		transform: translateX(-50%);
	}

	.style-6 .menu-container > .menu-item.current > .menu-link::after,
	.style-6 .menu-container > .menu-item:hover > .menu-link::after { width: 100%; }


	/* Primary Menu - Sub Title
	-----------------------------------------------------------------*/

	.sub-title .menu-container > .menu-item {
		margin-left: 0;
	}

	.sub-title .menu-container > .menu-item > .menu-link {
		position: relative;
		line-height: 14px;
		padding: 32px 20px;
		text-transform: uppercase;
	}

	.sub-title .menu-container > .menu-item:not(:first-child) > .menu-link::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		z-index: -2;
		width: 1px;
		height: 36px;
		background-color: #EEE;
		transform: translateY(-50%);
	}

	.sub-title .menu-container > .menu-item > .menu-link span {
		display: block;
		margin-top: 10px;
		line-height: 12px;
		font-size: 11px;
		font-weight: 400;
		color: #888;
		text-transform: capitalize;
	}

	.sub-title .menu-container > .menu-item:hover > .menu-link,
	.sub-title .menu-container > .menu-item.current > .menu-link {
		color: #FFF;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	}

	.sub-title .menu-container > .menu-item:hover > .menu-link span,
	.sub-title .menu-container > .menu-item.current > .menu-link span { color: #EEE; }

	.sub-title .menu-container > .menu-item:hover > .menu-link::after,
	.sub-title .menu-container > .menu-item.current > .menu-link::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-color: #1ABC9C;
		border-top: 5px solid rgba(0,0,0,0.1);
	}


	/* ----------------------------------------------------------------
		Side Header
	-----------------------------------------------------------------*/

	.side-header:not(.open-header) #wrapper {
		margin: 0 0 0 260px !important;
		width: auto !important;
	}

	.side-header .container {
		padding-left: 30px;
		padding-right: 30px;
	}

	.side-header #header {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		width: 260px;
		height: 100%;
		height: calc(100vh);
		background-color: #FFF;
		border: none;
		border-right: 1px solid #EEE;
		overflow: hidden;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.065);
	}

	.side-header #header-wrap {
		position: relative;
		width: 300px;
		width: calc( 100% + 40px );
		height: 100%;
		overflow-y: scroll;
		padding-right: 40px;
	}

	.side-header #header-wrap > .container {
		width: 260px;
		max-width: none;
		padding: 0 30px;
		margin: 0 !important;
		min-height: 100%;
		display: -ms-flexbox;
		display: flex;
	}

	.side-header .header-wrap-clone {
		display: none;
	}

	.side-header .header-row {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}

	.side-header #logo {
		margin: 3rem 0;
		width: 100%;
	}

	.side-header #logo img {
		max-width: 100%;
	}

	.side-header .header-misc {
		width: 100%;
		margin: 0;
	}

	.side-header .primary-menu {
		margin-bottom: 30px;
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
		width: 100%;
	}

	.side-header .primary-menu:not(.border-bottom-0)::after { margin-top: 40px; }

	.side-header .menu-container {
		display: block;
		height: auto;
		width: 100%;
	}

	.no-triggers .menu-container .sub-menu-trigger {
		display: none;
	}

	.side-header .on-click .menu-item .sub-menu-trigger {
		display: block;
		top: 0;
		right: -5px;
		font-size: 10px;
	}

	.side-header .primary-menu:not(.on-click) .text-center i.icon-angle-down {
		display: inline-block;
		position: relative;
		top: 2px;
		margin-left: 5px;
		transform: none;
	}

	.side-header .primary-menu:not(.on-click) .text-center .sub-menu-container i.icon-angle-down {
		top: -1px;
	}

	.side-header .menu-item {
		width: 100%;
	}

	.side-header .menu-item:not(:first-child) {
		margin: 10px 0 0;
		border-top: 0;
	}

	.side-header .menu-link {
		position: relative;
		padding: 5px 0 !important;
		margin: 0 !important;
		text-transform: uppercase;
	}

	.side-header .sub-menu-container,
	.side-header .mega-menu-content {
		position: relative;
		width: 100%;
		background-color: transparent;
		box-shadow: none;
		border: none;
		height: auto;
		z-index: 1;
		top: 0;
		left: 0;
		margin: 5px 0;
		padding: 0 0 0 12px !important;
	}

	.side-header .mega-menu-content {
		padding: 0 !important;
		margin-bottom: 0 !important;
	}

	.side-header .mega-menu-column {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		border: none !important;
	}

	.side-header .sub-menu-container .sub-menu-container {
		top: 0 !important;
		left: 0;
	}

	.side-header .sub-menu-container.menu-pos-invert {
		left: 0;
		right: auto;
	}

	.side-header .sub-menu-container .sub-menu-container.menu-pos-invert { right: auto; }

	.side-header .sub-menu-container .menu-item {
		margin: 0;
		border: 0;
	}

	.side-header .sub-menu-container .menu-item:first-child { border-top: 0; }

	.side-header .menu-item:hover > .menu-link {
		background-color: transparent !important;
	}

	.side-header .menu-container > .menu-item.sub-menu > .menu-link,
	.side-header .menu-container > .menu-item.sub-menu:hover > .menu-link { background-image: none !important; }


	/* Side Header - Push Header
	-----------------------------------------------------------------*/

	.side-header.open-header.push-wrapper { overflow-x: hidden; }

	.side-header.open-header #header { left: -260px; }

	.side-header.open-header.side-header-open #header { left: 0; }

	.side-header.open-header #wrapper { left: 0; }

	.side-header.open-header.push-wrapper.side-header-open #wrapper { left: 260px; }

	#header-trigger { display: none; }

	.side-header.open-header #header-trigger {
		display: block;
		position: fixed;
		cursor: pointer;
		top: 20px;
		left: 20px;
		z-index: 11;
		font-size: 1rem;
		width: 32px;
		height: 32px;
		line-height: 32px;
		background-color: #FFF;
		text-align: center;
		border-radius: 2px;
	}

	.side-header.open-header.side-header-open #header-trigger { left: 280px; }

	.side-header.open-header #header-trigger i:nth-child(1),
	.side-header.open-header.side-header-open #header-trigger i:nth-child(2) { display: block; }

	.side-header.open-header.side-header-open #header-trigger i:nth-child(1),
	.side-header.open-header #header-trigger i:nth-child(2) { display: none; }


	.side-header.open-header #header,
	.side-header.open-header #header-trigger,
	.side-header.open-header.push-wrapper #wrapper {
		-webkit-transition: left .4s ease;
		-o-transition: left .4s ease;
		transition: left .4s ease;
	}


	/* Side Header - Right Aligned
	-----------------------------------------------------------------*/

	.side-header.side-header-right #header {
		left: auto;
		right: 0;
		border: none;
		border-left: 1px solid #EEE;
	}

	.side-header.side-header-right:not(.open-header) #wrapper { margin: 0 260px 0 0 !important; }

	.side-header.side-header-right.open-header #header {
		left: auto;
		right: -260px;
	}

	.side-header.side-header-right.open-header.side-header-open #header {
		left: auto;
		right: 0;
	}

	.side-header.side-header-right.open-header.push-wrapper.side-header-open #wrapper { left: -260px; }

	.side-header.side-header-right.open-header #header-trigger {
		left: auto;
		right: 20px;
	}

	.side-header.side-header-right.open-header.side-header-open #header-trigger {
		left: auto;
		right: 280px;
	}

	.side-header.side-header-right.open-header #header,
	.side-header.side-header-right.open-header #header-trigger {
		-webkit-transition: right .4s ease;
		-o-transition: right .4s ease;
		transition: right .4s ease;
	}


	/* Primary Menu - Overlay Menu
	-----------------------------------------------------------------*/

	.overlay-menu:not(.top-search-open) #primary-menu-trigger {
		display: -ms-flexbox;
		display: flex;
		opacity: 1;
		pointer-events: auto;
		width: 30px;
		height: 30px;
		line-height: 30px;
	}

	.overlay-menu .primary-menu > #overlay-menu-close {
		position: fixed;
		top: 25px;
		left: auto;
		right: 25px;
		width: 48px;
		height: 48px;
		line-height: 48px;
		font-size: 1.5rem;
		text-align: center;
		color: #444;
	}

	.overlay-menu #primary-menu-trigger {
		z-index: 599;
	}

	.overlay-menu .primary-menu {
		position: fixed;
		top: 0;
		left: 0;
		padding: 0 !important;
		margin: 0 !important;
		border: 0 !important;
		width: 100%;
		height: 100%;
		height: calc(100vh);
		background-color: rgba(255,255,255,0.95);
	}

	.overlay-menu .primary-menu,
	.overlay-menu .primary-menu > #overlay-menu-close {
		opacity: 0 !important;
		pointer-events: none;
		z-index: -2;
		-webkit-transform: translateY(-80px);
		transform: translateY(-80px);
		-webkit-transition: opacity .4s ease, transform .45s ease !important;
		transition: opacity .4s ease, transform .45s ease !important;
	}

	.overlay-menu.primary-menu-open .primary-menu,
	.overlay-menu.primary-menu-open #overlay-menu-close {
		opacity: 1 !important;
		pointer-events: auto;
		z-index: 499;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transition: opacity .3s ease, transform .45s ease !important;
		transition: opacity .3s ease, transform .45s ease !important;
	}

	.overlay-menu .primary-menu,
	.overlay-menu.primary-menu-open .primary-menu {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-transition: opacity .3s ease !important;
		transition: opacity .3s ease !important;
	}

	.overlay-menu .primary-menu > .container {
		max-height: 90%;
		overflow-y: scroll;
	}

	.overlay-menu .menu-container {
		display: -ms-flexbox !important;
		display: flex !important;
		flex-direction: column;
		max-width: 250px;
		padding: 0 !important;
		margin: 0 auto !important;
		border: 0 !important;
	}

	.overlay-menu .menu-container > .menu-item > .menu-link {
		font-size: 0.9375rem;
	}

	.overlay-menu .menu-item {
		width: 100%;
	}

	.overlay-menu .menu-link {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 0;
		padding-right: 0;
	}

	.overlay-menu .sub-menu-container,
	.overlay-menu .mega-menu-content {
		display: none;
		position: relative;
		top: 0 !important;
		left: 0 !important;
		right: auto;
		width: 100%;
		max-width: 100%;
		box-shadow: none;
		background: transparent;
		border: 0;
		padding: 10px 0 !important;
		transform: translateX(0);
	}

	.overlay-menu .mega-menu-content .sub-menu-container {
		padding: 5px 0 !important;
	}

	.overlay-menu .mega-menu-title .sub-menu-container {
		padding-top: 0 !important;
	}

	.overlay-menu .mega-menu-content .mega-menu-column {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
		border: 0 !important;
	}

	.overlay-menu .sub-menu-container .menu-link {
		padding: 5px 0;
	}

	.overlay-menu .sub-menu-container .menu-item:hover > .menu-link {
		padding-left: 0;
		padding-right: 0;
		background: transparent;
	}

	.overlay-menu .sub-menu-container .menu-link i.icon-angle-down {
		position: relative;
		display: inline-block;
		top: -1px;
		left: 0;
		margin-left: 5px;
		transform: rotate(0deg);
	}

}

@media (max-width: 991.98px) {

	.side-push-panel #side-panel-trigger-close a {
		display: block;
		position: absolute;
		z-index: 12;
		top: 0;
		left: auto;
		right: 0;
		width: 40px;
		height: 40px;
		font-size: 18px;
		line-height: 40px;
		color: #444;
		text-align: center;
		background-color: rgba(0,0,0,0.1);
		border-radius: 0 0 0 2px;
	}

}


/* ----------------------------------------------------------------
	Top Search
-----------------------------------------------------------------*/

.header-misc {
	position: initial;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	margin-left: 15px;
}

.header-misc-element,
.header-misc-icon {
	margin: 0 8px;
}

.header-misc-icon > a {
	display: block;
	position: relative;
	width: 20px;
	height: 20px;
	font-size: 1.125rem;
	line-height: 20px;
	text-align: center;
	color: #333;
	-webkit-transition: color .3s ease-in-out;
	-o-transition: color .3s ease-in-out;
	transition: color .3s ease-in-out;
}

@media (min-width: 992px) {
	.header-misc:not([class*="order-"]) {
		-ms-flex-order: 6;
		order: 6;
	}
}

#top-search a { z-index: 11; }

#top-search a i {
	position: absolute;
	top: 0;
	left: 3px;
	-webkit-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

.top-search-open #top-search a i:nth-child(1),
#top-search a i.icon-line-cross:nth-child(2) { opacity: 0; }

.top-search-open #top-search a i.icon-line-cross:nth-child(2) {
	opacity: 1;
	z-index: 11;
	font-size: 1rem;
}

.top-search-form {
	opacity: 0;
	z-index: -2;
	pointer-events: none;
	position: absolute;
	width: 100% !important;
	height: 100%;
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
	-webkit-transition: opacity .3s ease-in-out;
	-o-transition: opacity .3s ease-in-out;
	transition: opacity .3s ease-in-out;
}

.top-search-open .top-search-form {
	opacity: 1;
	z-index: 10;
	pointer-events: auto;
}

.top-search-form input {
	box-shadow: none !important;
	border-radius: 0;
	border: 0;
	outline: 0 !important;
	font-size: 24px;
	padding: 10px 80px 10px 0;
	height: 100%;
	background-color: transparent !important;
	color: #333;
	font-weight: 700;
	margin-top: 0 !important;
	font-family: 'Poppins', sans-serif;
	letter-spacing: 2px;
}

@media (min-width: 992px) {
	.top-search-form input { font-size: 32px; }

	.full-header .top-search-form input,
	.container-fluid .top-search-form input {
		padding-left: 30px;
	}

	.transparent-header:not(.sticky-header):not(.full-header):not(.floating-header) .top-search-form input {
		border-bottom: 2px solid rgba(0,0,0,0.1);
	}
}

.search-overlay .top-search-form {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	display: -ms-flexbox;
	display: flex;
	background-color: #fff;
	align-items: center;
	justify-content: center;
}

.search-overlay .top-search-form input {
	max-width: 580px;
	margin: 0 auto;
	text-align: center;
	padding: 0 40px;
}

.top-search-form input::-moz-placeholder {
	color: #555;
	opacity: 1;
	text-transform: uppercase;
}
.top-search-form input:-ms-input-placeholder {
	color: #555;
	text-transform: uppercase;
}
.top-search-form input::-webkit-input-placeholder {
	color: #555;
	text-transform: uppercase;
}

.primary-menu .container .top-search-form input,
.sticky-header .top-search-form input { border: none !important; }

/* Top Search Animation */

#top-search {
	-webkit-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

body:not(.top-search-open) .top-search-parent #logo,
body:not(.top-search-open) .top-search-parent .primary-menu,
body:not(.top-search-open) .top-search-parent .header-misc > *:not(#top-search) {
	opacity: 1;
	-webkit-transition: padding .4s ease, margin .4s ease, opacity .2s .2s ease;
	transition: padding .4s ease, margin .4s ease, opacity .2s .2s ease;
}

.top-search-open .top-search-parent #logo,
.top-search-open .top-search-parent .primary-menu,
.top-search-open .top-search-parent .header-misc > *:not(#top-search) { opacity: 0; }


/* ----------------------------------------------------------------
	Top Cart
-----------------------------------------------------------------*/


.top-cart-number {
	display: block;
	position: absolute;
	top: -7px;
	left: auto;
	right: -8px;
	font-size: 0.625rem;
	color: #FFF;
	width: 1.125rem;
	height: 1.125rem;
	line-height: 1.125rem;
	text-align: center;
	border-radius: 50%;
	z-index: 1;
	overflow: hidden;
}

.top-cart-number::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #1ABC9C;
	opacity: 0.8;
	z-index: -1;
}

.top-cart-content {
	position: fixed;
	top: 0;
	left: auto;
	width: 260px;
	background-color: #FFF;
	border-left: 1px solid #EEE;
	box-shadow: 0px 20px 50px 10px rgba(0, 0, 0, 0.05);
	-webkit-transition: right .3s ease;
	-o-transition: right .3s ease;
	transition: right .3s ease;
}

@media (max-width: 991.98px) {
	.top-cart-content {
		right: -260px !important;
		height: calc(100vh);
		z-index: 499;
	}

	.top-cart-open .top-cart-content {
		right: 0 !important;
	}
}

.top-cart-title {
	padding: 0.75rem 1rem;
	border-bottom: 1px solid #EEE;
}

.top-cart-title h4 {
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.top-cart-items {
	padding: 1rem;
}

.top-cart-item,
.top-cart-action,
.top-cart-item-desc {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: start;
	align-items: flex-start;
}

.top-cart-item {
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.top-cart-item:not(:first-child) {
	padding-top: 1rem;
	margin-top: 1rem;
	border-top: 1px solid #F5F5F5;
}

.top-cart-item-image {
	width: 48px;
	height: 48px;
	border: 2px solid #EEE;
	-webkit-transition: border-color .2s linear;
	-o-transition: border-color .2s linear;
	transition: border-color .2s linear;
}

.top-cart-item-image a,
.top-cart-item-image img {
	display: block;
	width: 44px;
	height: 44px;
}

.top-cart-item-image:hover { border-color: #1ABC9C; }

.top-cart-item-desc,
.top-cart-item-desc-title {
	padding-left: 1rem;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.top-cart-item-desc {
	-ms-flex-align: start;
	align-items: flex-start;
}

.top-cart-item-desc-title {
	padding-left: 0;
}

.top-cart-item-desc a {
	display: block;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	color: #333;
	font-weight: 700;
	margin-bottom: 5px;
}

.top-cart-item-desc a:hover { color: #1ABC9C; }

.top-cart-item-price {
	font-size: 0.75rem;
	color: #999;
}

.top-cart-item-quantity {
	font-size: 0.75rem;
	line-height: 1.25rem;
	color: #444;
}

.top-cart-action {
	-ms-flex-align: center;
	align-items: center;
	padding: 1rem;
	border-top: 1px solid #EEE;
}

.top-cart-action .top-checkout-price {
	font-size: 1.25rem;
	color: #1ABC9C;
}

@media (min-width: 992px) {
	#top-cart {
		position: relative;
	}

	.top-cart-content {
		opacity: 0;
		position: absolute;
		width: 280px;
		border: 1px solid #EEE;
		border-top: 2px solid #1ABC9C;
		top: calc( 100% + 20px );
		right: -15px;
		margin: -10000px 0 0;
		z-index: auto;
		-webkit-transition: opacity .5s ease, top .4s ease;
		-o-transition: opacity .5s ease, top .4s ease;
		transition: opacity .5s ease, top .4s ease;
	}

	#top-cart.on-hover:hover .top-cart-content,
	.top-cart-open .top-cart-content {
		opacity: 1;
		z-index: 399;
		margin-top: 0;
	}

	.top-cart-items {
		padding: 1rem;
	}
}


/* ----------------------------------------------------------------
	Page Menu
-----------------------------------------------------------------*/

#page-menu {
	position: relative;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

#page-menu-wrap {
	position: absolute;
	z-index: 199;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #1ABC9C;
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}

#page-menu.sticky-page-menu #page-menu-wrap {
	position: fixed;
	box-shadow: 0 0 15px rgba(0,0,0,0.3);
}

#page-menu-trigger {
	opacity: 1;
	pointer-events: auto;
	top: 0;
	margin-top: 0;
	left: auto;
	right: 15px;
	width: 40px;
	height: 44px;
	line-height: 44px;
	color: #FFF;
}

.page-menu-open #page-menu-trigger { background-color: rgba(0,0,0,0.2); }

.page-menu-row {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.page-menu-title {
	color: #FFF;
	font-size: 1.25rem;
	font-weight: 300;
	margin-right: auto;
}

.page-menu-title span { font-weight: 700; }

.page-menu-nav {
	display: none;
	position: absolute;
	width: 200px;
	top: 100%;
	left: auto;
	right: 0;
	height: auto;
	background-color: #1ABC9C;
}

#page-menu.page-menu-open .page-menu-nav { display: block; }

.page-menu-nav .page-menu-container {
	margin: 0;
	list-style: none;
	background-color: rgba(0,0,0,0.2);
}

.page-menu-item {
	position: relative;
}

.page-menu-item > a {
	display: block;
	position: relative;
	line-height: 22px;
	padding: 11px 14px;
	color: #FFF;
	font-size: 0.875rem;
}

.page-menu-item:hover > a,
.page-menu-item.current > a { background-color: rgba(0,0,0,0.15); }

.page-menu-item > a .icon-angle-down {
	position: absolute;
	top: 50%;
	left: auto;
	right: 14px;
	transform: translateY(-50%);
}

.page-menu-sub-menu {
	display: none;
	background-color: rgba(0,0,0,0.15);
	list-style: none;
	padding: 1px 10px 5px;
}

.page-menu-item:hover > .page-menu-sub-menu {
	display: block;
}

.page-menu-sub-menu .page-menu-item > a {
	margin: 5px 0;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 14px;
}

@media (min-width: 992px) {

	.page-menu-nav {
		display: -ms-flex;
		display: flex;
		position: relative;
		width: auto;
		top: 0;
		left: 0;
		background-color: transparent !important;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}

	.page-menu-nav .page-menu-container {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		list-style: none;
		background-color: transparent;
	}

	.page-menu-item > a {
		margin: 10px 4px;
		padding-top: 3px;
		padding-bottom: 3px;
		border-radius: 14px;
	}

	.page-menu-item > a .icon-angle-down {
		position: relative;
		top: 1px;
		left: 0;
		right: 0;
		margin-left: 8px;
		transform: none;
	}

	body:not(.device-touch) .page-menu-item > a {
		-webkit-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}

	.page-menu-sub-menu {
		display: none;
		position: absolute;
		list-style: none;
		width: 150px;
		top: 100%;
		left: 0;
		padding: 0;
		background-color: #1ABC9C;
	}

	.page-menu-sub-menu .page-menu-item > a {
		height: auto;
		margin: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 0;
		background-color: rgba(0,0,0,0.15);
	}

	.page-menu-sub-menu li:hover a,
	.page-menu-sub-menu li.current a { background-color: rgba(0,0,0,0.3); }


	/* Page Menu - Dots Style
	-----------------------------------------------------------------*/

	#page-menu.dots-menu {
		position: fixed;
		top: 50%;
		left: auto;
		right: 20px;
		width: 24px;
		line-height: 1.5;
		text-shadow: none;
		transform: translateY(-50%);
		z-index: 299;
	}

	.dots-menu #page-menu-wrap {
		position: relative;
		top: 0 !important;
		box-shadow: none;
		background-color: transparent !important;
	}

	.dots-menu .page-menu-wrap-clone {
		display: none;
	}

	.dots-menu .container {
		padding: 0;
	}

	.dots-menu .page-menu-title { display: none; }

	.dots-menu .page-menu-nav {
		display: block;
		width: 100%;
	}

	.dots-menu .page-menu-container {
		flex-direction: column;
		-ms-flex-direction: column;
	}

	.dots-menu .page-menu-item {
		width: 10px;
		height: 10px;
		margin: 10px 0;
	}

	.dots-menu .page-menu-item > a {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		background-color: rgba(0,0,0,0.4);
		border-radius: 50%;
		color: #777;
	}

	.dots-menu .page-menu-item > a:hover {
		background-color: rgba(0,0,0,0.7);
	}

	.dots-menu .page-menu-item.current > a { background-color: #1ABC9C; }

	.dots-menu.dots-menu-border .page-menu-item {
		width: 12px;
		height: 12px;
	}

	.dots-menu.dots-menu-border .page-menu-item > a {
		background-color: transparent !important;
		border: 3px solid rgba(0,0,0,0.4);
	}

	.dots-menu.dots-menu-border .page-menu-item > a:hover {
		border-color: rgba(0,0,0,0.7);
	}

	.dots-menu.dots-menu-border .page-menu-item.current > a { border-color: #1ABC9C; }

	.dots-menu .page-menu-item div {
		position: absolute;
		width: auto;
		top: 50%;
		right: 25px;
		background-color: #1ABC9C;
		font-size: 0.75rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: #FFF;
		padding: 6px 14px;
		white-space: nowrap;
		pointer-events: none;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.15);
		border-radius: 2px;
		opacity: 0;
		transform: translateY(-50%);
	}

	body:not(.device-touch) .dots-menu .page-menu-item div {
		-webkit-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}

	.dots-menu .page-menu-item div::after {
		position: absolute;
		content: '';
		top: 50%;
		left: auto;
		right: -5px;
		width: 0;
		height: 0;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: 6px solid #1ABC9C;
		transform: translateY(-50%);
	}

	.dots-menu .page-menu-item > a:hover div {
		opacity: 1;
		right: 30px;
	}

}


/* ----------------------------------------------------------------
	One Page
-----------------------------------------------------------------*/

.one-page-arrow {
	position: absolute;
	z-index: 3;
	bottom: 20px;
	left: 50%;
	margin-left: -16px;
	width: 32px;
	height: 32px;
	font-size: 32px;
	text-align: center;
	color: #222;
}


/* ----------------------------------------------------------------
	Side Push Panel
-----------------------------------------------------------------*/

.side-push-panel { overflow-x: hidden; }

#side-panel {
	position: fixed;
	top: 0;
	right: -300px;
	z-index: 699;
	width: 300px;
	height: 100%;
	background-color: #F5F5F5;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
}

.side-panel-left #side-panel {
	left: -300px;
	right: auto;
}

.side-push-panel.stretched #wrapper,
.side-push-panel.stretched .sticky-header .container { right: 0; }

.side-panel-left.side-push-panel.stretched #wrapper,
.side-panel-left.side-push-panel.stretched .sticky-header .container {
	left: 0;
	right: auto;
}

#side-panel .side-panel-wrap {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 330px;
	height: 100%;
	overflow: auto;
	padding: 50px 70px 50px 40px;
}

.device-touch #side-panel .side-panel-wrap { overflow-y: scroll; }

#side-panel .widget {
	margin-top: 40px;
	padding-top: 40px;
	width: 220px;
	border-top: 1px solid #E5E5E5;
}

#side-panel .widget:first-child {
	padding-top: 0;
	border-top: 0;
	margin: 0;
}

.side-panel-open #side-panel { right: 0; }

.side-panel-left.side-panel-open #side-panel {
	left: 0;
	right: auto;
}

.side-push-panel.side-panel-open.stretched #wrapper,
.side-push-panel.side-panel-open.stretched .sticky-header .container { right: 300px; }

.side-push-panel.side-panel-open.stretched.device-xl .slider-inner,
.side-push-panel.side-panel-open.stretched.device-lg .slider-inner { left: -300px; }

.side-panel-left.side-push-panel.side-panel-open.stretched #wrapper,
.side-panel-left.side-push-panel.side-panel-open.stretched .sticky-header .container,
.side-panel-left.side-push-panel.side-panel-open.stretched .slider-inner {
	left: 300px;
	right: auto;
}

#side-panel-trigger {
	display: block;
	cursor: pointer;
	z-index: 11;
	margin-right: 5px;
}

#side-panel-trigger-close a { display: none; }

#side-panel,
.side-push-panel.stretched #wrapper,
.side-push-panel.stretched #header .container {
	-webkit-transition: right .4s ease;
	-o-transition: right .4s ease;
	transition: right .4s ease;
}

.side-panel-left #side-panel,
.side-panel-left.side-push-panel.stretched #wrapper,
.side-panel-left.side-push-panel.stretched #header .container,
.side-push-panel.stretched .slider-inner {
	-webkit-transition: left .4s ease;
	-o-transition: left .4s ease;
	transition: left .4s ease;
}

.body-overlay {
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: -1;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-webkit-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

.side-panel-open .body-overlay {
	opacity: 1;
	z-index: 599;
	pointer-events: auto;
}

.side-panel-open:not(.device-xs):not(.device-sm):not(.device-md) .body-overlay:hover { cursor: url('../assets/html-assets/icons/close.png') 15 15, default; }


/* ----------------------------------------------------------------
	Slider
-----------------------------------------------------------------*/


.slider-element {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.slider-parallax { position: relative; }

.slider-parallax,
.slider-parallax .swiper-container {
	visibility: visible;
	-webkit-backface-visibility: hidden;
}

.slider-inner {
	position: absolute;
	overflow: hidden;
	opacity: 1;
	visibility: visible;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.slider-parallax .slider-inner {
	position: fixed;
}

.h-auto:not(.slider-parallax) .slider-inner,
.h-auto .swiper-container,
.min-vh-0:not(.slider-parallax) .slider-inner,
.min-vh-0 .swiper-container {
	position: relative !important;
	height: auto !important;
}

.slider-parallax-invisible .slider-inner {
	opacity: 0;
	visibility: hidden;
}

@media (min-width: 992px) {
	.h-auto .swiper-wrapper,
	.min-vh-0 .swiper-wrapper {
		height: 75vh;
	}
}

.slider-inner.slider-inner-relative {
	position: relative !important;
	height: auto !important;
}

.device-touch .slider-parallax .slider-inner { position: relative; }

body:not(.side-header) .slider-parallax .slider-inner { left: 0; }

body:not(.stretched) .slider-parallax .slider-inner { left: auto; }

.slider-parallax .slider-inner,
.slider-parallax .slider-caption {
	will-change: transform;
	-webkit-transform: translate3d(0,0,0);
	-webkit-transition: transform 0s linear;
	-webkit-backface-visibility: hidden;
}


@media (max-width: 991.98px) {

	#slider {
		display: -ms-flexbox !important;
		display: flex !important;
		flex-direction: column;
		justify-content: center;
	}

	#slider .slider-inner {
		position: relative;
		display: -ms-flexbox;
		display: flex;
		flex-grow: 1;
		align-items: center;
	}

}


/* Boxed Slider
-----------------------------------------------------------------*/

.boxed-slider { padding-top: 40px; }


/* Swiper Slider
-----------------------------------------------------------------*/

.swiper_wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.full-screen .swiper_wrapper { height: auto; }

.swiper_wrapper .swiper-container {
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0;
}

.swiper_wrapper .swiper-slide {
	position: relative;
	margin: 0;
	overflow: hidden;
}

.swiper-slide-bg {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.swiper-slide [data-caption-animate] { -webkit-backface-visibility: hidden; }

.slider-element .video-wrap,
.section .video-wrap,
.swiper-slide .yt-bg-player,
.section .yt-bg-player,
.swiper-slide-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
}

.yt-bg-player .inlinePlayButton,
.yt-bg-player .loading { display: none !important; }

.slider-element .container,
.slider-element .container-fluid {
	position: relative;
	height: 100%;
}

.slider-element.canvas-slider .swiper-slide img { width: 100%; }

.slider-element.canvas-slider a {
	display: block;
	height: 100%;
}


.swiper-pagination {
	position: absolute;
	width: 100%;
	z-index: 20;
	margin: 0;
	top: auto;
	bottom: 20px !important;
	text-align: center;
	line-height: 1;
}

.swiper-pagination span {
	display: inline-block;
	cursor: pointer;
	width: 10px;
	height: 10px;
	margin: 0 4px;
	opacity: 1;
	background-color: transparent;
	border: 1px solid #FFF;
	border-radius: 50%;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.swiper-pagination span:hover,
.swiper-pagination span.swiper-pagination-bullet-active { background-color: #FFF !important; }


/* Video Overlay
-----------------------------------------------------------------*/

.video-wrap {
	overflow: hidden;
	width: 100%;
	position: relative;
	direction: ltr;
}

.video-wrap video {
	position: relative;
	z-index: 1;
	opacity: 1;
	width: 100%;
	transition: opacity .3s 1s ease;
	/*min-width: 100%;
	min-height: 100%;
	top: 50%;
	transform: translateY(-50%);*/
}

body:not(.has-plugin-html5video) .video-wrap video {
	opacity: 0;
}

.video-overlay,
.video-placeholder {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	background: transparent url('../assets/html-assets/grid.png') repeat;
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}

.video-placeholder {
	z-index: 4;
	background-repeat: no-repeat !important;
}


/* Slider Caption
-----------------------------------------------------------------*/

.slider-caption {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	flex-flow: column;
	justify-content: center;
	z-index: 20;
	max-width: 550px;
	color: #EEE;
	font-size: 1.375rem;
	font-weight: 300;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.15);
	-webkit-transition: top .3s ease;
	-o-transition: top .3s ease;
	transition: top .3s ease;
}

.slider-caption:not(.dark),
.slider-caption:not(.dark) h2 {
	color: #222;
	text-shadow: none;
}

.slider-caption h2 {
	font-size: 4rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 0;
}

.slider-caption p {
	margin-top: 30px;
	margin-bottom: 10px;
	font-size: 1.5rem;
}

@media (max-width: 991.98px) {

	.swiper_wrapper .slider-caption h2 {
		font-size: 5.5vw;
	}

	.swiper_wrapper .slider-caption p {
		font-size: 2.6vw;
	}

}

.slider-caption.slider-caption-right {
	margin-left: auto;
}

.slider-caption.slider-caption-center {
	position: relative;
	max-width: 800px;
	text-align: center;
	margin: 0 auto;
	left: 0;
	right: 0;
}

.slider-caption-bg {
	padding: 5px 12px;
	border-radius: 2px;
	background-color: rgba(0,0,0,0.6);
	top: auto;
	bottom: 50px;
	left: 50px;
}

.slider-caption-bg.slider-caption-bg-light {
	background-color: rgba(255,255,255,0.8);
	color: #333;
	font-weight: 400;
	text-shadow: none;
}

.slider-caption-top-left {
	top: 50px;
	bottom: auto;
}

.slider-caption-top-right {
	top: 50px;
	bottom: auto;
	left: auto;
	right: 50px;
}

.slider-caption-bottom-right {
	left: auto;
	right: 50px;
}


/* Slider Arrows
-----------------------------------------------------------------*/

.slider-element .owl-carousel { margin: 0; }

.slider-arrow-left,
.slider-arrow-right,
.flex-prev,
.flex-next,
.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
	position: absolute;
	cursor: pointer;
	z-index: 10;
	top: 50%;
	left: 0;
	background-color: rgba(0,0,0,0.3);
	width: 52px;
	height: 52px;
	border: 0;
	border-radius: 0 3px 3px 0;
	transform: translateY(-50%);
	-webkit-transition: background-color .3s ease-in-out;
	-o-transition: background-color .3s ease-in-out;
	transition: background-color .3s ease-in-out;
}

.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
	top: auto;
	bottom: 57px;
	left: auto;
	right: 20px;
	margin: 0;
	width: 32px;
	height: 32px;
	border-radius: 3px 3px 0 0;
}

.slider-arrow-bottom-sm {
	bottom: 20px;
	border-radius: 0 0 3px 3px;
}

.fslider[data-thumbs="true"] .flex-prev,
.fslider[data-thumbs="true"] .flex-next { margin-top: -20px; }

.slider-arrow-right,
.slider-element .owl-next,
.flex-next {
	left: auto;
	right: 0;
	border-radius: 3px 0 0 3px;
}

.slider-arrow-left i,
.slider-arrow-right i,
.flex-prev i,
.flex-next i,
.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
	line-height: 50px;
	width: 100%;
	height: 100%;
	color: #DDD;
	color: rgba(255,255,255,0.8);
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
	font-size: 34px;
	text-align: center;
	transition: color .3s ease-in-out;
}


.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
	width: 32px;
	height: 32px;
	margin: 0;
	line-height: 30px;
	font-size: 18px;
	color: #FFF;
	color: rgba(255,255,255,1);
}

.slider-element .owl-prev i { margin-left: 0; }

.slider-element .owl-next i { margin-right: 4px; }

.slider-arrow-left:hover,
.slider-arrow-right:hover,
.flex-prev:hover,
.flex-next:hover,
.slider-arrow-top-sm:hover,
.slider-arrow-bottom-sm:hover { background-color: rgba(0,0,0,0.6) !important; }


/* Slide Number
-----------------------------------------------------------------*/

.slide-number {
	position: absolute;
	opacity: 0;
	top: auto;
	right: 20px;
	bottom: 20px;
	z-index: 20;
	width: 36px;
	height: 40px;
	text-align: center;
	color: rgba(255,255,255,0.8);
	text-shadow: 1px 1px 1px rgba(0,0,0,0.15);
	font-size: 18px;
	font-weight: 300;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}

.slider-element:hover .slide-number { opacity: 1; }

.slide-number-current,
.slide-number-total {
	position: absolute;
	line-height: 1;
	top: 5px;
	left: 0;
}

.slide-number-total {
	top: auto;
	bottom: 5px;
	left: auto;
	right: 0;
}

.slide-number span {
	display: block;
	font-size: 32px;
	line-height: 40px;
}


/* Pagination
-----------------------------------------------------------------*/

.slider-element .owl-dots {
	position: absolute;
	width: 100%;
	z-index: 20;
	margin: 0;
	top: auto;
	bottom: 15px;
}

.slider-element .owl-dots button {
	width: 10px;
	height: 10px;
	margin: 0 3px;
	opacity: 1 !important;
	background-color: transparent !important;
	border: 1px solid #FFF;
}

.slider-element .owl-dots .owl-dot.active,
.slider-element .owl-dots .owl-dot:hover { background-color: #FFF !important; }


/* Flex Slider - Navigation
-----------------------------------------------------------------*/

.nav-offset .flex-prev,
.nav-offset .flex-next {
	border-radius: 3px;
}

.nav-offset .flex-prev {
	left: 20px;
}

.nav-offset .flex-next {
	right: 20px;
}

.nav-circle .flex-prev,
.nav-circle .flex-next {
	border-radius: 50%;
}

.nav-solid .flex-prev,
.nav-solid .flex-next {
	opacity: 1;
	background-color: #FFF;
}

.nav-solid .flex-prev:hover,
.nav-solid .flex-next:hover {
	background-color: #333 !important;
}

.nav-solid .flex-prev:not(:hover) i,
.nav-solid .flex-next:not(:hover) i {
	color: #444;
	text-shadow: none;
}


.nav-size-sm .flex-prev,
.nav-size-sm .flex-next {
	width: 32px;
	height: 32px;
}

.nav-size-sm .flex-prev i,
.nav-size-sm .flex-next i {
	line-height: 32px;
	font-size: 1.5rem;
}

.fslider[class*="nav-pos-"] .flex-prev,
.fslider[class*="nav-pos-"] .flex-next {
	position: relative;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 5px;
	transform: none;
}

.fslider.nav-size-sm[class*="nav-pos-"] .flex-prev,
.fslider.nav-size-sm[class*="nav-pos-"] .flex-next {
	margin: 0 3px;
}

.fslider[class*="nav-pos-"] .flex-direction-nav {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
}

.nav-pos-top .flex-direction-nav,
.nav-pos-top-left .flex-direction-nav,
.nav-pos-top-right .flex-direction-nav {
	top: 20px;
	left: 0;
}

.nav-pos-bottom .flex-direction-nav,
.nav-pos-bottom-left .flex-direction-nav,
.nav-pos-bottom-right .flex-direction-nav {
	top: auto;
	bottom: 20px;
}

.nav-pos-top-left .flex-direction-nav,
.nav-pos-bottom-left .flex-direction-nav {
	width: auto !important;
	left: 16px;
}

.nav-pos-top-right .flex-direction-nav,
.nav-pos-bottom-right .flex-direction-nav {
	width: auto !important;
	left: auto;
	right: 16px;
}


/* Flex Slider - Pagination & Thumbs
-----------------------------------------------------------------*/

.fslider[data-animation="fade"][data-thumbs="true"] .flexslider,
.fslider.testimonial[data-animation="fade"] .flexslider { height: auto !important; }

.flex-control-nav {
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	width: auto;
	z-index: 10;
	text-align: center;
	top: 14px;
	right: 10px;
	margin: 0;
}

.flex-control-nav li {
	display: block;
	margin: 0 3px;
	width: 0.625rem;
	height: 0.625rem;
}

.flex-control-nav li a {
	display: block;
	cursor: pointer;
	text-indent: -9999px;
	width: 100% !important;
	height: 100% !important;
	border: 1px solid #FFF;
	color: transparent;
	border-radius: 50%;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
}

.flex-control-nav li:hover a,
.flex-control-nav li a.flex-active {
	background-color: #FFF;
}


.control-solid .flex-control-nav li a {
	border: none;
	background-color: #FFF;
}

.control-solid .flex-control-nav li:hover a,
.control-solid .flex-control-nav li a.flex-active {
	background-color: #1ABC9C;
}


.control-square .flex-control-nav li a {
	border-radius: 3px;
}

.control-diamond .flex-control-nav li a {
	border-radius: 3px;
	transform: rotate(45deg);
}


/* Flex Slider: Pagination Positions
-----------------------------------------------------------------*/

.control-offset-lg .flex-control-nav {
	top: 24px;
	right: 20px;
}

.control-pos-top-left .flex-control-nav,
.control-pos-bottom-left .flex-control-nav {
	left: 10px;
	right: auto;
}

.control-offset-lg.control-pos-top-left .flex-control-nav,
.control-offset-lg.control-pos-bottom-left .flex-control-nav {
	left: 20px;
}

.control-pos-bottom-right .flex-control-nav,
.control-pos-bottom-left .flex-control-nav,
.control-pos-bottom .flex-control-nav,
.control-pos-bottom .flex-control-nav {
	top: auto;
	bottom: 14px;
}

.control-offset-lg.control-pos-bottom-right .flex-control-nav,
.control-offset-lg.control-pos-bottom-left .flex-control-nav,
.control-offset-lg.control-pos-bottom .flex-control-nav,
.control-offset-lg.control-pos-bottom .flex-control-nav {
	bottom: 24px;
}

.control-pos-bottom .flex-control-nav,
.control-pos-bottom .flex-control-nav,
.control-pos-top .flex-control-nav,
.control-pos-top .flex-control-nav {
	width: 100%;
	right: 0;
}


.control-size-lg .flex-control-nav li {
	width: 0.9325rem;
	height: 0.9325rem;
	margin: 0 5px;
}

/* Flex Slider: Thumbs
-----------------------------------------------------------------*/

.flex-control-nav.flex-control-thumbs {
	position: relative;
	justify-content: left;
	top: 0;
	left: 0;
	right: 0;
	margin: 2px -2px -2px 0;
	height: auto;
}

.flex-control-nav.flex-control-thumbs li { margin: 0 2px 2px 0; }

.flex-control-nav.flex-control-thumbs li img {
	cursor: pointer;
	text-indent: -9999px;
	border: 0;
	border-radius: 0;
	margin: 0;
	opacity: 0.5;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.flex-control-nav.flex-control-thumbs li,
.flex-control-nav.flex-control-thumbs li img {
	display: block;
	width: 100px !important;
	height: auto !important;
}

.flex-control-nav.flex-control-thumbs li:hover img,
.flex-control-nav.flex-control-thumbs li img.flex-active {
	border-width: 0;
	opacity: 1;
}


/* FlexSlider: Thumbs - Flexible
-----------------------------------------------------------------*/

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs {
	margin: 2px -2px -2px 0;
	height: auto;
	display: flex;
	flex-wrap: wrap;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
	flex-shrink: 0;
	flex: 0 0 auto;
	max-width: 100%;
	height: auto !important;
	margin: 0;
	padding: 0 2px 2px 0;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
	width: 25% !important;
}

.fslider.flex-thumb-grid.grid-3 .flex-control-nav.flex-control-thumbs li {
	width: 33.333333% !important;
}

.fslider.flex-thumb-grid.grid-5 .flex-control-nav.flex-control-thumbs li {
	width: 20% !important;
}

.fslider.flex-thumb-grid.grid-6 .flex-control-nav.flex-control-thumbs li {
	width: 16.666666% !important;
}

.fslider.flex-thumb-grid.grid-8 .flex-control-nav.flex-control-thumbs li {
	width: 12.5% !important;
}

.fslider.flex-thumb-grid.grid-10 .flex-control-nav.flex-control-thumbs li {
	width: 10% !important;
}

.fslider.flex-thumb-grid.grid-12 .flex-control-nav.flex-control-thumbs li {
	width: 8.333333% !important;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li img {
	width: 100% !important;
	height: auto !important;
}


/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/


#page-title {
	position: relative;
	padding: 4rem 0;
	background-color: #F5F5F5;
	border-bottom: 1px solid #EEE;
}

#page-title .container {
	position: relative;
}

#page-title h1 {
	padding: 0;
	margin: 0;
	line-height: 1;
	font-weight: 600;
	letter-spacing: 1px;
	color: #333;
	font-size: 2rem;
	text-transform: uppercase;
}

#page-title span {
	display: block;
	margin-top: 10px;
	font-weight: 300;
	color: #777;
	font-size: 1.125rem;
}

.breadcrumb {
	position: absolute !important;
	width: auto !important;
	top: 50% !important;
	left: auto !important;
	right: 15px !important;
	margin: 0 !important;
	background-color: transparent !important;
	padding: 0 !important;
	font-size: 90%;
	transform: translateY(-50%);
}

.transparent-header + .page-title-parallax .breadcrumb { margin-top: 35px !important; }

.breadcrumb a { color: #555; }

.breadcrumb a:hover { color: #1ABC9C; }

.breadcrumb i { width: auto !important; }

/* Page Title - Dark
-----------------------------------------------------------------*/

#page-title.page-title-dark {
	background-color: #333;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1) !important;
	border-bottom: none;
}

#page-title.page-title-dark h1 { color: rgba(255,255,255,0.9) !important; }

#page-title.page-title-dark span { color: rgba(255,255,255,0.7) !important; }

#page-title.page-title-dark .breadcrumb > .active { color: rgba(255,255,255,0.6); }

#page-title.page-title-dark .breadcrumb a { color: rgba(255,255,255,0.8); }

#page-title.page-title-dark .breadcrumb a:hover { color: rgba(255,255,255,0.95); }

#page-title.page-title-dark .breadcrumb-item + .breadcrumb-item::before {
	color: #CCC;
}

/* Page Title - Right Aligned
-----------------------------------------------------------------*/

.page-title-right { text-align: right; }

.page-title-right .breadcrumb {
	left: 15px !important;
	right: auto !important;
}

/* Page Title - Center Aligned
-----------------------------------------------------------------*/

.page-title-center { text-align: center; }

#page-title.page-title-center span {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

#page-title.page-title-center .breadcrumb {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	margin: 20px 0 0 !important;
	justify-content: center;
	transform: translateY(0);
}

/* Page Title - Background Pattern
-----------------------------------------------------------------*/

.page-title-pattern {
	background-image: url('../assets/html-assets/pattern.png');
	background-repeat: repeat;
	background-attachment: fixed;
}

/* Page Title - Parallax Background
-----------------------------------------------------------------*/

.page-title-parallax {
	padding: 100px 0;
	text-shadow: none;
	border-bottom: none;
}

#page-title.page-title-parallax h1 {
	font-size: 40px;
	font-weight: 600;
	letter-spacing: 2px;
}

#page-title.page-title-parallax span { font-size: 1.25rem; }

#page-title.page-title-parallax .breadcrumb { font-size: 0.875rem; }

.page-title-parallax {
	background-color: transparent;
	background-image: url('../assets/html-assets/parallax/parallax-bg.jpg');
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
}


/* Page Title - Mini
-----------------------------------------------------------------*/

#page-title.page-title-mini { padding: 20px 0; }

#page-title.page-title-mini h1 {
	font-weight: 600;
	font-size: 18px;
	color: #444;
}

#page-title.page-title-mini span { display: none; }


/* Page Title - No Background
-----------------------------------------------------------------*/

#page-title.page-title-nobg {
	background: transparent !important;
	border-bottom: 1px solid #F5F5F5;
}


/* Page Title - Video
-----------------------------------------------------------------*/

.page-title-video {
	background: none;
	position: relative;
	overflow: hidden;
}

.page-title-video .container {
	z-index: 5;
}

.page-title-video .video-wrap {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.page-title-video .video-wrap video { width: 100%; }

@media (max-width: 767.98px) {
	#page-title {
		padding: 2rem 0;
		text-align: center;
	}

	#page-title h1 {
		line-height: 1.5;
		font-size: calc( 1rem + 2vw );
	}

	.page-title-parallax span {
		font-size: 1rem;
	}

	#page-title .breadcrumb {
		position: relative !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		margin: 20px 0 0 !important;
		justify-content: center;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	#page-title #portfolio-navigation,
	#portfolio-ajax-title #portfolio-navigation {
		position: relative;
		top: 0;
		left: 0;
		margin: 15px auto 0;
	}
}


/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/


#content {
	position: relative;
	background-color: #FFF;
}

#content p { line-height: 1.8; }

.content-wrap {
	position: relative;
	padding: 80px 0;
	overflow: hidden;
}

#content .container { position: relative; }

.grid-container,
.grid-inner {
	position: relative;
	overflow: hidden;
}

.grid-inner {
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

body:not(.device-touch) .grid-container {
	-webkit-transition: height .4s ease;
	-o-transition: height .4s ease;
	transition: height .4s ease;
}


/* ----------------------------------------------------------------
	Portfolio
-----------------------------------------------------------------*/


/* Grid Filter
-----------------------------------------------------------------*/

.grid-filter-wrap {
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
}

.grid-filter {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 3.5rem;
	list-style: none;
}

.grid-filter li {
	position: relative;
	width: 50%;
}

.grid-filter li a {
	display: block;
	position: relative;
	padding: 10px 20px;
	font-size: 0.9375rem;
	color: #666;
	text-align: center;
	border-radius: 2px;
}

.grid-filter li a:hover { color: #1ABC9C; }

.grid-filter li.activeFilter a {
	color: #FFF;
	background-color: #1ABC9C;
	margin: 0;
	font-weight: bold;
}

@media (min-width: 576px) {
	.grid-filter li {
		width: 33.333333%;
	}
}

@media (min-width: 768px) {
	.grid-filter li {
		width: auto;
	}

	.grid-filter {
		border: 1px solid rgba(0,0,0,0.07);
		border-radius: 4px;
	}

	.grid-filter li a {
		border-left: 1px solid rgba(0,0,0,0.07);
		border-radius: 0;
	}

	.grid-filter li:first-child a {
		border-left: none;
		border-radius: 4px 0 0 4px;
	}

	.grid-filter li:last-child a { border-radius: 0 4px 4px 0; }

	.grid-filter li.activeFilter a {
		margin: -1px 0;
		padding-top: 11px;
		padding-bottom: 11px;
	}

	.bothsidebar .grid-filter li a { padding: 12px 14px; }

	.bothsidebar .grid-filter li.activeFilter a { padding: 13px 18px; }
}


/* Grid Filter: Style 2
-----------------------------------------------------------------*/

.grid-filter.style-2,
.grid-filter.style-3,
.grid-filter.style-4 {
	border: none;
	border-radius: 0;
	margin-bottom: 2.75rem;
}

.grid-filter.style-2 li,
.grid-filter.style-3 li,
.grid-filter.style-4 li { margin-bottom: 0.75rem; }

@media (min-width: 768px) {
	.grid-filter.style-2 li:not(:first-child),
	.grid-filter.style-3 li:not(:first-child) { margin-left: 10px; }
}

.grid-filter.style-2 li a,
.grid-filter.style-3 li a,
.grid-filter.style-4 li a {
	padding-top: 9px;
	padding-bottom: 9px;
	border-radius: 22px;
	border: none;
}

.grid-filter.style-2 li.activeFilter a {
	color: #FFF !important;
	margin: 0;
}


/* Grid Filter: Style 3
-----------------------------------------------------------------*/

.grid-filter.style-3 li a { border: 1px solid transparent; }

.grid-filter.style-3 li.activeFilter a {
	color: #1ABC9C !important;
	border-color: #1ABC9C;
	background-color: transparent;
	margin: 0;
}


/* Grid Filter: Style 4
-----------------------------------------------------------------*/

@media (min-width: 768px) {
	.grid-filter.style-4 li:not(:first-child) { margin-left: 30px; }
}

.grid-filter.style-4 li a {
	padding: 13px 5px;
	border-radius: 0;
}

.grid-filter.style-4 li a::after {
	content: '';
	position: absolute;
	top: auto;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 2px;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.grid-filter.style-4 li.activeFilter a {
	color: #444 !important;
	background-color: transparent;
	margin: 0;
}

.grid-filter.style-4 li.activeFilter a::after {
	width: 100%;
	left: 0%;
	background-color: #1ABC9C;
}


/* Portfolio - Shuffle Icon
-----------------------------------------------------------------*/

.grid-shuffle {
	width: 42px;
	height: 42px;
	border: 1px solid rgba(0,0,0,0.07);
	font-size: 1rem;
	text-align: center;
	line-height: 40px;
	color: #333;
	cursor: pointer;
	-webkit-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear;
}

.grid-shuffle:hover {
	background-color: #1ABC9C;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}


/* Portfolio - Items
-----------------------------------------------------------------*/

.portfolio,
.portfolio-item {
	position: relative;
}

.portfolio-rounded .portfolio-image {
	border-radius: 5px;
	overflow: hidden;
}

body:not(.device-touch) .portfolio {
	-webkit-transition: height .4s linear;
	-o-transition: height .4s linear;
	transition: height .4s linear;
}

.portfolio-reveal .portfolio-item { overflow: hidden; }

.portfolio-item .portfolio-image {
	position: relative;
	overflow: hidden;
}

.portfolio-item .portfolio-image,
.portfolio-item .portfolio-image > a,
.portfolio-item .portfolio-image img {
	display: block;
	width: 100%;
	height: auto;
}

@media (min-width: 768px) {
	.portfolio-reveal .portfolio-image img,
	.portfolio-reveal .bg-overlay {
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
}


/* Portfolio - Item Title
-----------------------------------------------------------------*/

.portfolio-desc {
	position: relative;
	z-index: 6;
	padding: 20px 5px;
}

.portfolio-desc h3 {
	margin: 0;
	padding: 0;
	font-size: 1.25rem;
}

.portfolio-desc h3 a {
	color: #222;
}

.portfolio-desc h3 a:hover {
	color: #1ABC9C;
}

.portfolio-desc span {
	display: block;
}

.portfolio-desc span,
.portfolio-desc span a {
	color: #888;
}

.portfolio-desc span a:hover {
	color: #000;
}


.desc-lg .portfolio-desc {
	padding: 20px 8px;
}

.desc-lg .portfolio-desc h3 {
	font-size: 1.5rem;
}

.desc-lg .portfolio-desc span {
	font-size: 110%;
}


.desc-sm .portfolio-desc {
	padding: 15px 5px;
}

.desc-sm .portfolio-desc h3 {
	font-size: 1rem;
}

.desc-sm .portfolio-desc span {
	font-size: 90%;
}

.portfolio.g-0 .portfolio-desc {
	padding-left: 15px;
	padding-right: 15px;
}

.portfolio.desc-sm.g-0 .portfolio-desc {
	padding-left: 10px;
	padding-right: 10px;
}

.portfolio-reveal .portfolio-desc {
	background-color: #FFF;
	top: -100%;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

@media (min-width: 768px) {
	.portfolio-reveal .portfolio-desc {
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
}

.portfolio-reveal .portfolio-item:hover .portfolio-desc {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.portfolio-reveal .portfolio-item:hover .portfolio-image img,
.portfolio-reveal .portfolio-item:hover .bg-overlay {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

@media (min-width: 768px) {
	.portfolio-reveal .portfolio-item:hover .portfolio-image img,
	.portfolio-reveal .portfolio-item:hover .bg-overlay {
		-webkit-transform: translateY(-15%);
		transform: translateY(-15%);
	}
}

@media (max-width: 767.98px) {
	.portfolio-reveal .portfolio-desc,
	.portfolio-reveal .portfolio-desc { display: none !important; }
}


.col-md-9 .portfolio-desc h3 {
	font-size: 1.125rem;
}

.col-md-9 .portfolio-desc span {
	font-size: 0.875rem;
}


/* Portfolio - Parallax
-----------------------------------------------------------------*/

.portfolio-parallax .portfolio-image {
	height: 60vh;
	background-attachment: fixed;
}

@media( min-width: 992px ) {
	.portfolio-parallax .portfolio-image {
		height: 500px;
	}
}


/* Portfolio Single
-----------------------------------------------------------------*/

.portfolio-single {}


/* Portfolio Single - Image
-----------------------------------------------------------------*/

.portfolio-single-image > a,
.portfolio-single-image .slide a,
.portfolio-single-image img,
.portfolio-single-image iframe,
.portfolio-single-image video {
	display: block;
	width: 100%;
}

.portfolio-single-image-full {
	position: relative;
	height: 600px;
	overflow: hidden;
	margin: -80px 0 80px !important;
}

.portfolio-single-video { height: auto !important; }


/* Masonry Thumbs
-----------------------------------------------------------------*/

.masonry-thumbs {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -1px;
	margin-right: -1px;
	margin-bottom: -2px;
}

.masonry-thumbs .grid-item {
	display: block;
	position: relative;
	width: 25%;
	max-width: 100%;
	height: auto;
	overflow: hidden;
	padding-left: 1px;
	padding-right: 1px;
	padding-bottom: 2px;
	padding-top: 0;
}

.masonry-gap-0 {
	margin: 0;
}

.masonry-gap-0 .grid-item {
	padding: 0;
}

.masonry-gap-lg {
	margin-left: -3px;
	margin-right: -3px;
	margin-bottom: -6px;
}

.masonry-gap-lg .grid-item {
	padding-left: 3px;
	padding-right: 3px;
	padding-bottom: 6px;
}

.masonry-gap-xl {
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: -10px;
}

.masonry-gap-xl .grid-item {
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 10px;
}

.grid-item > img,
.grid-item .grid-inner > img {
	width: 100%;
	height: auto;
	border-radius: 0;
}

.grid-1 > .grid-item {
	width: 100%;
}

.grid-2 > .grid-item {
	width: 50%;
}

.grid-3 > .grid-item {
	width: 33.333333%;
}

.grid-4 > .grid-item {
	width: 25%;
}

.grid-5 > .grid-item {
	width: 20%;
}

.grid-6 > .grid-item {
	width: 16.666667%;
}

.grid-8 > .grid-item {
	width: 12.50%;
}

.grid-10 > .grid-item {
	width: 10%;
}

.grid-12 > .grid-item {
	width: 8.333333%;
}

@media (min-width: 576px) {
	.grid-sm-1 > .grid-item {
		width: 100%;
	}

	.grid-sm-2 > .grid-item {
		width: 50%;
	}

	.grid-sm-3 > .grid-item {
		width: 33.333333%;
	}

	.grid-sm-4 > .grid-item {
		width: 25%;
	}

	.grid-sm-5 > .grid-item {
		width: 20%;
	}

	.grid-sm-6 > .grid-item {
		width: 16.666667%;
	}

	.grid-sm-8 > .grid-item {
		width: 12.50%;
	}

	.grid-sm-10 > .grid-item {
		width: 10%;
	}

	.grid-sm-12 > .grid-item {
		width: 8.333333%;
	}
}

@media (min-width: 768px) {
	.grid-md-1 > .grid-item {
		width: 100%;
	}

	.grid-md-2 > .grid-item {
		width: 50%;
	}

	.grid-md-3 > .grid-item {
		width: 33.333333%;
	}

	.grid-md-4 > .grid-item {
		width: 25%;
	}

	.grid-md-5 > .grid-item {
		width: 20%;
	}

	.grid-md-6 > .grid-item {
		width: 16.666667%;
	}

	.grid-md-8 > .grid-item {
		width: 12.50%;
	}

	.grid-md-10 > .grid-item {
		width: 10%;
	}

	.grid-md-12 > .grid-item {
		width: 8.333333%;
	}
}

@media (min-width: 992px) {
	.grid-lg-1 > .grid-item {
		width: 100%;
	}

	.grid-lg-2 > .grid-item {
		width: 50%;
	}

	.grid-lg-3 > .grid-item {
		width: 33.333333%;
	}

	.grid-lg-4 > .grid-item {
		width: 25%;
	}

	.grid-lg-5 > .grid-item {
		width: 20%;
	}

	.grid-lg-6 > .grid-item {
		width: 16.666667%;
	}

	.grid-lg-8 > .grid-item {
		width: 12.50%;
	}

	.grid-lg-10 > .grid-item {
		width: 10%;
	}

	.grid-lg-12 > .grid-item {
		width: 8.333333%;
	}
}

@media (min-width: 1200px) {
	.grid-xl-1 > .grid-item {
		width: 100%;
	}

	.grid-xl-2 > .grid-item {
		width: 50%;
	}

	.grid-xl-3 > .grid-item {
		width: 33.333333%;
	}

	.grid-xl-4 > .grid-item {
		width: 25%;
	}

	.grid-xl-5 > .grid-item {
		width: 20%;
	}

	.grid-xl-6 > .grid-item {
		width: 16.666667%;
	}

	.grid-xl-8 > .grid-item {
		width: 12.50%;
	}

	.grid-xl-10 > .grid-item {
		width: 10%;
	}

	.grid-xl-12 > .grid-item {
		width: 8.333333%;
	}
}

@media (min-width: 1400px) {
	.grid-xxl-1 > .grid-item {
		width: 100%;
	}

	.grid-xxl-2 > .grid-item {
		width: 50%;
	}

	.grid-xxl-3 > .grid-item {
		width: 33.333333%;
	}

	.grid-xxl-4 > .grid-item {
		width: 25%;
	}

	.grid-xxl-5 > .grid-item {
		width: 20%;
	}

	.grid-xxl-6 > .grid-item {
		width: 16.666667%;
	}

	.grid-xxl-8 > .grid-item {
		width: 12.50%;
	}

	.grid-xxl-10 > .grid-item {
		width: 10%;
	}

	.grid-xxl-12 > .grid-item {
		width: 8.333333%;
	}
}


/* Portfolio Single - Content
-----------------------------------------------------------------*/

.portfolio-single-content h2 {
	margin: 0 0 20px;
	padding: 0;
	font-size: 1.25rem;
	font-weight: 600 !important;
}

.modal-padding { padding: 40px; }

.ajax-modal-title {
	background-color: #F9F9F9;
	border-bottom: 1px solid #EEE;
	padding: 25px 40px;
}

.ajax-modal-title h2 {
	font-size: 1.75rem;
	margin-bottom: 0;
}


#portfolio-ajax-wrap {
	position: relative;
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height .4s ease;
	-o-transition: max-height .4s ease;
	transition: max-height .4s ease;
}

#portfolio-ajax-wrap.portfolio-ajax-opened { max-height: 1200px; }

#portfolio-ajax-container {
	display: none;
	padding-bottom: 60px;
}

#portfolio-ajax-title h2 { font-size: 1.75rem; }


/* Portfolio Single - Meta
-----------------------------------------------------------------*/

.portfolio-meta,
.portfolio-share {
	list-style: none;
	font-size: 0.875rem;
}

.portfolio-meta li {
	margin: 10px 0;
	color: #666;
}

.portfolio-meta li:first-child { margin-top: 0; }

.portfolio-meta li span {
	display: inline-block;
	width: 150px;
	font-weight: bold;
	color: #333;
}

.portfolio-meta li span i {
	position: relative;
	top: 1px;
	width: 14px;
	text-align: center;
	margin-right: 7px;
}


/* Portfolio Single Navigation
-----------------------------------------------------------------*/

#portfolio-navigation {
	position: absolute;
	top: 50%;
	left: auto;
	right: 10px;
	max-width: 96px;
	height: 24px;
	margin-top: -12px;
}

.page-title-right #portfolio-navigation {
	left: 10px;
	right: auto;
}

.page-title-center #portfolio-navigation {
	position: relative;
	top: 0;
	left: 0;
	margin: 20px auto 0;
}

#portfolio-navigation a {
	display: block;
	float: left;
	margin-left: 12px;
	width: 24px;
	height: 24px;
	text-align: center;
	color: #444;
	transition: color .3s linear;
	-webkit-transition: color .3s linear;
	-o-transition: color .3s linear;
}

.page-title-dark #portfolio-navigation a { color: #EEE; }

.page-title-parallax #portfolio-navigation a { color: #FFF; }

#portfolio-navigation a i {
	position: relative;
	top: -1px;
	font-size: 1.5rem;
	line-height: 1;
}

#portfolio-navigation a i.icon-angle-left,
#portfolio-navigation a i.icon-angle-right {
	font-size: 32px;
	top: -6px;
}

#portfolio-navigation a i.icon-angle-right { left: -1px; }

#portfolio-navigation a:first-child { margin-left: 0; }

#portfolio-navigation a:hover { color: #1ABC9C; }

#portfolio-ajax-show #portfolio-navigation {
	top: 0;
	margin-top: 0;
}


/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/


#posts { position: relative; }

.entry {
	position: relative;
	margin-bottom: 50px;
}

.posts-md .entry,
.posts-sm .entry {
	margin-bottom: 0;
}

.entry::after {
	content: '';
	position: relative;
	height: 2px;
	margin-top: 50px;
	background-color: #F5F5F5;
}

.entry-image { margin-bottom: 30px; }

.posts-md .entry-image,
.posts-sm .entry-image {
	margin-bottom: 0;
}

.entry-image,
.entry-image > a,
.entry-image .slide a,
.entry-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}

.posts-sm .entry-image {
	width: 64px;
}

.entry-image iframe { display: block; }

.entry-image video {
	display: block;
	width: 100%;
}

.entry-image img { border-radius: 3px; }

.posts-md .entry-image + .entry-title {
	margin-top: 0.875rem;
}

.entry-title h2,
.entry-title h3,
.entry-title h4 {
	margin: 0;
	font-size: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.entry-title.title-sm h2,
.entry-title.title-sm h3 {
	font-size: 1.25rem;
	letter-spacing: 0;
}

.entry-title.title-sm h3 {
	font-size: 1.125rem;
}

.entry-title.title-xs h2,
.entry-title.title-xs h3 {
	font-size: 1rem;
	letter-spacing: 0;
}

.entry-title h4 {
	font-size: 0.875rem;
	letter-spacing: 0;
}

.entry-title.nott h2,
.entry-title.nott h3,
.entry-title h4 {
	text-transform: none;
}

.entry-title h2 a,
.entry-title h3 a,
.entry-title h4 a { color: #333; }

.entry-title h2 a:hover,
.entry-title h3 a:hover,
.entry-title h4 a:hover { color: #1ABC9C; }

.entry-meta {
	position: relative;
	overflow: hidden;
	margin-top: 10px;
}

.posts-sm .entry-meta {
	margin-top: 6px;
}

.single-post .entry-meta { margin-bottom: 20px; }

.posts-md .entry-meta,
.posts-sm .entry-meta {
	margin-bottom: 0px;
}

.entry-meta ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 0 -10px -20px;
	list-style: none;
}

.entry-meta.no-separator ul,
.entry-meta ul.flex-column {
	align-items: start;
	margin-left: 0;
}

.posts-sm .entry-meta ul {
	margin-bottom: -5px;
}

.entry-meta li {
	font-size: 90%;
	margin: 0 15px 10px 0;
	color: #999;
}

.entry-meta ul.flex-column li {
	margin-left: 0;
}

.posts-sm .entry-meta li {
	margin-bottom: 5px;
}

.entry-meta:not(.no-separator) li::before {
	content: '\205E';
	width: 5px;
	text-align: center;
	display: inline-block;
	margin-right: 15px;
	opacity: 0.5;
}

.entry-meta i {
	position: relative;
	top: 1px;
	padding-left: 1px;
	margin-right: 5px;
}

.entry-meta a:not(:hover) { color: #999; }

.entry-meta ins {
	font-weight: 700;
	text-decoration: none;
}

.entry-content { margin-top: 30px; }

.single-post .entry-content { margin-top: 0; }

.posts-md .entry-content,
.posts-sm .entry-content {
	margin-top: 20px;
}

.single-post .entry-content .entry-image { max-width: 350px; }

.entry-content { position: relative; }

.entry-link {
	display: block;
	width: 100%;
	background-color: #F5F5F5;
	padding: 30px 0;
	text-align: center;
	color: #444;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 1.5rem;
	font-weight: 700;
	border-radius: 3px;
}

body:not(.device-touch) .entry-link {
	-webkit-transition: background-color .3s ease-in-out;
	-o-transition: background-color .3s ease-in-out;
	transition: background-color .3s ease-in-out;
}

.entry-link:hover {
	color: #FFF;
	background-color: #1ABC9C;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.entry-link span {
	display: block;
	margin-top: 5px;
	font-family: 'PT Serif', serif;
	font-style: italic;
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0;
	font-size: 0.875rem;
	color: #AAA;
}

.entry-link:hover span { color: #EEE; }

.entry blockquote p {
	font-weight: 400;
	font-family: 'PT Serif', serif;
	font-style: italic;
}


/* Blog - Grid
-----------------------------------------------------------------*/

.post-grid .entry {
	margin-bottom: 30px;
}

.post-grid .entry-title h2 {
	font-size: 1.125rem;
	letter-spacing: 0;
	font-weight: 600;
}

.post-grid .entry-link { font-size: 1.5rem; }

.post-grid .entry-link span { font-size: 0.875rem; }


/* Blog - Timeline
-----------------------------------------------------------------*/

.entry-timeline {
	display: none;
}

@media (min-width: 992px) {

	.timeline-border {
		position: absolute;
		top: 0;
		left: 50%;
		width: 0;
		border-left: 1px dashed #CCC;
		height: 100%;
		transform: translateX(-50%);
	}

	.postcontent .post-timeline::before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		width: 0;
		border-left: 1px dashed #CCC;
		height: 100%;
	}

	.post-timeline .entry-timeline {
		display: none;
		position: absolute;
		border: 2px solid #CCC;
		background-color: #FFF;
		padding-top: 0;
		text-indent: -9999px;
		top: 40px;
		left: auto;
		right: -6px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		font-size: 1.75rem;
		font-weight: bold;
		color: #AAA;
		text-align: center;
		line-height: 1;
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.post-timeline .entry-timeline .timeline-divider {
		position: absolute;
		top: 4px;
		left: auto;
		right: 15px;
		width: 70px;
		height: 0;
		border-top: 1px dashed #CCC;
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.post-timeline .entry:not(.entry-date-section) {
		padding-right: 85px;
	}

	.post-timeline .entry.alt:not(.entry-date-section) {
		padding-right: inherit;
		padding-left: 85px;
	}

	.post-timeline .alt .entry-timeline {
		right: auto;
		left: -6px;
	}

	.post-timeline .alt .entry-timeline .timeline-divider {
		left: 15px;
		right: auto;
	}

	.post-timeline .entry-timeline span {
		display: block;
		margin-top: 3px;
		font-size: 13px;
		font-weight: normal;
	}

	.post-timeline .entry:hover .entry-timeline,
	.post-timeline .entry:hover .timeline-divider {
		border-color: #1ABC9C;
		color: #1ABC9C;
	}

}

.entry.entry-date-section {
	margin: 50px 0 80px;
	text-align: center;
}

.entry.entry-date-section:first-child {
	margin-top: 0;
}

.entry.entry-date-section span {
	display: inline-block;
	width: 250px;
	background-color: #FFF;
	border: 2px solid #EEE;
	font-size: 18px;
	line-height: 50px;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
}

@media (min-width: 992px) {

	/* Blog - Timeline - Sidebar
	-----------------------------------------------------------------*/

	.postcontent .post-timeline::before {
		left: -68px;
		margin-left: 0;
	}

	.postcontent .post-timeline {
		padding-left: 0;
		margin-left: 100px;
		margin-right: 0 !important;
		overflow: visible;
	}

	.postcontent .post-timeline .entry {
		width: 100% !important;
		padding: 0 !important;
	}

	.postcontent .post-timeline .entry-timeline {
		display: block;
		border: 3px solid #CCC;
		background-color: #FFF;
		padding-top: 10px;
		text-indent: 0;
		top: 20px;
		left: -100px;
		right: auto;
		width: 64px;
		height: 64px;
		font-size: 1.5rem;
	}

	.postcontent .post-timeline .entry-timeline div.timeline-divider {
		top: 29px;
		left: 64px;
		width: 32px;
	}

}

.more-link {
	display: inline-block;
	border-bottom: 1px solid #1ABC9C;
	padding: 0 2px;
	font-family: 'PT Serif', serif;
	font-style: italic;
}

.more-link:hover { border-bottom-color: #555; }

.post-navigation + .line { margin-top: 50px; }


/* ----------------------------------------------------------------
	Blog - Author
-----------------------------------------------------------------*/

.author-image {
	float: left;
	width: 64px;
	height: 64px;
	margin-right: 15px;
}

.author-image img {
	width: 64px;
	height: 64px;
}

.author-desc {
	position: relative;
	overflow: hidden;
}


/* ----------------------------------------------------------------
	Comments List
-----------------------------------------------------------------*/


#comments {
	position: relative;
	margin-top: 50px;
	padding-top: 50px;
	border-top: 1px solid #EEE;
}

.commentlist {
	list-style: none;
	padding-bottom: 50px;
	margin: 0 0 50px;
	border-bottom: 1px solid #EEE;
}

#reviews .commentlist {
	padding-bottom: 30px;
	margin: 0 0 20px;
}

.commentlist ul { list-style: none; }

.commentlist li,
.commentlist li ul,
.commentlist li ul li { margin: 30px 0 0 0; }

.commentlist ul:first-child { margin-top: 0; }

.commentlist li {
	position: relative;
	margin: 30px 0 0 30px;
}

#reviews .commentlist li { margin-top: 20px; }

.comment-wrap {
	position: relative;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
	padding: 20px 20px 20px 35px;
}

.commentlist ul .comment-wrap {
	margin-left: 25px;
	padding-left: 20px;
}

#reviews .comment-wrap {
	border: 0;
	padding: 10px 0 0 35px;
}

.commentlist > li:first-child,
#reviews .commentlist > li:first-child {
	padding-top: 0;
	margin-top: 0;
}

.commentlist li .children { margin-top: 0; }

.commentlist li li .children { margin-left: 30px; }

.commentlist li .comment-content,
.pingback {
	position: relative;
	overflow: hidden;
}

.commentlist li .comment-content p,
.pingback p { margin: 20px 0 0 0; }

.commentlist li .comment-content { padding: 0 0 0 15px; }

.commentlist li .comment-meta {
	float: left;
	margin-right: 0;
	line-height: 1;
}

.comment-avatar {
	position: absolute;
	top: 15px;
	left: -35px;
	padding: 4px;
	background: #FFF;
	border: 1px solid #E5E5E5;
	border-radius: 50%;
}

.comment-avatar img {
	display: block;
	border-radius: 50%;
}

.commentlist li .children .comment-avatar { left: -25px; }

.comment-content .comment-author {
	margin-bottom: -10px;
	font-size: 1rem;
	font-weight: bold;
	color: #555;
}

.comment-content .comment-author a {
	border: none;
	color: #333;
}

.comment-content .comment-author a:hover { color: #1ABC9C; }

.comment-content .comment-author span { display: block; }

.comment-content .comment-author span,
.comment-content .comment-author span a {
	font-size: 12px;
	font-weight: normal;
	font-family: 'PT Serif', serif;
	font-style: italic;
	color: #AAA;
}

.comment-content .comment-author span a:hover { color: #888; }

.comment-reply-link,
.review-comment-ratings {
	display: block;
	position: absolute;
	top: 4px;
	left: auto;
	text-align: center;
	right: 0px;
	width: 14px;
	height: 14px;
	color: #CCC;
	font-size: 0.875rem;
	line-height: 1;
}

.review-comment-ratings {
	width: auto;
	color: #333;
}

.comment-reply-link:hover { color: #888; }


/* ----------------------------------------------------------------
	Comment Form
-----------------------------------------------------------------*/


#respond,
#respond form { margin-bottom: 0; }

.commentlist li #respond { margin: 30px 0 0; }

.commentlist li li #respond { margin-left: 30px; }

#respond p { margin: 10px 0 0 0; }

#respond p:first-child { margin-top: 0; }

#respond label small {
	color: #999;
	font-weight: normal;
}

#respond input[type="text"],
#respond textarea { margin-bottom: 0; }

.fb-comments,
.fb_iframe_widget,
.fb-comments > span,
.fb_iframe_widget > span,
.fb-comments > span > iframe,
.fb_iframe_widget > span > iframe {
	display: block !important;
	width: 100% !important;
	margin: 0;
}


/* Post Elements
-----------------------------------------------------------------*/

img.alignleft,
div.alignleft {
	float: left;
	margin: 5px 20px 13px 0;
	max-width: 100%;
}

div.alignleft > img,
div.alignnone > img,
div.aligncenter > img,
div.alignright > img {
	display: block;
	float: none;
}

img.alignnone,
img.aligncenter,
div.alignnone,
div.aligncenter {
	display: block;
	margin: 10px 0;
	float: none;
}

img.aligncenter,
div.aligncenter,
div.aligncenter img {
	margin-left: auto;
	margin-right: auto;
	clear: both;
}

img.alignright,
div.alignright {
	float: right;
	margin: 5px 0 13px 20px;
}

.wp-caption {
	text-align: center;
	margin: 10px 20px 13px 20px;
	font-family: 'Lato', Georgia, "Times New Roman", Times, serif;
	font-style: italic;
}

.wp-caption img,
.wp-caption img a {
	display: block;
	margin: 0;
}

p.wp-caption-text {
	display: inline-block;
	margin: 10px 0 0 0;
	padding: 5px 10px;
	background-color: #EEE;
	border-radius: 50px;
}

.wp-smiley {
	max-height: 13px;
	margin: 0 !important;
}


/* Infinity Scroll - Message Style
-----------------------------------------------------------------*/

#infscr-loading,
#portfolio-ajax-loader {
	position: fixed;
	z-index: 20;
	top: 50%;
	left: 50%;
	width: 48px;
	height: 48px;
	margin: -24px 0 0 -24px;
	background-color: rgba(0,0,0,0.7);
	border-radius: 3px;
	line-height: 48px;
	font-size: 1.5rem;
	color: #FFF;
	text-align: center;
}

#infscr-loading img,
#portfolio-ajax-loader img {
	display: none;
	width: 24px;
	height: 24px;
	margin: 12px;
}

#portfolio-ajax-loader { display: none; }

#portfolio-ajax-loader img { display: block; }

.page-load-status {
	position: relative;
	display: none;
	padding: 30px 0;
}

.page-load-status .css3-spinner-ball-pulse-sync > div { background-color: #333; }


/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/


.shop,
.product {
	position: relative;
}

.product .product-image {
	position: relative;
	overflow: hidden;
}

.product-image > a,
.product-image .slide a,
.product-image img {
	display: block;
	width: 100%;
}

body:not(.device-touch):not(.device-sm):not(.device-xs) .product-image > a {
	-webkit-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}

.product-image > a:nth-of-type(2) {
	opacity: 0;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
}

.product-image:hover > a:nth-of-type(2) {
	opacity: 1;
	z-index: 2;
}

.product-desc {
	padding: 15px 0;
}

.product-title { margin-bottom: 7px; }

.product-title h3 {
	margin: 0;
	font-size: 1.25rem;
}

.product-title h3 a,
.single-product .product-title h2 a { color: #333; }

.product-title h3 a:hover,
.single-product .product-title h2 a:hover { color: #1ABC9C; }

.product-price {
	font-size: 1.125rem;
	font-weight: 700;
	color: #444;
	margin-bottom: 4px;
}

.product-price del {
	font-weight: 400;
	font-size: 90%;
	color: #888;
}

.product-price ins {
	text-decoration: none;
	color: #1ABC9C;
}

.product-image > .badge,
.sale-flash {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 5;
}


/* Shop - Single
-----------------------------------------------------------------*/

.single-product .product {
	width: 100%;
	margin: 0;
}

.shop-quick-view-ajax,
.portfolio-ajax-modal {
	position: relative;
	background-color: #FFF;
	width: auto;
	max-width: 800px;
	margin: 0 auto;
}

.portfolio-ajax-modal {
	max-width: 1000px;
}

.single-product .product-image,
.single-product .product-image img { height: auto; }

.single-product .product-title h2 {
	font-size: 1.75rem;
	margin-bottom: 8px;
}

.single-product .product-price {
	font-size: 1.5rem;
	color: #1ABC9C;
	margin-bottom: 0;
}

.single-product .product-desc .line { margin: 20px 0; }

.quantity {
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	align-items: stretch;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: 30px;
}

.quantity input[type=number]::-webkit-inner-spin-button,
.quantity input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.quantity .qty {
	width: 50px;
	height: 40px;
	line-height: 40px;
	border: 0;
	border-left: 1px solid #DDD;
	border-right: 1px solid #DDD;
	background-color: #EEE;
	text-align: center;
	margin-bottom: 0;
}

.quantity .plus,
.quantity .minus {
	display: block;
	cursor: pointer;
	border: 0px transparent;
	padding: 0;
	width: 36px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #EEE;
	font-size: 1rem;
	font-weight: bold;
	transition: background-color .2s linear;
	-webkit-transition: background-color .2s linear;
	-o-transition: background-color .2s linear;
}

.quantity .plus:hover,
.quantity .minus:hover { background-color: #DDD; }

.quantity .qty:focus,
.quantity .plus:focus,
.quantity .minus:focus {
	box-shadow: none !important;
	outline: 0 !important;
}

.product-meta {
	font-size: 90%;
	color: #777;
}

.product-meta > .card-body > span {
	display: inline-block;
	margin: 0 5px;
}


.single-product .fbox-plain.fbox-sm .fbox-icon {
	width: 2.25rem;
}

.single-product .fbox-plain.fbox-sm .fbox-icon i {
	font-size: 1.125rem;
	line-height: 1.5;
}


/* ----------------------------------------------------------------
	Cart
-----------------------------------------------------------------*/


.cart .remove {
	font-size: 0.875rem;
	color: #FF0000;
}

.cart .remove:hover { color: #000; }

.cart th {
	padding: 12px 15px !important;
	color: #555;
}

.cart td {
	padding: 12px 15px !important;
	vertical-align: middle !important;
	border-color: #E5E5E5 !important;
}

.cart-product-thumbnail a {
	display: block;
	width: 68px;
}

.cart-product-thumbnail img {
	display: block;
	width: 64px;
	height: 64px;
	border: 2px solid #EEE;
}

.cart-product-thumbnail img:hover { border-color: #1ABC9C; }

.cart-product-name a,
.product-name a {
	font-weight: bold;
	font-size: 0.9375rem;
	color: #333;
}

.cart-product-name a:hover,
.product-name a:hover { color: #666; }

.cart-product-price,
.cart-product-quantity,
.cart-product-subtotal { text-align: center !important; }

.cart-product-quantity .quantity {
	margin: 0;
}

td.actions {
	padding: 20px 15px !important;
	vertical-align: top !important;
	background-color: #F5F5F5;
}


@media (max-width: 767.98px) {

	.cart:not(.cart-totals) thead th {
		display: none;
	}

	.cart:not(.cart-totals) tbody td {
		display: block;
		width: 100%;
		border: 0;
		padding: 0 !important;
		text-align: center;
	}

	.cart:not(.cart-totals) .cart_item {
		display: block;
		position: relative;
		border: 1px solid #EEE;
		margin-bottom: 1.5rem;
		padding: 1.5rem;
	}

	.cart:not(.cart-totals) .cart_item .cart-product-remove {
		display: block;
		position: absolute;
		top: 1.25rem;
		left: auto;
		right: 1.25rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.cart:not(.cart-totals) .cart-product-thumbnail,
	.cart:not(.cart-totals) .cart-product-name,
	.cart:not(.cart-totals) .cart-product-quantity {
		margin-bottom: 1rem;
	}

	.cart:not(.cart-totals) .cart_item .cart-product-thumbnail a {
		display: inline-block;
	}

	.cart:not(.cart-totals) .cart_item .cart-product-price {
		display: none;
	}

	.cart:not(.cart-totals) .cart_item .cart-product-name a {
		font-size: 1rem;
	}

	.cart:not(.cart-totals) .cart_item .cart-product-subtotal {
		font-size: 1.25rem;
		color: #1ABC9C;
	}

	.quantity .qty {
		width: 42px;
	}

	.quantity .qty,
	.quantity .plus,
	.quantity .minus {
		font-size: 0.875rem;
	}

}


/* ----------------------------------------------------------------
	Events
-----------------------------------------------------------------*/


.event.entry::after {
	display: none !important;
}

.event .grid-inner {
	background-color: #F9F9F9;
	border-bottom: 3px solid #EEE;
}

.event .entry-image .entry-date {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0,0,0,0.7);
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	width: 60px;
	height: 64px;
	text-align: center;
	padding-top: 9px;
	font-size: 1.75rem;
	line-height: 1;
}

.event .entry-image .entry-date span {
	display: block;
	font-size: 12px;
	margin-top: 7px;
}

.single-event .event-meta .iconlist li,
.parallax .iconlist li { margin: 5px 0; }

.single-event .entry-overlay,
.parallax .entry-overlay-meta {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	background-color: rgba(0,0,0,0.85);
	text-align: center;
	line-height: 80px;
	font-size: 1.25rem;
	color: #FFF;
	z-index: 5;
}

.single-event .countdown-section {
	padding: 0 15px;
	border-left-color: rgba(255,255,255,0.3);
	color: #CCC;
}

.single-event .countdown-amount { color: #FFF; }

.parallax .entry-overlay,
.parallax .entry-overlay-meta {
	background: transparent;
	width: auto;
	text-align: right;
	bottom: 30px;
	left: auto;
	right: 30px;
}

.parallax .entry-overlay-meta {
	padding: 20px;
	font-size: 0.875rem;
	text-align: left;
	right: 38px;
	bottom: 130px;
	width: 368px;
	height: auto;
	line-height: inherit;
	background-color: rgba(0,0,0,0.7);
	border-radius: 3px;
}

.parallax.overlay-left .entry-overlay,
.parallax.overlay-left .entry-overlay-meta {
	right: auto;
	left: 30px;
}

.parallax.overlay-left .entry-overlay-meta { left: 38px; }

@media (max-width: 575.98px) {
	.parallax .entry-overlay-meta,
	.parallax .entry-overlay {
		left: 0 !important;
		right: 0 !important;
		width: 90%;
		margin: 0 auto;
	}
}

.parallax.overlay-center .entry-overlay,
.parallax.overlay-center .entry-overlay-meta {
	bottom: 100px;
	right: auto;
	left: 50%;
	margin-left: -192px;
}

.parallax.overlay-center .entry-overlay-meta {
	top: 100px;
	bottom: auto;
	margin-left: -184px;
}

.parallax .entry-overlay-meta h2 {
	font-size: 1.25rem;
	text-transform: uppercase;
	border-bottom: 1px dashed rgba(255,255,255,0.2);
	padding-bottom: 17px;
}

.parallax .entry-overlay-meta h2 a { color: #FFF; }

.parallax .entry-overlay-meta h2 a:hover { color: #DDD; }

.parallax .countdown { top: 0; }

.parallax .countdown-section {
	border-left: 0;
	width: 80px;
	height: 80px;
	margin: 0 8px;
	font-size: 13px;
	background-color: rgba(0,0,0,0.7);
	border-radius: 3px;
	padding-top: 14px;
}

.parallax .countdown-amount {
	font-size: 28px;
	margin-bottom: 6px;
}


/* ----------------------------------------------------------------

	Countdown

-----------------------------------------------------------------*/

.countdown {
	position: relative;
	display: block;
}

.countdown-row {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.countdown-section {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
	font-size: 11px;
	line-height: 1;
	text-align: center;
	border-left: 1px dotted #BBB;
	color: #888;
	text-transform: capitalize;
}

.countdown-section:first-child { border-left: 0; }

.countdown-amount {
	display: block;
	font-size: 1.25rem;
	color: #333;
	margin-bottom: 5px;
}

.countdown-descr {
	display: block;
	width: 100%;
}


/* Countdown - Medium
-----------------------------------------------------------------*/

.countdown-medium .countdown-section { font-size: 0.875rem; }

.countdown-medium .countdown-amount {
	font-size: 30px;
	margin-bottom: 7px;
}


/* Countdown - Large
-----------------------------------------------------------------*/

.countdown-large .countdown-section { font-size: 18px; }

.countdown-large .countdown-amount {
	font-size: 44px;
	font-weight: 700;
	margin-bottom: 8px;
}


/* Countdown - Inline
-----------------------------------------------------------------*/

.countdown.countdown-inline { display: inline-block; }

.countdown.countdown-inline .countdown-row {
	display: inline-block;
	text-align: center;
}

.countdown.countdown-inline .countdown-section {
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	width: auto;
	border: none;
	color: inherit;
	margin-left: 7px;
	text-transform: inherit;
}

.countdown.countdown-inline .countdown-section:first-child { margin-left: 0; }

.countdown.countdown-inline .countdown-amount {
	display: inline-block;
	font-size: inherit;
	color: inherit;
	font-weight: bold;
	margin: 0 3px 0 0;
}

.countdown.countdown-inline .countdown-descr {
	display: inline-block;
	width: auto;
}


@media (min-width: 768px) {

	/* Countdown - Coming Soon
	-----------------------------------------------------------------*/

	.countdown.countdown-large.coming-soon .countdown-section {
		border: none;
		padding: 15px;
	}

	.countdown.countdown-large.coming-soon .countdown-amount {
		width: 140px;
		height: 140px;
		line-height: 140px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 50%;
		background-color: rgba(0,0,0,0.2);
		margin-bottom: 15px !important;
	}

}

/* ----------------------------------------------------------------
	Buttons
-----------------------------------------------------------------*/


.button {
	display: inline-block;
	position: relative;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
	margin: 5px;
	padding: 8px 22px;
	font-size: 0.875rem;
	line-height: 24px;
	background-color: #1ABC9C;
	color: #FFF;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: none;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

body:not(.device-touch) .button {
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.button.button-dark {
	background-color: #444;
}

.button.button-light {
	background-color: #EEE;
	color: #333;
	text-shadow: none !important;
}

.button:hover {
	background-color: #444;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.button.button-dark:hover { background-color: #1ABC9C; }

.button.button-mini {
	padding: 4px 14px;
	font-size: 11px;
	line-height: 20px;
}

.button.button-small {
	padding: 7px 17px;
	font-size: 12px;
	line-height: 20px;
}

.button.button-large {
	padding: 8px 26px;
	font-size: 1rem;
	line-height: 30px;
}

.button.button-xlarge {
	padding: 9px 32px;
	font-size: 18px;
	letter-spacing: 2px;
	line-height: 34px;
}

.button.button-desc {
	text-align: left;
	padding: 24px 34px;
	font-size: 1.25rem;
	height: auto;
	line-height: 1;
	font-family: 'Poppins', sans-serif;
}

.button.button-desc span {
	display: block;
	margin-top: 10px;
	font-size: 0.875rem;
	font-weight: 400;
	letter-spacing: 1px;
	font-family: 'PT Serif', serif;
	font-style: italic;
	text-transform: none;
}

.button i {
	position: relative;
	margin-right: 5px;
	width: 16px;
	text-align: center;
}

.button.text-end i { margin: 0 0 0 5px; }

.button-mini i { margin-right: 3px; }

.button-mini.text-end i { margin: 0 0 0 3px; }

.button-small i { margin-right: 4px; }

.button-small.text-end i { margin: 0 0 0 4px; }

.button-xlarge i {
	margin-right: 8px;
}

.button-xlarge.text-end i { margin: 0 0 0 8px; }

.button.button-desc i {
	font-size: 48px;
	width: 48px;
	text-align: center;
	margin-right: 12px;
}

.button.button-desc.text-end i { margin: 0 0 0 12px; }

.button.button-desc div { display: inline-block; }

.button.button-rounded { border-radius: 3px; }


/* Buttons - Border
-----------------------------------------------------------------*/

.button.button-border {
	border: 2px solid #444;
	background-color: transparent;
	color: #333;
	font-weight: 700;
	text-shadow: none;
}

.button.button-border-thin {
	border-width: 1px;
}

.button.button-border.button-desc { line-height: 1; }

.button.button-border:not(.button-fill):hover {
	background-color: #444;
	color: #FFF;
	border-color: transparent !important;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.button.button-border.button-light {
	border-color: #FFF;
	color: #FFF;
}

.button.button-border.button-light:hover {
	background-color: #FFF;
	color: #333;
	text-shadow: none;
}


/* Buttons - Border
-----------------------------------------------------------------*/

.button.button-3d {
	border-radius: 3px;
	box-shadow: inset 0 -3px 0 rgba(0,0,0,0.15);
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}

.button.button-3d:hover {
	background-color: #1ABC9C;
	opacity: 0.9;
}

.button.button-3d.button-light:hover,
.button.button-reveal.button-light:hover {
	text-shadow: none;
	color: #333;
}


/* Buttons - Icon Reveal
-----------------------------------------------------------------*/

.button.button-reveal {
	padding-left: 28px;
	padding-right: 28px;
	overflow: hidden;
}

.button.button-reveal i {
	display: block;
	position: absolute;
	top: 0;
	left: -32px;
	width: 32px;
	height: 100%;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	background-color: rgba(0,0,0,0.1);
}

.button.button-reveal.button-border i { top: -2px; }

.button.button-reveal.text-end i {
	left: auto;
	right: -32px;
}

.button.button-reveal.button-3d i,
.button.button-reveal.button-rounded i { border-radius: 3px 0 0 3px; }

.button.button-reveal.button-3d.text-end i,
.button.button-reveal.button-rounded.text-end i { border-radius: 0 3px 3px 0; }

.button.button-reveal span {
	display: inline-block;
	position: relative;
	left: 0;
}

body:not(.device-touch) .button.button-reveal i,
body:not(.device-touch) .button.button-reveal span {
	-webkit-transition: left 0.3s ease, right 0.3s ease;
	-o-transition: left 0.3s ease, right 0.3s ease;
	transition: left 0.3s ease, right 0.3s ease;
}

.button.button-reveal.button-mini {
	padding-left: 17px;
	padding-right: 17px;
}

.button.button-reveal.button-mini i {
	left: -22px;
	width: 22px;
}

.button.button-reveal.button-mini.text-end i {
	left: auto;
	right: -22px;
}

.button.button-reveal.button-small {
	padding-left: 22px;
	padding-right: 22px;
}

.button.button-reveal.button-small i {
	left: -26px;
	width: 26px;
}

.button.button-reveal.button-small.text-end i {
	left: auto;
	right: -26px;
}

.button.button-reveal.button-large {
	padding-left: 32px;
	padding-right: 32px;
}

.button.button-reveal.button-large i {
	left: -38px;
	width: 38px;
}

.button.button-reveal.button-large.text-end i {
	left: auto;
	right: -38px;
}

.button.button-reveal.button-xlarge {
	padding-right: 40px;
	padding-left: 40px;
}

.button.button-reveal.button-xlarge i {
	left: -44px;
	width: 44px;
}

.button.button-reveal.button-xlarge.text-end i {
	left: auto;
	right: -44px;
}

.button.button-reveal:hover i { left: 0; }

.button.button-reveal.text-end:hover i {
	left: auto;
	right: 0;
}

.button.button-reveal:hover span { left: 16px; }

.button.button-reveal.button-mini:hover span { left: 11px; }

.button.button-reveal.button-small:hover span { left: 13px; }

.button.button-reveal.button-large:hover span { left: 19px; }

.button.button-reveal.button-xlarge:hover span { left: 22px; }

.button.button-reveal.text-end:hover span { left: -16px; }

.button.button-reveal.button-mini.text-end:hover span { left: -11px; }

.button.button-reveal.button-small.text-end:hover span { left: -13px; }

.button.button-reveal.button-large.text-end:hover span { left: -19px; }

.button.button-reveal.button-xlarge.text-end:hover span { left: -22px; }


/* Buttons - Promo 100% Width
-----------------------------------------------------------------*/

.button.button-full {
	display: block;
	width: 100%;
	white-space: normal;
	margin: 0;
	height: auto;
	line-height: 1.6;
	padding: 30px 0;
	font-size: 30px;
	font-weight: 300;
	text-transform: none;
	border-radius: 0;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

.button.button-full.button-light { border-bottom: 1px solid rgba(0,0,0,0.15); }

.button.button-full strong {
	font-weight: 700;
	border-bottom: 2px solid #EEE;
}

body:not(.device-touch) .button.button-full strong {
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out;
}

.button.button-full.button-light strong { border-bottom-color: #333; }

.button.button-full.button-light:hover strong { border-bottom-color: #EEE; }


/* Buttons - Circle
-----------------------------------------------------------------*/

.button.button-circle { border-radius: 20px; }

.button.button-mini.button-circle { border-radius: 14px; }

.button.button-small.button-circle { border-radius: 17px; }

.button.button-large.button-circle { border-radius: 23px; }

.button.button-xlarge.button-circle { border-radius: 26px; }


/* Buttons - Fill Effect
-----------------------------------------------------------------*/

.button.button-border.button-fill {
	overflow: hidden;
	transform-style: preserve-3d;
	-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
	-webkit-backface-visibility: hidden;
}

.button.button-border.button-fill span { position: relative; }

.button.button-border.button-fill:hover {
	background-color: transparent !important;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.button.button-border.button-fill.button-light:hover {
	border-color: #EEE !important;
	color: #333 !important;
	text-shadow: none;
}

.button.button-border.button-fill::before {
	content: '';
	position: absolute;
	background-color: #444;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	-webkit-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
	-webkit-backface-visibility: hidden;
}

.button.button-border.button-fill.button-light::before { background-color: #EEE; }

.button.button-border.button-fill.fill-from-right::before {
	left: auto;
	right: 0;
}

.button.button-border.button-fill:hover::before { width: 100%; }

.button.button-border.button-fill.fill-from-top::before,
.button.button-border.button-fill.fill-from-bottom::before {
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.button.button-border.button-fill.fill-from-top::before {
	width: 100%;
	height: 0;
}

.button.button-border.button-fill.fill-from-top:hover::before { height: 100%; }

.button.button-border.button-fill.fill-from-bottom::before {
	width: 100%;
	height: 0;
	top: auto;
	bottom: 0;
}

.button.button-border.button-fill.fill-from-bottom:hover::before { height: 100%; }


/* Buttons - States
-----------------------------------------------------------------*/
.button.disabled,
.button:disabled {
	opacity: 0.65 !important;
	pointer-events: none !important;
}


/* Buttons - Colors
-----------------------------------------------------------------*/

.button-red:not(.button-border) { background-color: #C02942 !important; }
.button-3d.button-red:hover,
.button-reveal.button-red:hover,
.button-border.button-red:hover,
.button-border.button-red.button-fill::before { background-color: #C02942 !important; }

.button-border.button-red {
	color: #C02942;
	border-color: #C02942;
}

.button-teal:not(.button-border) { background-color: #53777A !important; }
.button-3d.button-teal:hover,
.button-reveal.button-teal:hover,
.button-border.button-teal:hover,
.button-border.button-teal.button-fill::before { background-color: #53777A !important; }

.button-border.button-teal {
	color: #53777A;
	border-color: #53777A;
}

.button-light.button-yellow:not(.button-border) { background-color: #ECD078 !important; }
.button-3d.button-yellow:hover,
.button-reveal.button-yellow:hover,
.button-border.button-yellow:hover,
.button-border.button-yellow.button-fill::before { background-color: #ECD078 !important; }

.button-border.button-yellow,
.button-border.button-yellow.button-fill.button-light:hover {
	color: #ECD078 !important;
	border-color: #ECD078 !important;
}

.button-border.button-yellow:hover,
.button-border.button-yellow.button-fill.button-light:hover { color: #333 !important; }

.button-green:not(.button-border) { background-color: #59BA41 !important; }
.button-3d.button-green:hover,
.button-reveal.button-green:hover,
.button-border.button-green:hover,
.button-border.button-green.button-fill::before { background-color: #59BA41 !important; }

.button-border.button-green {
	color: #59BA41;
	border-color: #59BA41;
}

.button-brown:not(.button-border) { background-color: #774F38 !important; }
.button-3d.button-brown:hover,
.button-reveal.button-brown:hover,
.button-border.button-brown:hover,
.button-border.button-brown.button-fill::before { background-color: #774F38 !important; }

.button-border.button-brown {
	color: #774F38;
	border-color: #774F38;
}

.button-aqua:not(.button-border) { background-color: #40C0CB !important; }
.button-3d.button-aqua:hover,
.button-reveal.button-aqua:hover,
.button-border.button-aqua:hover,
.button-border.button-aqua.button-fill::before { background-color: #40C0CB !important; }

.button-border.button-aqua {
	color: #40C0CB;
	border-color: #40C0CB;
}

.button-light.button-lime:not(.button-border) { background-color: #AEE239 !important; }
.button-3d.button-lime:hover,
.button-reveal.button-lime:hover,
.button-border.button-lime:hover,
.button-border.button-lime.button-fill::before { background-color: #AEE239 !important; }

.button-border.button-lime {
	color: #AEE239;
	border-color: #AEE239;
}

.button-purple:not(.button-border) { background-color: #5D4157 !important; }
.button-3d.button-purple:hover,
.button-reveal.button-purple:hover,
.button-border.button-purple:hover,
.button-border.button-purple.button-fill::before { background-color: #5D4157 !important; }

.button-border.button-purple {
	color: #5D4157;
	border-color: #5D4157;
}

.button-leaf:not(.button-border) { background-color: #A8CABA !important; }
.button-3d.button-leaf:hover,
.button-reveal.button-leaf:hover,
.button-border.button-leaf:hover,
.button-border.button-leaf.button-fill::before { background-color: #A8CABA !important; }

.button-border.button-leaf {
	color: #A8CABA;
	border-color: #A8CABA;
}

.button-pink:not(.button-border) { background-color: #F89FA1 !important; }
.button-3d.button-pink:hover,
.button-reveal.button-pink:hover,
.button-border.button-pink:hover,
.button-border.button-pink.button-fill::before { background-color: #F89FA1 !important; }

.button-border.button-pink {
	color: #F89FA1;
	border-color: #F89FA1;
}

.button-dirtygreen:not(.button-border) { background-color: #1693A5 !important; }
.button-3d.button-dirtygreen:hover,
.button-reveal.button-dirtygreen:hover,
.button-border.button-dirtygreen:hover,
.button-border.button-dirtygreen.button-fill::before { background-color: #1693A5 !important; }

.button-border.button-dirtygreen {
	color: #1693A5;
	border-color: #1693A5;
}

.button-blue:not(.button-border) { background-color: #1265A8 !important; }
.button-3d.button-blue:hover,
.button-reveal.button-blue:hover,
.button-border.button-blue:hover,
.button-border.button-blue.button-fill::before { background-color: #1265A8 !important; }

.button-border.button-blue {
	color: #1265A8;
	border-color: #1265A8;
}

.button-amber:not(.button-border) { background-color: #EB9C4D !important; }
.button-3d.button-amber:hover,
.button-reveal.button-amber:hover,
.button-border.button-amber:hover,
.button-border.button-amber.button-fill::before { background-color: #EB9C4D !important; }

.button-border.button-amber {
	color: #EB9C4D;
	border-color: #EB9C4D;
}

.button-black:not(.button-border) { background-color: #111 !important; }
.button-3d.button-black:hover,
.button-reveal.button-black:hover,
.button-border.button-black:hover,
.button-border.button-black.button-fill::before { background-color: #111 !important; }

.button-border.button-black {
	color: #111;
	border-color: #111;
}

.button-white:not(.button-border) { background-color: #F9F9F9 !important; }
.button-3d.button-white:hover,
.button-reveal.button-white:hover { background-color: #F9F9F9 !important; }


/* Buttons - No Hover
-----------------------------------------------------------------*/

.button.button-nohover:hover {
	opacity: inherit !important;
	background-color: inherit !important;
	color: inherit !important;
	border-color: inherit !important;
}


/* ----------------------------------------------------------------
	Promo Boxes
-----------------------------------------------------------------*/


.promo {
	position: relative;
}

.promo h3 {
	font-weight: bold;
	margin-bottom: 0;
}

.promo h3 + span {
	display: block;
	color: #444;
	font-weight: 300;
	font-size: 1.125rem;
	margin-top: 6px;
}


/* Promo Uppercase
-----------------------------------------------------------------*/

.promo-uppercase { text-transform: uppercase; }

.promo-uppercase h3 { font-size: 1.25rem; }

.promo-uppercase h3 + span {
	font-size: 1rem;
}


/* Promo - with Border
-----------------------------------------------------------------*/

.promo-border {
	border: 1px solid #E5E5E5;
	border-radius: 3px;
}


/* Promo - Light
-----------------------------------------------------------------*/

.promo-light {
	background-color: #F5F5F5;
	border-radius: 3px;
}


/* Promo - Dark
-----------------------------------------------------------------*/

.promo-dark {
	border: 0 !important;
	background-color: #333;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	border-radius: 3px;
}

.promo-dark h3 { color: #FFF; }

.promo-dark h3 + span {
	color: #EEE;
	font-weight: 400;
}


/* Promo - 100% Full Width
-----------------------------------------------------------------*/

.promo-full {
	border-radius: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
}


/* Promo - Parallax
-----------------------------------------------------------------*/

.promo.parallax { padding: 80px 0 !important; }


/* Promo - Mini
-----------------------------------------------------------------*/

.promo-mini {
	text-align: left;
}

.promo-mini h3 {
	font-size: 1.25rem;
}

.promo-mini.promo-uppercase h3 { font-size: 18px; }


/* ----------------------------------------------------------------
	Featured Boxes
-----------------------------------------------------------------*/


.feature-box {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
}

.feature-box:first-child { margin-top: 0; }

.fbox-icon {
	width: 5.5rem;
	height: 4rem;
	padding: 0 0.75rem;
}

.fbox-icon a,
.fbox-icon i,
.fbox-icon img {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	color: #FFF;
}

.fbox-icon i,
.fbox-icon img {
	border-radius: 50%;
	background-color: #1ABC9C;
}

.fbox-icon i {
	font-style: normal;
	font-size: 1.75rem;
	line-height: 4rem;
	text-align: center;
}

.fbox-content {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%;
	padding: 0 0.75rem;
}

.fbox-content h3 {
	font-size: 1rem;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin-bottom: 0;
	color: #333;
}

.fbox-content p {
	margin-top: 0.5rem;
	margin-bottom: 0px;
	color: #999;
}

.fbox-content .before-heading {
	font-size: 0.875rem;
}

.fbox-content-sm h3,
.fbox-content-sm p {
	font-size: 0.875rem;
}

.fbox-content-sm .before-heading {
	font-size: 0.75rem;
}

.fbox-content-lg h3 {
	font-size: 1.25rem;
}

.fbox-content-lg p {
	font-size: 1.125rem;
	margin-top: 0.75rem;
}

.fbox-content-lg .before-heading {
	font-size: 1rem;
}


/* Feature Box - Icon Small
-----------------------------------------------------------------*/

.fbox-sm {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}

.fbox-sm .fbox-icon {
	width: 3rem;
	height: 2rem;
	padding: 0 0.5rem;
}

.fbox-sm .fbox-icon i {
	font-size: 1rem;
	line-height: 2rem;
}

.fbox-sm .fbox-content {
	padding: 0 0.5rem;
}

/* Feature Box - Icon Large
-----------------------------------------------------------------*/

.fbox-lg .fbox-icon {
	width: 7.5rem;
	height: 6rem;
}

.fbox-lg .fbox-icon i {
	font-size: 2.5rem;
	line-height: 6rem;
}

/* Feature Box - Icon X-Large
-----------------------------------------------------------------*/

.fbox-xl .fbox-icon {
	width: 9.5rem;
	height: 8rem;
}

.fbox-xl .fbox-icon i {
	font-size: 4.5rem;
	line-height: 8rem;
}


/* Feature Box - Icon Outline
-----------------------------------------------------------------*/

.fbox-outline .fbox-icon a {
	border: 1px solid #1ABC9C;
	padding: 0.25rem;
	border-radius: 50%;
}

.fbox-outline .fbox-icon i {
	line-height: 3.5rem;
}


/* Feature Box - Icon Outline Sizes
-----------------------------------------------------------------*/

.fbox-outline.fbox-sm .fbox-icon i { line-height: 1.5rem; }

.fbox-outline.fbox-lg .fbox-icon i { line-height: 5.5rem; }

.fbox-outline.fbox-xl .fbox-icon i { line-height: 7.5rem; }


/* Feature Box - Icon Rounded
-----------------------------------------------------------------*/

.fbox-rounded .fbox-icon { border-radius: 3px !important; }

.fbox-rounded .fbox-icon i,
.fbox-rounded .fbox-icon img { border-radius: 3px !important; }


/* Feature Box - Icon Rounded Sizes
-----------------------------------------------------------------*/

.fbox-rounded.fbox-lg .fbox-icon { border-radius: 4px !important; }

.fbox-rounded.fbox-lg .fbox-icon i,
.fbox-rounded.fbox-lg .fbox-icon img { border-radius: 4px !important; }

.fbox-rounded.fbox-xl .fbox-icon { border-radius: 6px !important; }

.fbox-rounded.fbox-xl .fbox-icon i,
.fbox-rounded.fbox-xl .fbox-icon img { border-radius: 6px !important; }


/* Feature Box - Light Background
-----------------------------------------------------------------*/

.fbox-light.fbox-outline .fbox-icon a { border-color: #E5E5E5; }

.fbox-light .fbox-icon i,
.fbox-light .fbox-icon img {
	border: 1px solid #E5E5E5;
	background-color: #F5F5F5;
	color: #444;
}


/* Feature Box - Dark Background
-----------------------------------------------------------------*/

.fbox-dark.fbox-outline .fbox-icon a { border-color: #444; }

.fbox-dark .fbox-icon i,
.fbox-dark .fbox-icon img { background-color: #333; }


/* Feature Box - Border
-----------------------------------------------------------------*/

.fbox-border .fbox-icon a {
	border: 1px solid #1ABC9C;
	border-radius: 50%;
}

.fbox-border .fbox-icon i,
.fbox-border .fbox-icon img {
	border: none;
	background-color: transparent !important;
	color: #1ABC9C;
}


/* Feature Box - Border - Light
-----------------------------------------------------------------*/

.fbox-border.fbox-light .fbox-icon a { border-color: #E5E5E5; }

.fbox-border.fbox-light .fbox-icon i,
.fbox-border.fbox-light .fbox-icon img { color: #888; }


/* Feature Box - Border - Dark
-----------------------------------------------------------------*/

.fbox-border.fbox-dark .fbox-icon a { border-color: #333; }

.fbox-border.fbox-dark .fbox-icon i,
.fbox-border.fbox-dark .fbox-icon img { color: #444; }


/* Feature Box - Plain
-----------------------------------------------------------------*/

.fbox-plain .fbox-icon {
	border: none !important;
}

.fbox-plain .fbox-icon i,
.fbox-plain .fbox-icon img {
	border: none;
	background-color: transparent !important;
	color: #1ABC9C;
	border-radius: 0;
}

.fbox-plain .fbox-icon i {
	font-size: 3rem;
	line-height: 1;
}

.fbox-plain.fbox-image .fbox-icon { width: auto; }

.fbox-plain.fbox-image .fbox-icon img {
	width: auto;
	display: inline-block;
}

.fbox-plain.fbox-light .fbox-icon i,
.fbox-plain.fbox-light .fbox-icon img { color: #888; }

.fbox-plain.fbox-dark .fbox-icon i,
.fbox-plain.fbox-dark .fbox-icon img { color: #444; }


/* Feature Box - Icon Plain Sizes
-----------------------------------------------------------------*/

.fbox-plain.fbox-sm .fbox-icon i { font-size: 2rem; }

.fbox-plain.fbox-lg .fbox-icon i { font-size: 4.5rem; }

.fbox-plain.fbox-xl .fbox-icon i { font-size: 6rem; }


/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/

.fbox-center {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.fbox-center .fbox-icon {
	margin-bottom: 1.5rem;
}

.fbox-center .fbox-content {
	-ms-flex-preferred-size: auto;
}


/* Feature Box - Subtitle
-----------------------------------------------------------------*/

.fbox-content + .fbox-icon {
	margin-top: 1.5rem;
	margin-bottom: 0 !important;
}

.fbox-content h3 span.subtitle {
	display: block;
	margin-top: 5px;
	color: #444;
	font-weight: 300;
	text-transform: none;
}

.fbox-center.fbox-sep:not(.fbox-bg) h3::after {
	content: '';
	display: block;
	margin: 1.25rem auto;
	width: 2rem;
	height: 2px;
	background-color: #555;
	-webkit-transition: width .3s ease;
	-o-transition: width .3s ease;
	transition: width .3s ease;
}

.fbox-center.fbox-sep:not(.fbox-bg):hover h3::after { width: 3rem; }

.fbox-center.fbox-italic p {
	font-style: italic;
	font-family: 'Lato', sans-serif;
}


/* Feature Box - Background
-----------------------------------------------------------------*/

.fbox-bg.fbox-center {
	margin-top: 2rem;
	margin-left: 0;
	margin-right: 0;
	padding: 0 2rem 2rem;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
}

.fbox-bg.fbox-center .fbox-icon {
	margin-top: -2rem;
}

.fbox-bg.fbox-center .fbox-icon { background-color: #FFF; }


.fbox-bg.fbox-center.fbox-lg {
	margin-top: 3rem;
}

.fbox-bg.fbox-center.fbox-lg .fbox-icon {
	margin-top: -3rem;
}

.fbox-bg.fbox-center.fbox-xl {
	margin-top: 4rem;
}

.fbox-bg.fbox-center.fbox-xl .fbox-icon {
	margin-top: -4rem;
}


/* Animated Icon Hovers
-----------------------------------------------------------------*/

.fbox-effect .fbox-icon i { z-index: 1; }

.fbox-effect .fbox-icon i::after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.fbox-rounded.fbox-effect .fbox-icon i::after { border-radius: 3px; }

.fbox-effect .fbox-icon i {
	-webkit-transition: background-color 0.3s, color 0.3s;
	-o-transition: background-color 0.3s, color 0.3s;
	transition: background-color 0.3s, color 0.3s;
}

.fbox-effect .fbox-icon i::after {
	top: -3px;
	left: -3px;
	padding: 3px;
	box-shadow: 0 0 0 2px #333;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-webkit-transform: scale(.8);
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	-moz-transform: scale(.8);
	-ms-transform: scale(.8);
	transition: transform 0.3s, opacity 0.3s;
	transform: scale(.8);
	opacity: 0;
}

.fbox-effect .fbox-icon i:hover,
.fbox-effect:hover .fbox-icon i {
	background-color: #333;
	color: #FFF;
}

.fbox-effect.fbox-dark .fbox-icon i::after { box-shadow: 0 0 0 2px #1ABC9C; }

.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-effect.fbox-dark:hover .fbox-icon i { background-color: #1ABC9C; }

.fbox-effect .fbox-icon i:hover::after,
.fbox-effect:hover .fbox-icon i::after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Icon Effects - Bordered
-----------------------------------------------------------------*/

.fbox-border.fbox-effect .fbox-icon i {
	-webkit-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
	-o-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
	transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
}

.fbox-border.fbox-effect .fbox-icon i::after {
	top: -2px;
	left: -2px;
	padding: 2px;
	z-index: -1;
	box-shadow: none;
	background-image: url('../assets/html-assets/icons/iconalt.svg');
	background-position: center center;
	background-size: 100% 100%;
	-webkit-transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s;
	-o-transition: -moz-transform 0.5s, opacity 0.5s, background-color 0.5s;
	transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
}

.fbox-border.fbox-rounded.fbox-effect .fbox-icon i::after { border-radius: 3px; }

.fbox-border.fbox-effect .fbox-icon i:hover,
.fbox-border.fbox-effect:hover .fbox-icon i {
	color: #FFF;
	box-shadow: 0 0 0 1px #333;
}

.fbox-border.fbox-effect .fbox-icon i::after {
	-webkit-transform: scale(1.3);
	-moz-transform: scale(1.3);
	-ms-transform: scale(1.3);
	transform: scale(1.3);
	opacity: 0;
	background-color: #333;
}

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i { box-shadow: 0 0 0 1px #1ABC9C; }

.fbox-border.fbox-effect.fbox-dark .fbox-icon i::after { background-color: #1ABC9C; }

.fbox-border.fbox-effect .fbox-icon i:hover::after,
.fbox-border.fbox-effect:hover .fbox-icon i::after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}


/* Media Featured Box
-----------------------------------------------------------------*/

.media-box {
	padding: 0;
	margin-left: 0;
	margin-right: 0;
	-ms-flex-direction: column;
	flex-direction: column;
}

.media-box .fbox-media { margin: 0 0 1.5rem; }

.media-box .fbox-media,
.media-box .fbox-media a,
.media-box .fbox-media img {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
}

.media-box .fbox-media iframe { display: block; }

.media-box p { margin-top: 1rem; }

.media-box .fbox-content {
	-ms-flex-preferred-size: auto;
}

.media-box.fbox-bg .fbox-media { margin: 0; }

.media-box.fbox-bg .fbox-content {
	padding: 1.5rem;
	background-color: #FFF;
	border: 1px solid #E5E5E5;
	border-top: 0;
	border-radius: 0 0 5px 5px;
}

.media-box.fbox-bg .fbox-media img { border-radius: 5px 5px 0 0; }


/* ----------------------------------------------------------------
	Flipbox
-----------------------------------------------------------------*/

.flipbox { perspective: 1000px; }

.flipbox-inner {
	transition: 0.5s;
	transition-timing-function: ease;
	position: relative;
	transform-style: preserve-3d;
}

.flipbox-front,
.flipbox-back {
	-webkit-backface-visibility: hidden;
}

.flipbox-front { z-index: 2; }

.flipbox-back {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.flipbox:not(.flipbox-vertical) .flipbox-front { transform: rotateY(0deg); }
.flipbox:not(.flipbox-vertical) .flipbox-back { transform: rotateY(-180deg); }

.flipbox.flipbox-vertical .flipbox-front { transform: rotateX(0deg); }
.flipbox.flipbox-vertical .flipbox-back { transform: rotateX(-180deg); }

.flipbox:not(.flipbox-vertical):hover .flipbox-inner,
.flipbox:not(.flipbox-vertical).hover .flipbox-inner { transform: rotateY(180deg); }

.flipbox.flipbox-vertical:hover .flipbox-inner,
.flipbox.flipbox-vertical.hover .flipbox-inner { transform: rotateX(180deg); }


/* ----------------------------------------------------------------
	Process Steps
-----------------------------------------------------------------*/

.process-steps {
	margin: 0 0 40px;
	list-style: none;
}

.process-steps li {
	position: relative;
	text-align: center;
	overflow: hidden;
}

.process-steps li .i-circled { margin-bottom: 0 !important; }

.process-steps li .i-bordered { background-color: #FFF; }

.process-steps li h5 {
	margin: 15px 0 0 0;
	font-size: 1rem;
	font-weight: 300;
}

.process-steps li.active h5,
.process-steps li.ui-tabs-active h5 {
	font-weight: 600;
	color: #1ABC9C;
}

.process-steps li.active a,
.process-steps li.ui-tabs-active a {
	background-color: #1ABC9C !important;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	border-color: #1ABC9C !important;
}

@media (min-width: 992px) {

	.process-steps li::before,
	.process-steps li::after {
		content: '';
		position: absolute;
		top: 30px;
		left: 0;
		margin-left: -26px;
		width: 50%;
		border-top: 1px dashed #DDD;
	}

	.process-steps li::after {
		left: auto;
		right: 0;
		margin: 0 -26px 0 0;
	}

	.process-steps li:first-child::before,
	.process-steps li:last-child::after { display: none; }

}


/* ----------------------------------------------------------------
	Alerts
-----------------------------------------------------------------*/


.style-msg,
.style-msg2 {
	position: relative;
	display: block;
	color: #444;
	border-left: 4px solid rgba(0,0,0,0.1);
	margin-bottom: 20px;
}

.style-msg2 { border-left: 4px solid rgba(0,0,0,0.2); }

.successmsg {
	background: #d4edda;
	color: #155724;
}

.errormsg {
	background: #f8d7da;
	color: #721c24;
}

.infomsg {
	background: #d1ecf1;
	color: #0c5460;
}

.alertmsg {
	background: #fff3cd;
	color: #856404;
}

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
	display: block;
	padding: 15px;
	border-left: 1px solid rgba(255,255,255,0.5);
	font-size: 1rem;
}

.style-msg2 .msgtitle {
	background: rgba(0,0,0,0.15);
	border-top: none;
	border-bottom: none;
	font-weight: bold;
}

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
	color: #FFF;
}

.style-msg2 .sb-msg { font-weight: normal; }

.style-msg2 ol,
.style-msg2 ul { margin: 0 0 0 30px; }

.style-msg2 ol { list-style-type: decimal; }

.style-msg2 ul { list-style-type: disc; }

.alert i,
.sb-msg i,
.msgtitle i {
	position: relative;
	top: 1px;
	font-size: 0.875rem;
	width: 14px;
	text-align: center;
	margin-right: 5px;
}

.style-msg .btn-close {
	position: absolute;
	top: 0px;
	left: auto;
	right: 0px;
}


/* ----------------------------------------------------------------
	Styled Icons
-----------------------------------------------------------------*/


.i-rounded,
.i-plain,
.i-circled,
.i-bordered,
.social-icon {
	display: block;
	float: left;
	margin: 4px 11px 7px 0;
	text-align: center !important;
	font-size: 28px;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	cursor: pointer;
	font-style: normal;
}

body:not(.device-touch) .i-rounded,
body:not(.device-touch) .i-plain,
body:not(.device-touch) .i-circled,
body:not(.device-touch) .i-bordered,
body:not(.device-touch) .social-icon {
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.i-rounded,
.i-circled,
.i-bordered {
	width: 52px !important;
	height: 52px !important;
	line-height: 52px !important;
	background-color: #444;
	border-radius: 3px;
}

.i-bordered {
	border: 1px solid #555;
	line-height: 50px !important;
	background-color: transparent !important;
	color: #444;
	text-shadow: none;
}

.i-plain {
	width: 36px !important;
	height: 36px !important;
	font-size: 28px;
	line-height: 36px !important;
	color: #444;
	text-shadow: 1px 1px 1px #FFF;
}

.i-plain:hover { color: #777; }

.i-circled { border-radius: 50%; }

.i-light {
	background-color: #F5F5F5;
	color: #444;
	text-shadow: 1px 1px 1px #FFF;
	box-shadow: inset 0 0 15px rgba(0,0,0,0.2);
}

.i-alt {
	background-image: url('../assets/html-assets/icons/iconalt.svg');
	background-position: center center;
	background-size: 100% 100%;
}


.i-rounded:hover,
.i-circled:hover {
	background-color: #1ABC9C;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	box-shadow: 0 0 0 rgba(0,0,0,0.2);
}

.i-bordered:hover {
	background-color: #444 !important;
	color: #FFF;
	border-color: #444;
}


/* Icon Size - Small
-----------------------------------------------------------------*/

.i-small.i-rounded,
.i-small.i-plain,
.i-small.i-circled,
.i-small.i-bordered {
	margin: 4px 11px 7px 0;
	font-size: 0.875rem;
}

.i-small.i-rounded,
.i-small.i-circled,
.i-small.i-bordered {
	width: 28px !important;
	height: 28px !important;
	line-height: 28px !important;
}

.i-small.i-bordered { line-height: 26px !important; }

.i-small.i-plain {
	width: 18px !important;
	height: 18px !important;
	font-size: 0.875rem;
	line-height: 18px !important;
}


/* Icon Size - Small
-----------------------------------------------------------------*/

.i-medium.i-rounded,
.i-medium.i-plain,
.i-medium.i-circled,
.i-medium.i-bordered {
	margin: 6px 13px 9px 0;
	font-size: 21px;
}

.i-medium.i-rounded,
.i-medium.i-circled,
.i-medium.i-bordered {
	width: 36px !important;
	height: 36px !important;
	line-height: 36px !important;
}

.i-medium.i-bordered { line-height: 34px !important; }

.i-medium.i-plain {
	width: 28px !important;
	height: 28px !important;
	font-size: 1.25rem;
	line-height: 28px !important;
}


/* Icon Size - Large
-----------------------------------------------------------------*/

.i-large.i-rounded,
.i-large.i-plain,
.i-large.i-circled,
.i-large.i-bordered {
	margin: 4px 11px 7px 0;
	font-size: 42px;
}

.i-large.i-rounded,
.i-large.i-circled,
.i-large.i-bordered {
	width: 64px !important;
	height: 64px !important;
	line-height: 64px !important;
}

.i-large.i-bordered { line-height: 62px !important; }

.i-large.i-plain {
	width: 48px !important;
	height: 48px !important;
	font-size: 42px;
	line-height: 48px !important;
}


/* Icon Size - Extra Large
-----------------------------------------------------------------*/

.i-xlarge.i-rounded,
.i-xlarge.i-plain,
.i-xlarge.i-circled,
.i-xlarge.i-bordered {
	margin: 4px 11px 7px 0;
	font-size: 56px;
}

.i-xlarge.i-rounded,
.i-xlarge.i-circled,
.i-xlarge.i-bordered {
	width: 84px !important;
	height: 84px !important;
	line-height: 84px !important;
}

.i-xlarge.i-bordered { line-height: 82px !important; }

.i-xlarge.i-plain {
	width: 64px !important;
	height: 64px !important;
	font-size: 56px;
	line-height: 64px !important;
}


/* Icon Size - Extra Extra Large
-----------------------------------------------------------------*/

.i-xxlarge.i-rounded,
.i-xxlarge.i-plain,
.i-xxlarge.i-circled,
.i-xxlarge.i-bordered {
	margin: 6px 15px 10px 0;
	font-size: 64px;
}

.i-xxlarge.i-rounded,
.i-xxlarge.i-circled,
.i-xxlarge.i-bordered {
	width: 96px !important;
	height: 96px !important;
	line-height: 96px !important;
}

.i-xxlarge.i-bordered { line-height: 94px !important; }

.i-xxlarge.i-plain {
	width: 80px !important;
	height: 80px !important;
	font-size: 64px;
	line-height: 80px !important;
}


/* ----------------------------------------------------------------
	Social Icons
-----------------------------------------------------------------*/


.social-icon {
	margin: 0 5px 5px 0;
	width: 40px;
	height: 40px;
	font-size: 1.25rem;
	line-height: 38px !important;
	color: #555;
	text-shadow: none;
	border: 1px solid #AAA;
	border-radius: 3px;
	overflow: hidden;
}

.d-flex > .social-icon,
.d-sm-flex > .social-icon,
.d-md-flex > .social-icon,
.d-lg-flex > .social-icon,
.d-xl-flex > .social-icon,
.d-xxl-flex > .social-icon {
	float: none;
	margin-bottom: 0px;
}


/* Social Icons - Rounded
-----------------------------------------------------------------*/

.si-rounded { border-radius: 50%; }

/* Social Icons - Borderless
-----------------------------------------------------------------*/

.si-borderless { border-color: transparent !important; }

/* Social Icons - Dark
-----------------------------------------------------------------*/

.si-dark {
	background-color: #444;
	color: #FFF !important;
	border-color: transparent;
}

/* Social Icons - Light
-----------------------------------------------------------------*/

.si-light {
	background-color: rgba(0,0,0,0.05);
	color: #666 !important;
	border-color: transparent;
}

/* Social Icons - Colored
-----------------------------------------------------------------*/

.si-colored { border-color: transparent !important; }

.si-colored i { color: #FFF; }

/* Social Icons - Large
-----------------------------------------------------------------*/

.social-icon.si-large {
	margin: 0 10px 10px 0;
	width: 56px;
	height: 56px;
	font-size: 30px;
	line-height: 54px !important;
}

/* Social Icons - Small
-----------------------------------------------------------------*/

.social-icon.si-small {
	width: 32px;
	height: 32px;
	font-size: 0.875rem;
	line-height: 30px !important;
}

.social-icon i {
	display: block;
	position: relative;
}

body:not(.device-touch) .social-icon i {
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.social-icon i:last-child { color: #FFF !important; }

.social-icon:hover i:first-child { margin-top: -38px; }

.social-icon.si-large:hover i:first-child { margin-top: -54px; }

.social-icon.si-small:hover i:first-child { margin-top: -30px; }

.social-icon:hover {
	color: #555;
	border-color: transparent;
}

.si-colored.social-icon { border-color: transparent; }

/* Social Icons - Sticky
-----------------------------------------------------------------*/

.si-sticky {
	position: fixed;
	top: 50%;
	left: 5px;
	width: 36px;
	z-index: 499;
	transform: translateY(-50%);
}

/* Social Icons - Sticky Right
-----------------------------------------------------------------*/

.si-sticky.si-sticky-right {
	left: auto;
	right: 8px;
}

/* Social Icons - Share
-----------------------------------------------------------------*/

.si-share {
	position: relative;
	padding-left: 5px;
	border-top: 1px solid #EEE;
	border-bottom: 1px solid #EEE;
}

.si-share span {
	line-height: 36px;
	font-weight: bold;
	font-size: 13px;
}

.si-share .social-icon {
	margin: -1px 0;
	border-radius: 0;
	font-size: 15px;
}

.si-facebook:hover,
.si-colored.si-facebook { background-color: #3B5998 !important; }
.si-delicious:hover,
.si-colored.si-delicious { background-color: #205CC0 !important; }
.si-paypal:hover,
.si-colored.si-paypal { background-color: #00588B !important; }
.si-flattr:hover,
.si-colored.si-flattr { background-color: #F67C1A !important; }
.si-android:hover,
.si-colored.si-android { background-color: #A4C639 !important; }
.si-smashmag:hover,
.si-colored.si-smashmag { background-color: #E53B2C !important; }
.si-gplus:hover,
.si-colored.si-gplus { background-color: #DD4B39 !important; }
.si-wikipedia:hover,
.si-colored.si-wikipedia { background-color: #333 !important; }
.si-stumbleupon:hover,
.si-colored.si-stumbleupon { background-color: #F74425 !important; }
.si-foursquare:hover,
.si-colored.si-foursquare { background-color: #25A0CA !important; }
.si-call:hover,
.si-colored.si-call { background-color: #444 !important; }
.si-ninetyninedesigns:hover,
.si-colored.si-ninetyninedesigns { background-color: #F26739 !important; }
.si-forrst:hover,
.si-colored.si-forrst { background-color: #5B9A68 !important; }
.si-digg:hover,
.si-colored.si-digg { background-color: #191919 !important; }
.si-spotify:hover,
.si-colored.si-spotify { background-color: #81B71A !important; }
.si-reddit:hover,
.si-colored.si-reddit { background-color: #C6C6C6 !important; }
.si-blogger:hover,
.si-colored.si-blogger { background-color: #FC4F08 !important; }
.si-cc:hover,
.si-colored.si-cc { background-color: #688527 !important; }
.si-dribbble:hover,
.si-colored.si-dribbble { background-color: #EA4C89 !important; }
.si-evernote:hover,
.si-colored.si-evernote { background-color: #5BA525 !important; }
.si-flickr:hover,
.si-colored.si-flickr { background-color: #FF0084 !important; }
.si-google:hover,
.si-colored.si-google { background-color: #DD4B39 !important; }
.si-instapaper:hover,
.si-colored.si-instapaper { background-color: #333 !important; }
.si-klout:hover,
.si-colored.si-klout { background-color: #FF5F52 !important; }
.si-linkedin:hover,
.si-colored.si-linkedin { background-color: #0E76A8 !important; }
.si-vk:hover,
.si-colored.si-vk { background-color: #2B587A !important; }
.si-rss:hover,
.si-colored.si-rss { background-color: #EE802F !important; }
.si-skype:hover,
.si-colored.si-skype { background-color: #00AFF0 !important; }
.si-twitter:hover,
.si-colored.si-twitter { background-color: #00ACEE !important; }
.si-youtube:hover,
.si-colored.si-youtube { background-color: #C4302B !important; }
.si-vimeo:hover,
.si-colored.si-vimeo { background-color: #86C9EF !important; }
.si-aim:hover,
.si-colored.si-aim { background-color: #FCD20B !important; }
.si-yahoo:hover,
.si-colored.si-yahoo { background-color: #720E9E !important; }
.si-email3:hover,
.si-colored.si-email3 { background-color: #6567A5 !important; }
.si-macstore:hover,
.si-colored.si-macstore { background-color: #333333 !important; }
.si-myspace:hover,
.si-colored.si-myspace { background-color: #666666 !important; }
.si-podcast:hover,
.si-colored.si-podcast { background-color: #E4B21B !important; }
.si-cloudapp:hover,
.si-colored.si-cloudapp { background-color: #525557 !important; }
.si-dropbox:hover,
.si-colored.si-dropbox { background-color: #3D9AE8 !important; }
.si-ebay:hover,
.si-colored.si-ebay { background-color: #89C507 !important; }
.si-github:hover,
.si-colored.si-github { background-color: #171515 !important; }
.si-googleplay:hover,
.si-colored.si-googleplay { background-color: #DD4B39 !important; }
.si-itunes:hover,
.si-colored.si-itunes { background-color: #222 !important; }
.si-plurk:hover,
.si-colored.si-plurk { background-color: #CF5A00 !important; }
.si-pinboard:hover,
.si-colored.si-pinboard { background-color: #0000E6 !important; }
.si-soundcloud:hover,
.si-colored.si-soundcloud { background-color: #FF7700 !important; }
.si-tumblr:hover,
.si-colored.si-tumblr { background-color: #34526F !important; }
.si-wordpress:hover,
.si-colored.si-wordpress { background-color: #1E8CBE !important; }
.si-yelp:hover,
.si-colored.si-yelp { background-color: #C41200 !important; }
.si-intensedebate:hover,
.si-colored.si-intensedebate { background-color: #009EE4 !important; }
.si-eventbrite:hover,
.si-colored.si-eventbrite { background-color: #F16924 !important; }
.si-scribd:hover,
.si-colored.si-scribd { background-color: #666666 !important; }
.si-stripe:hover,
.si-colored.si-stripe { background-color: #008CDD !important; }
.si-print:hover,
.si-colored.si-print { background-color: #111 !important; }
.si-dwolla:hover,
.si-colored.si-dwolla { background-color: #FF5C03 !important; }
.si-statusnet:hover,
.si-colored.si-statusnet { background-color: #131A30 !important; }
.si-acrobat:hover,
.si-colored.si-acrobat { background-color: #D3222A !important; }
.si-drupal:hover,
.si-colored.si-drupal { background-color: #27537A !important; }
.si-buffer:hover,
.si-colored.si-buffer { background-color: #333333 !important; }
.si-pocket:hover,
.si-colored.si-pocket { background-color: #EE4056 !important; }
.si-bitbucket:hover,
.si-colored.si-bitbucket { background-color: #0E4984 !important; }
.si-stackoverflow:hover,
.si-colored.si-stackoverflow { background-color: #EF8236 !important; }
.si-hackernews:hover,
.si-colored.si-hackernews { background-color: #FF6600 !important; }
.si-xing:hover,
.si-colored.si-xing { background-color: #126567 !important; }
.si-instagram:hover,
.si-colored.si-instagram { background-color: #8A3AB9 !important; }
.si-quora:hover,
.si-colored.si-quora { background-color: #A82400 !important; }
.si-openid:hover,
.si-colored.si-openid { background-color: #E16309 !important; }
.si-steam:hover,
.si-colored.si-steam { background-color: #111 !important; }
.si-amazon:hover,
.si-colored.si-amazon { background-color: #E47911 !important; }
.si-disqus:hover,
.si-colored.si-disqus { background-color: #E4E7EE !important; }
.si-plancast:hover,
.si-colored.si-plancast { background-color: #222 !important; }
.si-appstore:hover,
.si-colored.si-appstore { background-color: #000 !important; }
.si-pinterest:hover,
.si-colored.si-pinterest { background-color: #C8232C !important; }
.si-fivehundredpx:hover,
.si-colored.si-fivehundredpx { background-color: #111 !important; }
.si-tiktok:hover,
.si-colored.si-tiktok { background-color: #000 !important; }
.si-whatsapp:hover,
.si-colored.si-whatsapp { background-color: #25D366 !important; }
.si-tripadvisor:hover,
.si-colored.si-tripadvisor { background-color: #00AF87 !important; }
.si-gpay:hover,
.si-colored.si-gpay { background-color: #6877DF !important; }
.si-unsplash:hover,
.si-colored.si-unsplash { background-color: #111 !important; }
.si-snapchat:hover,
.si-colored.si-snapchat { background-color: #FFFC00 !important; }


/* Social Icons Text Color
-----------------------------------------------------------------*/

.si-text-color.si-facebook i { color: #3B5998; }
.si-text-color.si-delicious i { color: #205CC0; }
.si-text-color.si-paypal i { color: #00588B; }
.si-text-color.si-flattr i { color: #F67C1A; }
.si-text-color.si-android i { color: #A4C639; }
.si-text-color.si-smashmag i { color: #E53B2C; }
.si-text-color.si-gplus i { color: #DD4B39; }
.si-text-color.si-wikipedia i { color: #333; }
.si-text-color.si-stumbleupon i { color: #F74425; }
.si-text-color.si-foursquare i { color: #25A0CA; }
.si-text-color.si-call i { color: #444; }
.si-text-color.si-ninetyninedesigns i { color: #F26739; }
.si-text-color.si-forrst i { color: #5B9A68; }
.si-text-color.si-digg i { color: #191919; }
.si-text-color.si-spotify i { color: #81B71A; }
.si-text-color.si-reddit i { color: #C6C6C6; }
.si-text-color.si-blogger i { color: #FC4F08; }
.si-text-color.si-cc i { color: #688527; }
.si-text-color.si-dribbble i { color: #EA4C89; }
.si-text-color.si-evernote i { color: #5BA525; }
.si-text-color.si-flickr i { color: #FF0084; }
.si-text-color.si-google i { color: #DD4B39; }
.si-text-color.si-instapaper i { color: #333; }
.si-text-color.si-klout i { color: #FF5F52; }
.si-text-color.si-linkedin i { color: #0E76A8; }
.si-text-color.si-vk i { color: #2B587A; }
.si-text-color.si-rss i { color: #EE802F; }
.si-text-color.si-skype i { color: #00AFF0; }
.si-text-color.si-twitter i { color: #00ACEE; }
.si-text-color.si-youtube i { color: #C4302B; }
.si-text-color.si-vimeo i { color: #86C9EF; }
.si-text-color.si-aim i { color: #FCD20B; }
.si-text-color.si-yahoo i { color: #720E9E; }
.si-text-color.si-email3 i { color: #6567A5; }
.si-text-color.si-macstore i { color: #333333; }
.si-text-color.si-myspace i { color: #666666; }
.si-text-color.si-podcast i { color: #E4B21B; }
.si-text-color.si-cloudapp i { color: #525557; }
.si-text-color.si-dropbox i { color: #3D9AE8; }
.si-text-color.si-ebay i { color: #89C507; }
.si-text-color.si-github i { color: #171515; }
.si-text-color.si-googleplay i { color: #DD4B39; }
.si-text-color.si-itunes i { color: #222; }
.si-text-color.si-plurk i { color: #CF5A00; }
.si-text-color.si-pinboard i { color: #0000E6; }
.si-text-color.si-soundcloud i { color: #FF7700; }
.si-text-color.si-tumblr i { color: #34526F; }
.si-text-color.si-wordpress i { color: #1E8CBE; }
.si-text-color.si-yelp i { color: #C41200; }
.si-text-color.si-intensedebate i { color: #009EE4; }
.si-text-color.si-eventbrite i { color: #F16924; }
.si-text-color.si-scribd i { color: #666666; }
.si-text-color.si-stripe i { color: #008CDD; }
.si-text-color.si-print i { color: #111; }
.si-text-color.si-dwolla i { color: #FF5C03; }
.si-text-color.si-statusnet i { color: #131A30; }
.si-text-color.si-acrobat i { color: #D3222A; }
.si-text-color.si-drupal i { color: #27537A; }
.si-text-color.si-buffer i { color: #333333; }
.si-text-color.si-pocket i { color: #EE4056; }
.si-text-color.si-bitbucket i { color: #0E4984; }
.si-text-color.si-stackoverflow i { color: #EF8236; }
.si-text-color.si-hackernews i { color: #FF6600; }
.si-text-color.si-xing i { color: #126567; }
.si-text-color.si-instagram i { color: #8A3AB9; }
.si-text-color.si-quora i { color: #A82400; }
.si-text-color.si-openid i { color: #E16309; }
.si-text-color.si-steam i { color: #111; }
.si-text-color.si-amazon i { color: #E47911; }
.si-text-color.si-disqus i { color: #E4E7EE; }
.si-text-color.si-plancast i { color: #222; }
.si-text-color.si-appstore i { color: #000; }
.si-text-color.si-pinterest i { color: #C8232C; }
.si-text-color.si-fivehundredpx i { color: #111; }
.si-text-color.si-tiktok i { color: #000; }
.si-text-color.si-whatsapp i { color: #25D366; }
.si-text-color.si-tripadvisor i { color: #00AF87; }
.si-text-color.si-gpay i { color: #6877DF; }
.si-text-color.si-unsplash i { color: #111; }
.si-text-color.si-snapchat i { color: #FFFC00; }

.si-text-color:hover { color: #FFF; }


/* ----------------------------------------------------------------
	Toggle
-----------------------------------------------------------------*/


.toggle {
	display: block;
	position: relative;
	margin: 0 0 20px 0;
}

.toggle-header,
.accordion-header {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 -5px;
	cursor: pointer;
	color: #444;
	font-size: 1rem;
	font-weight: 700;
}

.toggle-icon,
.accordion-icon {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
	padding: 0 5px;
	text-align: center;
}

.toggle-icon i,
.toggle-icon span {
	width: 1rem;
}

.toggle-title,
.accordion-title {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
	padding: 0 5px;
}

.toggle:not(.toggle-active) > .toggle-header .toggle-open,
.toggle-active > .toggle-header .toggle-closed {
	display: none;
}

.toggle-active > .toggle-header .toggle-open {
	display: inline-block;
}

.toggle-content,
.accordion-content {
	display: none;
	position: relative;
	padding: 0.75rem 0;
}




/* Toggle - with Title Background
-----------------------------------------------------------------*/

.toggle-bg .toggle-header {
	background-color: #EEE;
	margin: 0;
	padding: 0.75rem;
	border-radius: 2px;
}

.toggle-bg .toggle-content {
	padding: 1rem 0.75rem;
}


/* Toggle - Bordered
-----------------------------------------------------------------*/

.toggle-border {
	border: 1px solid #CCC;
	border-radius: 4px;
}

.toggle-border .toggle-header {
	padding: 0.75rem;
	margin: 0;
}

.toggle-border .toggle-content {
	padding: 1rem;
	padding-top: 0;
}


/* Toggle - FAQs
-----------------------------------------------------------------*/

.faqs .toggle {
	border-bottom: 1px solid #EEE;
	padding-bottom: 12px;
	margin-bottom: 12px;
}

.faqs .toggle-content { padding-bottom: 10px; }


/* ----------------------------------------------------------------
	Accordions
-----------------------------------------------------------------*/

.accordion { margin-bottom: 20px; }

.accordion-header {
	padding: 0.75rem 0;
	border-top: 1px dotted #DDD;
	cursor: pointer;
}

.accordion:not([data-collapsible="true"]) .accordion-header.accordion-active { cursor: auto; }

.accordion-header:first-child { border-top: none; }

.accordion-header:not(.accordion-active) .accordion-open,
.accordion-active .accordion-closed { display: none; }

.accordion-active .accordion-open { display: inline-block; }

.accordion-content {
	padding-top: 0;
	padding-bottom: 1.5rem;
}


/* Accordion - with Title Background
-----------------------------------------------------------------*/

.accordion-bg .accordion-header {
	background-color: #EEE;
	padding: 0.75rem;
	border-radius: 2px;
	margin: 0;
	margin-bottom: 5px;
	border-top: 0;
}

.accordion-bg .accordion-content {
	padding: 0.75rem 0.75rem 1.5rem;
}


/* Accordion - Bordered
-----------------------------------------------------------------*/

.accordion-border {
	border: 1px solid #DDD;
	border-radius: 4px;
}

.accordion-border .accordion-header {
	border-color: #CCC;
	padding: 0.75rem;
	margin: 0;
}

.accordion-border .accordion-content {
	padding: 0 1.125rem 1.5rem;
}


/* Accordion - Large
-----------------------------------------------------------------*/

.accordion-lg .accordion-header {
	font-size: 1.25rem;
}


/* ----------------------------------------------------------------
	Tabs
-----------------------------------------------------------------*/


.tabs {
	position: relative;
	margin: 0 0 30px 0;
}

.tab-nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0;
	border-bottom: 1px solid #DDD;
	list-style: none;
	padding: 0 15px;
}

.tab-nav li {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
	border: 1px solid #DDD;
	border-bottom: 0;
	border-left: 0;
	height: 41px;
	text-align: center;
}

.tabs-justify .tab-nav li {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.tab-nav li:first-child {
	border-left: 1px solid #DDD;
}

.tab-nav li a {
	display: block;
	padding: 0 15px;
	color: #444;
	height: 40px;
	line-height: 40px;
	background-color: #F2F2F2;
	font-weight: bold;
}

@media (max-width: 767.98px) {
	.tab-nav li a {
		font-size: 0.875rem;
	}
}

.tab-nav li.ui-tabs-active a {
	position: relative;
	top: 1px;
	background-color: #FFF;
}


/* Tabs - Alternate Nav
-----------------------------------------------------------------*/

.tabs-alt .tab-nav li { border-color: transparent; }

.tabs-alt .tab-nav li:first-child { border-left: 0; }

.tabs-alt .tab-nav li a { background-color: #FFF; }

.tabs-alt .tab-nav li.ui-tabs-active a {
	border: 1px solid #DDD;
	border-bottom: 0;
}


/* Tabs - Top Border
-----------------------------------------------------------------*/

.tabs-tb .tab-nav li.ui-tabs-active a {
	top: -1px;
	height: 43px;
	line-height: 38px;
	border-top: 2px solid #1ABC9C;
}

.tabs-alt.tabs-tb .tab-nav li.ui-tabs-active a { height: 42px; }


/* Tabs - Bottom Border
-----------------------------------------------------------------*/

.tabs-bb .tab-nav {
	padding: 0;
}

.tabs-bb .tab-nav li {
	border: 0 !important;
	margin: 0;
}

.tabs-bb .tab-nav li a {
	background-color: transparent;
	border-bottom: 2px solid transparent;
}

.tabs-bb .tab-nav li.ui-tabs-active a {
	border-bottom: 2px solid #1ABC9C;
	top: -2px;
	height: 44px;
	line-height: 44px;
}


/* Tabs - Navigation Style 2
-----------------------------------------------------------------*/


.tab-nav.tab-nav2 {
	border-bottom: 0;
	padding: 0;
}

.tab-nav.tab-nav2 li {
	border: 0;
	margin-left: 10px;
}

.tab-nav.tab-nav2 li:first-child {
	margin-left: 0;
	border-left: 0;
}

.tab-nav.tab-nav2 li a {
	background-color: #F5F5F5;
	border-radius: 2px;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.tab-nav.tab-nav2 li.ui-state-active a {
	top: 0;
	background-color: #1ABC9C;
	color: #FFF;
}


/* Tabs - Large Navigation
-----------------------------------------------------------------*/


.tab-nav-lg {
	margin-bottom: 20px;
	list-style: none;
}

.tab-nav-lg li {
	position: relative;
	text-align: center;
	padding: 1rem;
}

.tab-nav-lg li a {
	display: inline-block;
	color: #444;
	font-family: 'Poppins', sans-serif;
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.tab-nav.tab-nav-lg li i {
	display: block;
	font-size: 28px;
	margin: 0 0 7px;
}

.tab-nav-lg li.ui-tabs-active a {
	color: #1ABC9C;
}



.tab-nav-lg.tab-nav-section li.ui-tabs-active::before,
.tab-nav-lg.tab-nav-section li.ui-tabs-active::after {
	content: '';
	position: absolute;
	z-index: 2;
	top: auto;
	bottom: -50px;
	border: solid transparent;
}

.tab-nav-lg.tab-nav-section li.ui-tabs-active::after {
	border-top-color: #FFF;
	border-width: 16px;
	left: 50%;
	margin-left: -16px;
}

.tab-nav-lg.tab-nav-section li.ui-tabs-active::before {
	border-top-color: #FFF;
	border-width: 16px;
	left: 50%;
	margin-left: -16px;
}


/* Tabs - Content Area
-----------------------------------------------------------------*/

.tab-container {
	position: relative;
	padding: 20px 0 0;
}

.tabs .tab-content:not(:first-child) {
	display: none;
}

.tab-nav i {
	position: relative;
	top: 1px;
	margin-right: 3px;
}

/* Tabs - Bordered
-----------------------------------------------------------------*/

.tabs-bordered .tab-nav {
	padding: 0;
}

.tabs-bordered .tab-nav li:first-child { margin-left: 0; }

.tabs-bordered .tab-container {
	border: 1px solid #DDD;
	border-top: 0;
	padding: 20px;
}


/* Tabs - Side Tabs
-----------------------------------------------------------------*/

@media (min-width: 768px) {

	.side-tabs {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.side-tabs .tab-nav {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		flex-flow: column;
		min-width: 200px;
		width: auto;
		max-width: 100%;
		padding: 20px 0;
		border-bottom: 0;
		border-right: 1px solid #DDD;
	}

	.side-tabs .tab-nav li {
		border: 1px solid #DDD;
		border-right: 0;
		border-top: 0;
		height: auto;
		text-align: left;
	}

	.side-tabs .tab-nav li:first-child {
		margin-left: 0;
		border-top: 1px solid #DDD;
		border-left: 1px solid #DDD;
	}

	.side-tabs .tab-nav li a {
		height: auto;
		line-height: 44px;
	}

	.side-tabs .tab-nav li.ui-tabs-active a {
		top: 0;
		right: -1px;
	}

	.side-tabs .tab-container {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding: 15px 0 0 20px;
	}

	.ui-tabs .ui-tabs-hide {
		position: absolute !important;
		left: -10000px !important;
		display: block !important;
	}


	/* Tabs - Side Tabs & Nav Style 2
	-----------------------------------------------------------------*/

	.side-tabs .tab-nav.tab-nav2 { border: 0; }

	.side-tabs .tab-nav.tab-nav2 li {
		border: 0;
		margin: 6px 0 0;
	}

	.side-tabs .tab-nav.tab-nav2 li:first-child { margin-top: 0; }

	.side-tabs .tab-nav.tab-nav2 li a { box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }

	.side-tabs .tab-nav.tab-nav2 li.ui-state-active a { right: 0; }

	/* Tabs - Side Tabs & Bordered
	-----------------------------------------------------------------*/

	.tabs-bordered.side-tabs .tab-nav {
		position: relative;
		padding: 0;
		z-index: 2;
		margin-right: -1px;
	}

	.tabs-bordered.side-tabs .tab-nav li a {
		height: auto;
		line-height: 44px;
	}

	.tabs-bordered.side-tabs .tab-nav li.ui-tabs-active a {
		right: -1px;
		border-right: 1px solid #FFF;
	}

	.tabs-bordered.side-tabs .tab-container {
		overflow: hidden;
		padding: 20px;
		border-top: 1px solid #DDD;
	}

}


/* ----------------------------------------------------------------
	Side Navigation
-----------------------------------------------------------------*/


.sidenav {
	width: 100%;
	padding: 0;
	background-color: #FFF;
	border-radius: 4px;
}

.sidenav li { list-style-type: none; }

.sidenav li a {
	display: block;
	position: relative;
	margin: 0 0 -1px;
	padding: 11px 20px 11px 14px;
	border: 1px solid #E5E5E5;
	border-right-width: 2px;
	color: #666;
}

.sidenav > li:first-child > a { border-radius: 4px 4px 0 0; }

.sidenav > li:last-child > a {
	border-radius: 0 0 4px 4px;
	border-bottom-width: 2px;
}

.sidenav > .ui-tabs-active > a,
.sidenav > .ui-tabs-active > a:hover {
	position: relative;
	z-index: 2;
	border-color: rgba(0,0,0,0.1) !important;
	color: #FFF !important;
	font-weight: 700;
	background-color: #1ABC9C !important;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.sidenav li ul { display: none; }

.sidenav li.ui-tabs-active ul { display: block; }

.sidenav li li a {
	padding: 6px 20px 6px 35px;
	font-size: 0.875rem;
	border-top: none;
	border-bottom: none;
}

.sidenav li li:first-child a { padding-top: 20px; }

.sidenav li li:last-child a { padding-bottom: 20px; }

.sidenav li i {
	position: relative;
	top: 1px;
	margin-right: 6px;
	font-size: 0.875rem;
	text-align: center;
	width: 16px;
}

/* Chevrons */
.sidenav .icon-chevron-right {
	position: absolute;
	width: 14px;
	height: 14px;
	top: 50%;
	left: auto;
	right: 6px;
	margin-top: -8px;
	margin-right: 0;
	opacity: .4;
	font-size: 12px !important;
}

.sidenav > li > a:hover {
	background-color: #F5F5F5;
	color: #222;
}

.sidenav a:hover .icon-chevron-right { opacity: .5; }

.sidenav .ui-tabs-active .icon-chevron-right,
.sidenav .ui-tabs-active a:hover .icon-chevron-right { opacity: 1; }


/* ----------------------------------------------------------------
	FAQ List
-----------------------------------------------------------------*/


.faqlist li {
	margin-bottom: 5px;
	font-size: 15px;
}

.faqlist li a { color: #444; }

.faqlist li a:hover { color: #1ABC9C !important; }


/* ----------------------------------------------------------------
	Clients
-----------------------------------------------------------------*/


.clients-grid,
.testimonials-grid {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	list-style: none;
	overflow: hidden;
}

.clients-grid .grid-item,
.testimonials-grid .grid-item {
	position: relative;
	padding: 20px 0;
	-webkit-backface-visibility: hidden;
}

.clients-grid .grid-item a { opacity: 0.6; }

body:not(.device-touch) .clients-grid .grid-item a {
	-webkit-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
	backface-visibility: hidden;
}

.clients-grid .grid-item a:hover { opacity: 1; }

.clients-grid .grid-item a,
.clients-grid .grid-item img {
	display: block;
	width: 75%;
	margin-left: auto;
	margin-right: auto;
}

.clients-grid .grid-item a img { width: 100%; }

.clients-grid .grid-item::before,
.clients-grid .grid-item::after,
.testimonials-grid .grid-item::before,
.testimonials-grid .grid-item::after {
	content: '';
	position: absolute;
}

.clients-grid .grid-item::before,
.testimonials-grid .grid-item::before {
	height: 100%;
	top: 0;
	left: -1px;
	border-left: 1px dashed #DDD;
}

.clients-grid .grid-item::after,
.testimonials-grid .grid-item::after {
	width: 100%;
	height: 0;
	top: auto;
	left: 0;
	bottom: -1px;
	border-bottom: 1px dashed #DDD;
}


/* ----------------------------------------------------------------
	Testimonials
-----------------------------------------------------------------*/

/* Testimonials - Grid
-----------------------------------------------------------------*/

.testimonials-grid .grid-item {
	padding: 2rem;
}

.testimonials-grid .grid-item .testimonial {
	padding: 0;
	background-color: transparent !important;
	border: 0 !important;
	box-shadow: none !important;
}


/* Testimonials - Item
-----------------------------------------------------------------*/

.testimonial {
	position: relative;
	padding: 20px;
	background-color: #FFF;
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 5px;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.testi-image {
	float: left;
	margin-right: 15px;
}

.testi-image,
.testi-image a,
.testi-image img,
.testi-image i {
	display: block;
	width: 64px;
	height: 64px;
}

.testi-image i {
	text-align: center;
	background-color: #EEE;
	border-radius: 50%;
	line-height: 64px;
	font-size: 28px;
	color: #888;
	text-shadow: 1px 1px 1px #FFF;
}

.testi-image img { border-radius: 50%; }

.testi-content {
	position: relative;
	overflow: hidden;
}

.testi-content p {
	margin-bottom: 0;
	font-family: 'PT Serif', serif;
	font-style: italic;
}

.testi-content p::before,
.testi-content p::after { content: '"'; }

.testi-meta {
	margin-top: 10px;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
}

.testi-meta::before { content: '\2013'; }

.testi-meta span {
	display: block;
	font-weight: normal;
	color: #999;
	font-size: 13px;
	text-transform: none;
	padding-left: 10px;
}


/* Twitter - Small Scroller
-----------------------------------------------------------------*/

.testimonial.twitter-scroll .testi-content p::before,
.testimonial.twitter-scroll .testi-content p::after,
.testimonial.twitter-scroll .testi-meta::before { content: ''; }

.testimonial.twitter-scroll .testi-meta span { padding-left: 0; }

.testimonial.twitter-scroll .testi-meta span a { color: #999; }

.testimonial.twitter-scroll .testi-meta span a:hover { color: #222; }


/* Testimonials - Full Width
-----------------------------------------------------------------*/

.testimonial.testimonial-full { padding: 30px; }

.testimonial.testimonial-full[data-animation="fade"] { padding: 30px; }

.testimonial.testimonial-full[data-animation="fade"] .flexslider { overflow: visible !important; }

.testimonial-full .testi-image {
	float: none;
	margin: 0 auto 20px;
}

.testimonial-full .testi-image,
.testimonial-full .testi-image a,
.testimonial-full .testi-image img,
.testimonial-full .testi-image i {
	display: block;
	width: 72px;
	height: 72px;
}

.testimonial-full .testi-image i { line-height: 72px; }

.testimonial-full .testi-content {
	text-align: center;
	font-size: 18px;
}

.testimonial-full .testi-meta { margin-top: 15px; }

.testimonial-full .testi-meta span { padding-left: 0; }


/* Testimonial - Section Scroller
-----------------------------------------------------------------*/

.section > .testimonial {
	padding: 0;
	background-color: transparent !important;
	border: 0 !important;
	box-shadow: none !important;
	max-width: 800px;
	margin: 0 auto;
}

.section > .testimonial[data-animation="fade"] { padding: 0; }

.section > .testimonial .testi-content { font-size: 1.25rem; }

.section > .testimonial .testi-meta,
.section > .testimonial .testi-meta span { font-size: 0.875rem; }


/* Testimonial - Pagination
-----------------------------------------------------------------*/

.testimonial .flex-control-nav {
	top: auto;
	bottom: 6px;
	right: 0;
}

.testimonial .flex-control-nav li {
	margin: 0 2px;
	width: 6px;
	height: 6px;
}

.testimonial .flex-control-nav li a {
	width: 6px !important;
	height: 6px !important;
	border: none;
	background-color: #1ABC9C;
	opacity: 0.5;
}

.testimonial .flex-control-nav li:hover a { opacity: 0.75; }
.testimonial .flex-control-nav li a.flex-active { opacity: 1; }


/* Testimonial - Full Scroller
-----------------------------------------------------------------*/

.testimonial.testimonial-full .flex-control-nav {
	position: relative;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 6px;
	margin-top: 20px;
}

.testimonial.testimonial-full .flex-control-nav li {
	display: inline-block;
	float: none;
}


/* ----------------------------------------------------------------
	Team
-----------------------------------------------------------------*/


.team,
.team-image { position: relative; }

.team-image > a,
.team-image img {
	display: block;
	width: 100%;
}

.team-desc { text-align: center; }

.team-desc.team-desc-bg {
	background-color: #FFF;
	padding: 10px 0 30px;
}

.team .si-share {
	margin-top: 30px;
	text-align: left;
}

.team .si-share .social-icon {
	font-size: 0.875rem;
	width: 34px;
}

.team-title {
	position: relative;
	padding-top: 20px;
}

.team-title h4 {
	display: block;
	margin-bottom: 0;
	font-size: 1.25rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
}

.team-title span {
	display: block;
	color: #1ABC9C;
	font-weight: 400;
	font-family: 'PT Serif', serif;
	font-style: italic;
	font-size: 1rem;
	margin-top: 5px;
}

.team-content {
	margin-top: 1rem;
	color: #777;
}

.team-content p:last-child { margin-bottom: 0; }


/* Team Center
-----------------------------------------------------------------*/

.team.center .team-title::after {
	left: 50%;
	margin-left: -40px;
}


/* Team List
-----------------------------------------------------------------*/

.team-list .team-desc {
	text-align: left;
}

.team-list .team-title { padding-top: 0; }

.team .team-desc > .social-icon { margin-top: 20px; }

.team .team-content ~ .social-icon { margin-top: 30px; }

.team:not(.team-list) .social-icon {
	display: inline-block !important;
	vertical-align: middle;
	float: none;
	margin-left: 4px;
	margin-right: 4px;
}


/* ----------------------------------------------------------------
	Pricing Boxes
-----------------------------------------------------------------*/

.pricing { position: relative; }

.pricing [class^=col-] {
	margin-top: 1.25rem;
}


/* Pricing Boxes
-----------------------------------------------------------------*/

.pricing-box {
	position: relative;
	overflow: hidden;
	border: 1px solid rgba(0,0,0,0.075);
	border-radius: 3px;
	background-color: #FFF;
}


/* Pricing Boxes - Title
-----------------------------------------------------------------*/

.pricing-title {
	padding: 1rem 0;
	background-color: #F9F9F9;
	border-bottom: 1px solid rgba(0,0,0,0.075);
	letter-spacing: 1px;
}

.pricing-title h3 {
	margin: 0;
	font-size: 1.5rem;
	font-weight: bold;
	color: #555;
}

.pricing-title span {
	display: block;
	color: #777;
	font-size: 1rem;
	margin: 3px 0;
}

.pricing-title.title-sm h3 {
	font-size: 1.25rem;
}

.pricing-title.title-sm span {
	font-size: 0.875rem;
}


/* Pricing Boxes - Price
-----------------------------------------------------------------*/

.pricing-price {
	position: relative;
	padding: 1.5rem 0;
	color: #333;
	font-size: 4rem;
	line-height: 1;
}

.pricing-price .price-unit {
	display: inline-block;
	vertical-align: top;
	margin: 7px 3px 0 0;
	font-size: 28px;
	font-weight: normal;
}

.pricing-price .price-tenure {
	vertical-align: baseline;
	font-size: 1rem;
	font-weight: normal;
	letter-spacing: 1px;
	color: #999;
	margin: 0 0 0 3px;
}


/* Pricing Boxes - Features
-----------------------------------------------------------------*/

.pricing-features {
	border-top: 1px solid rgba(0,0,0,0.075);
	border-bottom: 1px solid rgba(0,0,0,0.075);
	background-color: #F9F9F9;
	padding: 2rem 0;
}

.pricing-features ul {
	margin: 0;
	list-style: none;
}

.pricing-features li {
	padding: 0.375rem 0;
}


/* Pricing Boxes - Action
-----------------------------------------------------------------*/

.pricing-action { padding: 1.25rem 0; }


/* Pricing Boxes - Best Price
-----------------------------------------------------------------*/

.pricing-highlight {
	box-shadow: 0 0 8px rgba(0,0,0,0.1);
	z-index: 10;
}

.pricing-highlight { margin: -20px -1px; }

.pricing-highlight .pricing-title {
	padding: 20px 0;
}

.pricing-highlight .pricing-title,
.pricing-highlight .pricing-features {
	background-color: #fff;
}

.pricing-highlight .pricing-price {
	font-size: 72px;
}


/* Pricing Boxes - Simple
-----------------------------------------------------------------*/

.pricing-simple,
.pricing-simple .pricing-title,
.pricing-simple .pricing-features {
	border: 0;
}

.pricing-simple .pricing-title,
.pricing-simple .pricing-features {
	background-color: transparent;
}


/* Pricing Boxes - Minimal
-----------------------------------------------------------------*/

.pricing-minimal .pricing-price {
	background-color: #FFF;
	border-bottom: 1px solid rgba(0,0,0,0.075);
}

.pricing-minimal .pricing-price::after { display: none; }


/* Pricing Boxes - Extended
-----------------------------------------------------------------*/

.pricing-extended {
	background-color: #FFF;
	text-align: left;
}

.pricing-extended .pricing-title {
	background-color: transparent;
	padding-top: 0;
}

.pricing-extended .pricing-features {
	border: 0;
	background-color: transparent;
}

.pricing-extended .pricing-action-area {
	border-left: 1px solid rgba(0,0,0,0.05);
	background-color: #F9F9F9;
	padding: 30px;
	text-align: center;
}

.pricing-extended .pricing-action-area .pricing-price,
.pricing-extended .pricing-action-area .pricing-action { padding: 0; }

.pricing-extended .pricing-action-area .pricing-price { padding-bottom: 20px; }

.pricing-extended .pricing-action-area .pricing-price::after { display: none; }

.pricing-extended .pricing-action-area .pricing-price span.price-tenure {
	display: block;
	margin: 10px 0 0 0;
	font-weight: 300;
	text-transform: uppercase;
	font-size: 0.875rem;
}

.pricing-extended .pricing-meta {
	color: #999;
	font-weight: 300;
	font-size: 0.875rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-bottom: 10px;
}

.table-comparison,
.table-comparison th { text-align: center; }

.table-comparison th:first-child,
.table-comparison td:first-child {
	text-align: left;
	font-weight: bold;
}


/* ----------------------------------------------------------------
	Counter
-----------------------------------------------------------------*/


.counter {
	font-size: 42px;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
}

.counter.counter-small { font-size: 28px; }

.counter.counter-large { font-size: 56px; }

.counter.counter-xlarge {
	font-size: 64px;
	font-weight: 400;
}

.counter.counter-inherit {
	font-size: inherit;
	font-weight: inherit;
}

.counter + h5 {
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.counter.counter-lined + h5::before {
	display: block;
	position: relative;
	margin: 20px auto 25px auto;
	content: '';
	width: 15%;
	border-top: 2px solid #444;
	opacity: 0.9;
}

.counter.counter-small + h5 { font-size: 0.875rem; }

.counter.counter-large + h5,
.counter.counter-xlarge + h5 {
	font-size: 15px;
	font-weight: 300;
}

.widget .counter + h5 { opacity: 0.7; }


/* ----------------------------------------------------------------
	Animated Rounded Skills
-----------------------------------------------------------------*/


.rounded-skill {
	display: inline-block;
	margin: 0 15px 15px;
	display: inline-block;
	position: relative;
	text-align: center;
	font-size: 1.25rem;
	font-weight: bold;
	color: #333;
}

body:not(.device-touch) .rounded-skill {
	-webkit-transition: opacity .4s ease;
	-o-transition: opacity .4s ease;
	transition: opacity .4s ease;
}

.rounded-skill + h5 { margin-bottom: 0; }

.rounded-skill i { font-size: 42px; }

.rounded-skill canvas {
	position: absolute;
	top: 0;
	left: 0;
}


/* ----------------------------------------------------------------
	Skills Bar
-----------------------------------------------------------------*/


.skills {
	margin: 0;
	list-style: none;
	padding-top: 25px;
}

.skills li {
	position: relative;
	margin-top: 38px;
	height: 4px;
	background-color: #F5F5F5;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
	border-radius: 2px;
}

.skills li:first-child { margin-top: 0; }

.skills li .progress {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	background-color: #1ABC9C;
	overflow: visible !important;
	border-radius: 2px;
	-webkit-transition: width 1.2s ease-in-out;
	-o-transition: width 1.2s ease-in-out;
	transition: width 1.2s ease-in-out;
}

.skills li .progress-percent {
	position: absolute;
	top: -28px;
	right: 0;
	background-color: #333;
	color: #F9F9F9;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	height: 22px;
	line-height: 22px;
	padding: 0px 5px;
	font-size: 11px;
	border-radius: 2px;
}

.skills li .progress-percent .counter {
	font-weight: 400;
	font-family: 'Lato', sans-serif;
}

.progress-percent::after, .progress-percent::before {
	top: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.progress-percent::after {
	border-top-color: #333;
	border-width: 4px;
	left: 50%;
	margin-left: -4px;
}

.progress-percent::before {
	border-top-color: #333;
	border-width: 5px;
	left: 50%;
	margin-left: -5px;
}

.skills li > span {
	position: absolute;
	z-index: 1;
	top: -23px;
	left: 0;
	line-height: 20px;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	color: #222;
}

/* ----------------------------------------------------------------
	Quotes & Blockquotes
-----------------------------------------------------------------*/

blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 1.125rem;
	border-left: 5px solid #EEE;
}

.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #EEE;
	border-left: 0;
	text-align: right;
}

blockquote.float-start {
	max-width: 300px;
	margin: 5px 20px 10px 0;
	padding-right: 0;
}

blockquote.float-end {
	max-width: 300px;
	margin: 5px 0 10px 20px;
	padding-left: 0;
}

.quote {
	border: none !important;
	position: relative;
}

.quote p { position: relative; }

.quote::before {
	font-family: 'font-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	content: "\e7ad";
	position: absolute;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 42px;
	top: 0;
	left: 0;
	color: #EEE;
}

.quote.blockquote-reverse::before {
	content: "\e7ae";
	left: auto;
	right: 0;
}


/* ----------------------------------------------------------------
	Dropcaps & Highlights
-----------------------------------------------------------------*/


.dropcap {
	float: left;
	font-size: 42px;
	line-height: 1;
	margin: 0 5px 0 0;
	text-transform: uppercase;
}

.highlight {
	padding: 2px 5px;
	background-color: #444;
	color: #FFF;
	border-radius: 2px;
}

/* ----------------------------------------------------------------
	Text Rotater
-----------------------------------------------------------------*/

.text-rotater .t-rotate {
	display: none;
}

.text-rotater .t-rotate.morphext,
.text-rotater .t-rotate.plugin-typed-init {
	display: inherit;
}

.text-rotater .typed-cursor {
	font-weight: 400;
}

.text-rotater > .t-rotate > .animated {
	display: inline-block;
}


/* ----------------------------------------------------------------
	Owl Carousel CSS
-----------------------------------------------------------------*/

.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in { z-index: 0; }

.owl-carousel .owl-animated-out { z-index: 1; }

.owl-carousel .fadeOut  {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes fadeOut {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

.owl-height {
	-webkit-transition:height 500ms ease-in-out;
	-o-transition:height 500ms ease-in-out;
	transition:height 500ms ease-in-out
}

.owl-carousel {
	display:none;
	-webkit-tap-highlight-color:transparent;
	position:relative;
	z-index:1;
	width: 100%;
	touch-action: manipulation;
}

.owl-carousel .owl-stage {
	position:relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage::after {
	content:".";
	display:block;
	clear:both;
	visibility:hidden;
	line-height:0;
	height:0
}

.owl-carousel .owl-stage-outer {
	position:relative;
	overflow:hidden;
	-webkit-transform:translate3d(0,0,0);
}

.owl-carousel.owl-loaded { display:block }

.owl-carousel.owl-loading {
	display:block;
	min-height: 100px;
	background: no-repeat center center;
}

.owl-carousel .owl-refresh .owl-item { display:none }

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
	display:block;
	width:100%;
	-webkit-transform-style:preserve-3d;
}

.slider-element .owl-carousel .owl-item img { -webkit-transform-style: preserve-3d; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled { display: none; }

.owl-nav .owl-prev,
.owl-nav .owl-next,
.owl-dot,
.owl-dots button {
	cursor: pointer;
	cursor: hand;
	padding: 0;
	border: 0;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-loaded { display: block; }

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden { opacity: 0; }

.mega-menu-content .owl-carousel.owl-hidden { opacity: 1; }

.owl-carousel.owl-refresh .owl-item { display: none; }

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl { direction: rtl; }

.owl-carousel.owl-rtl .owl-item { float: right; }

.no-js .owl-carousel { display: block; }

.owl-carousel .owl-item .owl-lazy {
	opacity:0;
	-webkit-transition:opacity 400ms ease;
	-o-transition:opacity 400ms ease;
	transition:opacity 400ms ease
}

.owl-carousel .owl-item img { transform-style:preserve-3d }

.owl-carousel .owl-video-wrapper {
	position:relative;
	height:100%;
	background:#111
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 64px;
	width: 64px;
	left: 50%;
	top: 50%;
	margin-left: -32px;
	margin-top: -32px;
	background: url("../assets/html-assets/icons/play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transition: scale 100ms ease;
	-o-transition: scale 100ms ease;
	transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transition:scale(1.3,1.3);
	-o-transition:scale(1.3,1.3);
	transition:scale(1.3,1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn { display:none }

.owl-carousel .owl-video-tn {
	opacity:0;
	height:100%;
	background-position:center center;
	background-repeat:no-repeat;
	-webkit-background-size:contain;
	-moz-background-size:contain;
	-o-background-size:contain;
	background-size:contain;
	-webkit-transition:opacity 400ms ease;
	-o-transition:opacity 400ms ease;
	transition:opacity 400ms ease
}

.owl-carousel .owl-video-frame {
	position:relative;
	z-index:1;
	height: 100%;
	width: 100%;
}


/* Owl Carousel - Controls
-----------------------------------------------------------------*/

.owl-carousel .owl-dots,
.owl-carousel .owl-nav {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	line-height: 1;
}

/* Owl Carousel - Controls - Arrows
-----------------------------------------------------------------*/

.owl-carousel .owl-nav [class*=owl-] {
	position: absolute;
	top: 50%;
	margin-top: -18px;
	left: -36px;
	zoom: 1;
	width: 36px;
	height: 36px;
	line-height: 32px;
	border: 1px solid rgba(0,0,0,0.2);
	color: #666;
	background-color: #FFF;
	font-size: 18px;
	border-radius: 50%;
	opacity: 0;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.owl-carousel.with-carousel-dots .owl-nav [class*=owl-] { margin-top: -38px; }

.slider-element .owl-nav [class*=owl-],
.owl-carousel-full .owl-nav [class*=owl-] {
	margin-top: -30px;
	left: 0 !important;
	height: 60px;
	line-height: 60px;
	border: none;
	color: #EEE;
	background-color: rgba(0,0,0,0.4);
	font-size: 28px;
	border-radius: 0 3px 3px 0;
}

.owl-carousel-full .with-carousel-dots .owl-nav [class*=owl-] { margin-top: -50px; }

.owl-carousel .owl-nav .owl-next {
	left: auto;
	right: -36px;
}

.slider-element .owl-nav .owl-next,
.owl-carousel-full  .owl-nav .owl-next {
	left: auto !important;
	right: 0 !important;
	border-radius: 3px 0 0 3px;
}

.owl-carousel:hover .owl-nav [class*=owl-] {
	opacity: 1;
	left: -18px;
}

.owl-carousel:hover .owl-nav .owl-next {
	left: auto;
	right: -18px;
}

.owl-carousel .owl-nav [class*=owl-]:hover {
	background-color: #1ABC9C !important;
	color: #FFF !important;
	text-decoration: none;
}

.owl-carousel .owl-nav .disabled { display: none !important; }


/* Owl Carousel - Controls - Dots
-----------------------------------------------------------------*/

.owl-carousel .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	width: 8px;
	height: 8px;
	margin: 30px 4px 0 4px;
	opacity: 0.5;
	border-radius: 50%;
	background-color: #1ABC9C;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.owl-carousel .owl-dots .owl-dot.active,
.owl-carousel .owl-dots .owl-dot:hover { opacity: 1; }


/* Owl Carousel - Controls - Dots - Positions
-----------------------------------------------------------------*/
.owl-carousel[class*=owl-nav-pos-],
.owl-carousel[class*=owl-dots-pos-]
.owl-carousel[class*=owl-img-pos-] {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}

.owl-carousel.owl-nav-pos-1 .owl-nav,
.owl-carousel.owl-dots-pos-1 .owl-dots,
.owl-carousel.owl-content-pos-1 .owl-stage-outer {
	order: 1;
}

.owl-carousel.owl-nav-pos-2 .owl-nav,
.owl-carousel.owl-dots-pos-2 .owl-dots,
.owl-carousel.owl-content-pos-2 .owl-stage-outer {
	order: 2;
	margin: 20px 0;
}

.owl-carousel.owl-nav-pos-3 .owl-nav,
.owl-carousel.owl-dots-pos-3 .owl-dots,
.owl-carousel.owl-content-pos-3 .owl-stage-outer {
	order: 3;
}

.owl-carousel.owl-dots-pos-2 .owl-dots .owl-dot,
.owl-carousel.owl-dots-pos-3 .owl-dots .owl-dot {
	margin-top: 0;
}

.owl-carousel.owl-nav-pos-left .owl-nav,
.owl-carousel.owl-nav-pos-right .owl-nav,
.owl-carousel.owl-dots-pos-left .owl-dots,
.owl-carousel.owl-dots-pos-right .owl-dots {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
}

.owl-carousel.owl-dots-pos-left .owl-dots,
.owl-carousel.owl-nav-pos-left .owl-nav {
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.owl-carousel.owl-dots-pos-right .owl-dots,
.owl-carousel.owl-nav-pos-right .owl-nav {
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.owl-carousel.with-carousel-dots.owl-dots-pos-top .owl-nav [class*=owl-] { margin-top: 0; }

/* Owl Carousel - Controls - Dots - Sizes
-----------------------------------------------------------------*/
.owl-carousel {
	--owl-nav-sizes: 36px;
	--owl-dots-gutters: 4px;
	--owl-dots-sizes: 8px;
	--owl-nav-bg: var(--themecolor, #1ABC9C);
	--owl-nav-margin: calc(var(--owl-nav-sizes) / -2);
	--owl-nav-with-dots: calc( var(--owl-nav-margin) - calc( calc( 30px + var(--owl-dots-sizes) ) / 2 ) );
}

.owl-carousel.owl-dots-size-sm {
	--owl-dots-sizes: 4px;
	--owl-dots-gutters: 2px;
}

.owl-carousel.owl-dots-size-lg {
	--owl-dots-sizes: 12px;
	--owl-dots-gutters: 5px;
}

.owl-carousel.owl-dots-size-xl {
	--owl-dots-sizes: 16px;
	--owl-dots-gutters: 6px;
}

.owl-carousel .owl-dots .owl-dot {
	width: var(--owl-dots-sizes);
	height: var(--owl-dots-sizes);
	background-color: var(--owl-nav-bg);
	margin-left: var(--owl-dots-gutters);
	margin-right: var(--owl-dots-gutters);
	transition: all .3s;
}

.owl-carousel.owl-nav-hover-fixed[class*=owl-nav-pos-] .owl-nav .disabled {
	display: inline-block !important;
	pointer-events: none;
	opacity: .4 !important;
}

/* Owl Carousel - Controls - Dots - Border
-----------------------------------------------------------------*/
.owl-carousel.owl-dots-border .owl-dots .owl-dot:not(.active) {
	opacity: 1;
	background-color: transparent;
	border: 1px solid var(--owl-nav-bg);
}

.owl-carousel.owl-dots-border .owl-dots .owl-dot.active {
	border-color: transparent;
}

/* Owl Carousel - Controls - Dots - square
-----------------------------------------------------------------*/
.owl-carousel.owl-dots-square .owl-dots .owl-dot {
	border-radius: 0;
	--owl-dots-sizes: 10px;
}

/* Owl Carousel - Controls - Dots - square
-----------------------------------------------------------------*/
.owl-carousel.owl-dots-rounded .owl-dots .owl-dot {
	--owl-dots-sizes: 10px;
	border-radius: 2px;
}

/* Owl Carousel - Controls - Dots - Dashed
-----------------------------------------------------------------*/
.owl-carousel.owl-dots-dashed .owl-dots .owl-dot {
	--owl-dots-sizes: 16px;
	opacity: .4;
	height: 4px;
	border-radius: 4px;
}

.owl-carousel.owl-dots-dashed .owl-dots .owl-dot.active {
	--owl-dots-sizes: 32px;
	opacity: 1;
}

/* Owl Carousel - Controls - Dots - Numbers
-----------------------------------------------------------------*/
.owl-carousel.owl-dots-number .owl-dots {
	--owl-dots-sizes: 28px;
	counter-reset: dots;
	--owl-nav-bg: #FFF;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot {
	position: relative;
	width: auto;
	opacity: .3;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot.active {
	opacity: 1;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot:before {
	display: inline-block;
	counter-increment: dots;
	content: counter(dots, decimal-leading-zero);
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot span {
	position: relative;
	display: inline-block;
	width: 0px;
	height: 2px;
	background-color: #666;
	top: -5px;
	margin-left: 5px;
	transition: width .4s ease-in-out;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot.active span {
	 width: 30px;
}

/* Owl Carousel - Controls - Arrows - Sizes
-----------------------------------------------------------------*/

.owl-carousel .owl-nav [class*=owl-] {
	left: calc(-1 * var(--owl-nav-sizes));
	width: var(--owl-nav-sizes);
	height: var(--owl-nav-sizes);
	line-height: calc(calc(-1 * var(--owl-nav-sizes)) - 4px);
	font-size: calc(var(--owl-nav-sizes) / 2);
	margin-top: var(--owl-nav-margin);
}

.owl-carousel.with-carousel-dots .owl-nav [class*=owl-] {
	margin-top: var(--owl-nav-with-dots);
}

.owl-carousel .owl-nav .owl-next {
	left: auto;
	right: calc(-1 * var(--owl-nav-sizes));
}

.slider-element .owl-nav .owl-next,
.owl-carousel-full  .owl-nav .owl-next {
	left: auto !important;
	right: 0 !important;
}

.owl-carousel.owl-nav-hover-fixed .owl-nav [class*=owl-],
.owl-carousel:hover .owl-nav [class*=owl-] {
	opacity: 1;
	left: calc(calc(-1 * var(--owl-nav-sizes)) / 2);
}

.owl-carousel.owl-nav-hover-fixed .owl-nav .owl-next,
.owl-carousel:hover .owl-nav .owl-next {
	left: auto;
	right: calc(calc(-1 * var(--owl-nav-sizes)) / 2);
}

.owl-carousel .owl-nav [class*=owl-]:hover {
	background-color: var(--owl-nav-bg) !important;
}

.slider-element .owl-nav [class*=owl-],
.owl-carousel-full .owl-nav [class*=owl-] {
	margin-top: -30px;
	height: calc(var(--owl-nav-sizes) * 1.6666666667);
	line-height: calc(var(--owl-nav-sizes) * 1.6666666667);
	font-size: calc(calc(var(--owl-nav-sizes) * 1.6666666667) / 2);
}

.owl-carousel-full .with-carousel-dots .owl-nav [class*=owl-] {
	margin-top: calc( calc(calc(var(--owl-nav-sizes) * 1.6666666667) / -2)) - calc( calc( 30px + var(--owl-dots-sizes) ) / 2 );
}

.owl-carousel.owl-nav-hover-fixed[class*=owl-nav-pos-] .owl-nav [class*=owl-] {
	position: relative;
	top: auto;
	left: auto;
	right: auto;
	margin: 0;
}

.owl-carousel.owl-nav-hover-fixed[class*=owl-nav-pos-] .owl-nav .owl-prev {
	margin-right: 5px;
}

.owl-carousel.owl-nav-hover-fixed[class*=owl-nav-pos-] .owl-nav .owl-next {
	margin-left: 5px;
}

.owl-carousel.owl-nav-text .owl-nav [class*=owl-] {
	width: auto;
	height: auto;
	line-height: 1.5;
	font-size: 0.925rem;
	border-radius: 3px;
	padding: 4px 8px;
}

.owl-carousel.owl-nav-text .owl-nav [class*=owl-] i {
	position: relative;
	top: 1px;
}



/* ----------------------------------------------------------------
	Flip Cards
-----------------------------------------------------------------*/

.flip-card {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.flip-card-front,
.flip-card-back {
	background-size: cover;
	background-position: center;
	-webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
	-webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 100%;
	height: 280px;
	border-radius: 6px;
	color: #FFF;
	font-size: 1.5rem;
}

.flip-card-back { background-color: #666; }

.flip-card:hover .flip-card-front,
.flip-card:hover .flip-card-back {
	-webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
	-webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.flip-card-front.no-after::after,
.flip-card-back.no-after::after { content: none }

.flip-card-front::after,
.flip-card-back::after {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	content: "";
	display: block;
	opacity: 0.6;
	background-color: #000;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 6px;
}

.flip-card-back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.flip-card-inner {
	-webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
	transform: translateY(-50%) translateZ(60px) scale(0.94);
	top: 50%;
	position: absolute;
	left: 0;
	width: 100%;
	padding: 2rem;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline: 1px solid transparent;
	-webkit-perspective: inherit;
	perspective: inherit;
	z-index: 2;
}

.flip-card-back {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-card-front,
.flip-card:hover .flip-card-back {
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-card:hover .flip-card-front {
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.top-to-bottom .flip-card-back {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.top-to-bottom .flip-card-front,
.top-to-bottom:hover .flip-card-back {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.top-to-bottom:hover .flip-card-front {
	-webkit-transform: rotateX(-180deg);
	transform: rotateX(-180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-card-inner span {
	font-size: 18px;
	line-height: 20px;
	font-weight: 300;
}

.flip-card-inner p {
	position: relative;
	font-size: 1rem;
	margin-bottom: 0;
	color: rgba(255, 255, 255, 0.7);
}

/*.owl-carousel .owl-stage { padding: 20px 0; }*/


/* ----------------------------------------------------------------
	Overlays
-----------------------------------------------------------------*/


.bg-overlay,
.bg-overlay-bg,
.bg-overlay-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
}

.bg-overlay [data-hover-animate]:not(.animated) {
	opacity: 0;
}

.bg-overlay {
	z-index: 5;
}

.bg-overlay-content {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	padding: 20px;
}

.bg-overlay-bg {
	background-color: rgba(255,255,255,0.85);
}

.dark .bg-overlay-bg,
.bg-overlay-bg.dark {
	background-color: rgba(0,0,0,0.5);
}


.text-overlay-mask {
	opacity: 0.85;
	top: auto;
	bottom: 0;
	height: auto;
	padding: 40px 15px 15px;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.85))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* IE10+ */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.overlay-trigger-icon {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin: 0 0.25rem;
	font-size: 1.125rem;
	text-align: center;
	border-radius: 50%;
	-webkit-backface-visibility: hidden;
	transition: all .3s ease;
}

.overlay-trigger-icon .icon-line-play {
	position: relative;
	left: 2px;
}

.overlay-trigger-icon.size-sm {
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin: 0 0.5rem;
	font-size: 0.875rem;
}

.overlay-trigger-icon.size-lg {
	width: 64px;
	height: 64px;
	line-height: 64px;
	margin: 0 0.5rem;
	font-size: 1.5rem;
}


/* News Carousel Overlays
-----------------------------------------------------------------*/

.news-carousel .rounded-skill {
	position: absolute;
	margin: 0 !important;
	top: 30px;
	right: 30px;
	color: #FFF;
	font-weight: 700;
}


/* ----------------------------------------------------------------
	Forms
-----------------------------------------------------------------*/

.sm-form-control {
	display: block;
	width: 100%;
	height: 38px;
	padding: 8px 14px;
	font-size: 15px;
	line-height: 1.42857143;
	color: #555555;
	background-color: #ffffff;
	background-image: none;
	border: 2px solid #DDD;
	border-radius: 0 !important;
	-webkit-transition: border-color ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s;
	transition: border-color ease-in-out .15s;
}

.sm-form-control:focus {
	border-color: #AAA;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.sm-form-control::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.sm-form-control:-ms-input-placeholder { color: #999; }
.sm-form-control::-webkit-input-placeholder { color: #999; }

.sm-form-control[disabled],
.sm-form-control[readonly],
fieldset[disabled] .sm-form-control {
	cursor: not-allowed;
	background-color: #eeeeee;
	opacity: 1;
}

.sm-form-control { height: auto; }

.form-control { border-radius: 3px; }

.form-control:active,
.form-control:focus {
	border-color: #999;
	box-shadow: none;
}

label {
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #555;
	margin-bottom: 10px;
	cursor: pointer;
}

label.label-muted {
	color: #999;
	font-weight: normal;
	margin-right: 5px;
}


/* ----------------------------------------------------------------
	Contact Form
-----------------------------------------------------------------*/


.contact-form-overlay {
	position: relative;
	background-color: #FFF;
	z-index: 10;
	border-radius: 4px;
	box-shadow: 0 1px 10px rgba(0,0,0,0.15);
}

label.error {
	margin-top: 5px;
	color: #E42C3E;
	font-weight: 400;
}

label.error { display: none !important; }

.show-error-msg + label.error { display: block !important; }

input[type=checkbox]:not(:checked) + label.error { display: block !important; }

.form-control.error,
.sm-form-control.error { border-color: #E42C3E; }


/* ----------------------------------------------------------------
	Google Maps
-----------------------------------------------------------------*/


.gmap {
	position: relative;
	width: 100%;
	height: 450px;
}

.gmap img { max-width: none !important; }

.gm-style .gm-style-iw h3 span {
	font-size: inherit;
	font-family: inherit;
}

#map-overlay {
	position: relative;
	padding: 100px 0;
}

#map-overlay .gmap {
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
}


/* ----------------------------------------------------------------
	Google Custom Search
-----------------------------------------------------------------*/


#content .cse .gsc-control-cse,
#content .gsc-control-cse,
#content .gsc-above-wrapper-area,
#content .gsc-adBlock,
#content .gsc-thumbnail-inside,
#content .gsc-url-top,
#content .gsc-table-result,
#content .gsc-webResult,
#content .gsc-result { padding: 0 !important; }

#content .gsc-selected-option-container { width: auto !important; }

#content .gsc-result-info { padding-left: 0 !important; }

#content .gsc-above-wrapper-area-container,
#content .gsc-table-result { margin-bottom: 10px; }

#content .gcsc-branding { display: none; }

#content .gsc-results,
#content .gsc-webResult { width: 100% !important; }

#content .gs-no-results-result .gs-snippet,
#content .gs-error-result .gs-snippet { margin: 0 !important; }


/* ----------------------------------------------------------------
	Heading Styles
-----------------------------------------------------------------*/


/* Titular
-----------------------------------------------------------------*/

.titular-title {
	font-weight: 500;
	letter-spacing: -1px;
}

h1.titular-title { font-size: 3.25rem; }

h2.titular-title { font-size: 2.75rem; }

h3.titular-title { font-size: 2rem; }

.titular-sub-title {
	margin: 0;
	font-weight: 600;
	letter-spacing: 4px;
	font-size: 1rem;
	text-transform: uppercase;
}

.titular-title + .titular-sub-title {
	margin-top: -25px;
	margin-bottom: 30px;
}


/* Block Titles
-----------------------------------------------------------------*/

.title-block {
	padding: 2px 0 3px 20px;
	border-left: 7px solid #1ABC9C;
	margin-bottom: 30px;
}

.title-block-right {
	padding: 2px 20px 3px 0;
	border-left: 0;
	border-right: 7px solid #1ABC9C;
	text-align: right;
}

.title-block h1,
.title-block h2,
.title-block h3,
.title-block h4 { margin-bottom: 0; }

.title-block > span {
	display: block;
	margin-top: 4px;
	color: #555;
	font-weight: 300;
}

.title-block h1 + span,
.title-block h2 + span {
	font-size: 1.25rem;
}

.title-block h3 + span {
	font-size: 1.125rem;
}

.title-block h4 + span {
	font-size: 0.9375px;
}


/* Heading Block - with Subtitle
-----------------------------------------------------------------*/

.heading-block { margin-bottom: 50px; }

.heading-block h1,
.heading-block h2,
.heading-block h3,
.heading-block h4,
.emphasis-title h1,
.emphasis-title h2 {
	margin-bottom: 0;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #333;
}

.emphasis-title h1,
.emphasis-title h2 {
	font-weight: 400;
	letter-spacing: 0;
	text-transform: none;
}

.heading-block h1 { font-size: 32px; }

.heading-block h2 { font-size: 30px; }

.heading-block h3 { font-size: 1.75rem; }

.heading-block h4 {
	font-size: 1.25rem;
	font-weight: 700;
}

.heading-block > span:not(.before-heading) {
	display: block;
	margin-top: 10px;
	font-weight: 300;
	color: #777;
}

.heading-block .before-heading { margin-bottom: 7px; }

.heading-block.center > span,
.heading-block.text-center > span,
.center .heading-block > span,
.text-center .heading-block > span {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 768px) {
	.text-md-start .heading-block > span {
		max-width: none !important;
	}
}

.heading-block h1 + span { font-size: 1.5rem; }

.heading-block h2 + span { font-size: 1.25rem; }

.heading-block h3 + span,
.heading-block h4 + span { font-size: 1.25rem; }

.heading-block::after {
	content: '';
	display: block;
	margin-top: 30px;
	width: 40px;
	border-top: 2px solid #444;
}

.center .heading-block::after,
.text-center .heading-block::after,
.heading-block.center::after,
.heading-block.text-center::after { margin: 30px auto 0; }

@media (min-width: 768px) {
	.text-md-start .heading-block::after {
		margin-left: 0 !important;
	}
}

.text-end .heading-block,
.heading-block.text-end,
.heading-block.title-right { direction: rtl; }

.heading-block.border-0::after,
.heading-block.border-bottom-0::after { display: none; }

.heading-block.border-color::after { border-color: #1ABC9C; }


/* Emphasis Title
-----------------------------------------------------------------*/

.emphasis-title { margin: 0 0 50px; }

.emphasis-title h1,
.emphasis-title h2 {
	font-size: 64px;
	letter-spacing: -2px;
}

.emphasis-title h1 strong,
.emphasis-title h2 strong { font-weight: 700; }


/* Justify Border Title
-----------------------------------------------------------------*/

.fancy-title {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 2rem;
}

.fancy-title h1,
.fancy-title h2,
.fancy-title h3,
.fancy-title h4,
.fancy-title h5,
.fancy-title h6 {
	position: relative;
	margin-bottom: 0;
}

.fancy-title::before,
.fancy-title::after {
	content: '';
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
	height: 0;
	border-top: 3px double #E5E5E5;
}

.fancy-title::before {
	display: none;
	margin-right: 0.75rem;
}

.fancy-title::after {
	margin-left: 0.75rem;
}

.title-border::before,
.title-border::after {
	border-top-width: 1px;
	border-top-style: solid;
}

.title-border-color::before,
.title-border-color::after {
	border-top: 1px solid #1ABC9C !important;
}


/* Fancy Title - Center Align
-----------------------------------------------------------------*/

.title-center::before {
	display: block;
}


/* Fancy Title - Right Align
-----------------------------------------------------------------*/

.title-right::before {
	display: block;
}

.title-right::after {
	display: none;
}


/* Fancy Title - Bottom Short Border
-----------------------------------------------------------------*/

.title-bottom-border::before,
.title-bottom-border::after {
	display: none;
}

.title-bottom-border h1,
.title-bottom-border h2,
.title-bottom-border h3,
.title-bottom-border h4,
.title-bottom-border h5,
.title-bottom-border h6 {
	width: 100%;
	padding: 0 0 0.75rem;
	border-bottom: 2px solid #1ABC9C;
}


/* Sub Heading
-----------------------------------------------------------------*/

.before-heading {
	display: block;
	margin: 0 0 5px;
	font-size: 1rem;
	font-family: 'PT Serif', serif;
	font-style: italic;
	font-weight: 400;
	color: #999;
}


@media (max-width: 767.98px){

	.title-block h1 + span { font-size: 18px; }

	.title-block h2 + span { font-size: 17px; }

	.title-block h3 + span { font-size: 15px; }

	.title-block h4 + span { font-size: 13px; }

	.heading-block h1 { font-size: 30px; }

	.heading-block h2 { font-size: 26px; }

	.heading-block h3 { font-size: 22px; }

	.heading-block h4 { font-size: 19px; }

	.heading-block h1 + span { font-size: 20px; }

	.heading-block h2 + span { font-size: 18px; }

	.heading-block h3 + span,
	.heading-block h4 + span { font-size: 17px; }

	.emphasis-title h1,
	.emphasis-title h2 { font-size: 44px !important; }

}


/* ----------------------------------------------------------------
	Divider
-----------------------------------------------------------------*/


.divider {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
	margin: 3rem auto;
	color: #E5E5E5;
	width: 100%;
}

.divider.divider-margin-lg { margin: 5rem auto; }

.divider::after,
.divider::before {
	content: '';
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
	height: 1px;
	background-color: #EEE;
}

.divider::before {
	display: none;
	margin-right: 0.5rem;
}

.divider::after {
	margin-left: 0.5rem;
}

.divider.divider-thick::after,
.divider.divider-thick::before { height: 3px; }

.divider.divider-sm { width: 55%; }

.divider.divider-xs { width: 35%; }

.divider i,
.divider a,
.divider-text {
	position: relative;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 24px;
	max-width: 100%;
	line-height: 1;
	font-size: 18px !important;
	text-align: center;
}

.divider a,
.divider-text {
	width: auto;
	color: inherit;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.divider a:hover { color: #888; }


/* Divider - Icon Align Right
-----------------------------------------------------------------*/

.divider.divider-right::before {
	display: block;
}

.divider.divider-right::after {
	display: none;
}


/* Divider - Icon Align Center
-----------------------------------------------------------------*/

.divider.divider-center::before {
	display: block;
}


/* Divider - Rounded Icon
-----------------------------------------------------------------*/

.divider.divider-rounded i,
.divider.divider-border i {
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #BBB;
	background-color: #F5F5F5;
	border-radius: 50%;
}

.divider.divider-rounded::before,
.divider.divider-border::before,
.divider.divider-rounded::after,
.divider.divider-border::after {
	margin-left: 0;
	margin-right: 0;
}


/* Divider - Rounded & Border
-----------------------------------------------------------------*/

.divider.divider-border { color: #E5E5E5; }

.divider.divider-border i {
	line-height: 38px;
	background-color: transparent;
	border: 1px solid #EEE;
}


/* Divider - Line Only
-----------------------------------------------------------------*/

.divider.divider-line::before,
.divider.divider-line::after {
	margin-left: 0;
	margin-right: 0;
}


/* ----------------------------------------------------------------
	Magazine Specific Classes
-----------------------------------------------------------------*/

.top-advert {
	padding: 5px;
	border-left: 1px solid #EEE;
	border-right: 1px solid #EEE;
}

.top-advert a,
.top-advert img { display: block; }

.bnews-title {
	display: block;
	float: left;
	margin-top: 2px;
	padding-top: .3em;
	text-transform: uppercase;
}

.bnews-slider {
	float: left;
	width: 970px;
	margin-left: 20px;
	min-height: 0;
}

/* ----------------------------------------------------------------
	Go To Top
-----------------------------------------------------------------*/


#gotoTop {
	display: none;
	z-index: 599;
	position: fixed;
	width: 40px;
	height: 40px;
	background-color: #333;
	background-color: rgba(0,0,0,0.3);
	font-size: 1.25rem;
	line-height: 36px;
	text-align: center;
	color: #FFF;
	top: auto;
	left: auto;
	right: 30px;
	bottom: 50px;
	cursor: pointer;
	border-radius: 2px;
}

body:not(.device-touch) #gotoTop {
	transition: background-color .2s linear;
	-webkit-transition: background-color .2s linear;
	-o-transition: background-color .2s linear;
}

.stretched #gotoTop { bottom: 30px; }

#gotoTop:hover { background-color: #1ABC9C; }


/* ----------------------------------------------------------------
	Read More
-----------------------------------------------------------------*/


.read-more-wrap {
	position: relative;
	overflow: hidden;
	-webkit-transition: height .5s ease;
	transition: height .5s ease;
}

.read-more-wrap .read-more-mask {
	position: absolute;
	z-index: 9;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	min-height: 60px;
	height: 35%;
	background-image: linear-gradient( rgba(255,255,255,0), rgba(255,255,255,1 ) );
}

.read-more-trigger {
	-webkit-transition: all .5s ease;
	transition: all .5s ease;
}

.read-more-trigger i {
	position: relative;
	top: 1px;
	margin-left: 3px;
}

.read-more-wrap .read-more-trigger {
	position: absolute;
	display: block !important;
	z-index: 10;
	top: auto;
	bottom: 5px;
	left: 5px;
	margin: 0 !important;
}

.read-more-wrap .read-more-trigger-center {
	left: 50%;
	transform: translateX(-50%);
}

.read-more-wrap .read-more-trigger-right {
	left: auto;
	right: 5px;
}


/* ----------------------------------------------------------------
	GDPR Settings
-----------------------------------------------------------------*/

.gdpr-settings {
	position: fixed;
	max-width: calc(100vw - 2rem);
	max-height: 60vh;
	overflow-y: scroll;
	margin-bottom: 1rem;
	border-radius: 4px;
	top: auto;
	bottom: 0;
	left: 1rem;
	opacity: 0;
	padding: 3rem;
	z-index: 999;
	background-color: #EEE;
	transition: all .3s ease;
}

.gdpr-settings-sm {
	max-width: 30rem;
	margin-right: 1rem;
}

.gdpr-settings-sm.gdpr-settings-right {
	left: auto;
	right: 1rem;
	margin-right: 0;
	margin-left: 1rem;
}

@media (min-width: 768px) {
	.gdpr-settings {
		max-height: none;
		overflow: auto;
	}
}

.gdpr-settings.dark {
	background-color: #111;
}


.gdpr-container {
	position: relative;
	height: auto;
}

.gdpr-blocked-message {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	text-align: center;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.gdpr-blocked-message a {
	text-decoration: underline !important;
}

.gdpr-container:not(.gdpr-content-active) {
	min-height: 250px;
}

.gdpr-container.gdpr-content-active {
	min-height: 0 !important;
}

.gdpr-container.gdpr-content-active .gdpr-blocked-message {
	display: none;
}


/* ----------------------------------------------------------------
	Error 404
-----------------------------------------------------------------*/


.error404 {
	display: block;
	font-size: 18vw;
	font-weight: 700;
	color: #DDD;
	line-height: 1;
	letter-spacing: 4px;
}

.error404-wrap .container { z-index: 7; }

.error404-wrap .error404 {
	line-height: 0.9;
	margin-bottom: 40px;
	font-weight: bold;
	font-size: 19vw;
	color: #FFF !important;
	opacity: 0.2;
	text-shadow: 1px 1px 5px rgba(0,0,0,0.4);
}

.error404-wrap .heading-block h4 {
	font-weight: 300;
	margin-bottom: 8px;
}

.error404-wrap .heading-block span { font-size: 1.125rem; }

.error404-wrap form { max-width: 500px; }


/* ----------------------------------------------------------------
	Landing Pages
-----------------------------------------------------------------*/

.landing-wide-form {
	background: rgba(0,0,0,0.3);
	padding: 30px;
	border-radius: 3px;
}

.landing-form-overlay {
	position: absolute;
	z-index: 10;
	top: auto;
	left: auto;
	right: 0;
	bottom: -154px;
	background-color: rgba(0,0,0,0.6);
	border-radius: 3px 3px 0 0;
}

.landing-video {
	z-index: 1;
	width: 560px;
	height: 315px;
	margin: 22px 0 0 95px;
	overflow: hidden;
}

.landing-promo h3 { font-size: 1.75rem; }

.landing-promo > .container > span { font-size: 1.125rem; }


/* ----------------------------------------------------------------
	Preloaders
-----------------------------------------------------------------*/


.preloader,
.preloader2,
.form-process {
	display: block;
	width: 100%;
	height: 100%;
	background: center center no-repeat #FFF;
}

.preloader2 { background-color: transparent; }

.form-process {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	background-color: transparent;
}

.form-process::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #FFF;
	opacity: 0.7;
	z-index: -1;
}


/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/


#footer {
	position: relative;
	background-color: #EEE;
	border-top: 5px solid rgba(0,0,0,0.2);
}

@media (min-width: 992px) {
	.sticky-footer #slider:not(.slider-parallax-invisible),
	.sticky-footer #page-submenu,
	.sticky-footer #page-title,
	.sticky-footer #content {
		z-index: 2;
	}

	.sticky-footer #content {
		transform: translate3d(0,0,0);
	}

	.sticky-footer #footer {
		position: sticky;
		top: auto;
		bottom: 0;
		left: 0;
	}
}

#footer .footer-widgets-wrap {
	position: relative;
	padding: 80px 0;
}

#copyrights {
	padding: 40px 0;
	background-color: #DDD;
	font-size: 0.875rem;
	line-height: 1.8;
}

#copyrights i.footer-icon {
	position: relative;
	top: 1px;
	font-size: 0.875rem;
	width: 14px;
	text-align: center;
	margin-right: 3px;
}

.copyright-links a {
	display: inline-block;
	margin: 0 3px;
	color: #333;
	border-bottom: 1px dotted #444;
}

.copyright-links a:hover {
	color: #555;
	border-bottom: 1px solid #666;
}

.copyrights-menu { margin-bottom: 10px; }

.copyrights-menu a {
	font-size: 0.875rem;
	margin: 0 10px;
	border-bottom: 0 !important;
}

#copyrights a:first-child { margin-left: 0; }

#copyrights.text-end a:last-child { margin-right: 0; }

.footer-logo {
	display: block;
	margin-bottom: 30px;
}


/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/


.widget {
	position: relative;
	margin-top: 50px;
}

.widget p { line-height: 1.7; }

.sidebar-widgets-wrap .widget {
	padding-top: 50px;
	border-top: 1px solid #EEE;
}

.widget:first-child { margin-top: 0; }

.sidebar-widgets-wrap .widget:first-child {
	padding-top: 0;
	border-top: 0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.sidebar-widgets-wrap {
		position: relative;
		display: -ms-flex;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -20px -40px -20px;
	}

	.sidebar-widgets-wrap .widget {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		margin: 0 0 40px 0;
		padding-top: 0;
		padding-left: 20px;
		padding-right: 20px;
		border-top: 0;
	}
}

.widget > h4 {
	margin-bottom: 25px;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.sidebar-widgets-wrap .widget > h4 { letter-spacing: 1px; }


/* Tag Cloud
-----------------------------------------------------------------*/


.tagcloud { margin-bottom: -2px; }

.tagcloud a {
	display: block;
	float: left;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 3px 6px;
	border: 1px solid #E5E5E5;
	font-size: 13px !important;
	color: #666;
	border-radius: 2px;
}

body:not(.device-touch) .tagcloud a {
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.tagcloud a:hover {
	border-color: #1ABC9C !important;
	color: #1ABC9C !important;
}


/* Widget - Links
-----------------------------------------------------------------*/

.widget_nav_menu ul,
.widget_nav_menu li,
.widget_links ul,
.widget_links li,
.widget_meta ul,
.widget_meta li,
.widget_archive ul,
.widget_archive li,
.widget_recent_comments ul,
.widget_recent_comments li,
.widget_recent_entries ul,
.widget_recent_entries li,
.widget_categories ul,
.widget_categories li,
.widget_pages ul,
.widget_pages li,
.widget_rss ul,
.widget_rss li {
	list-style: none;
	margin: 0;
}

.widget_nav_menu li,
.widget_links li,
.widget_meta li,
.widget_archive li,
.widget_recent_comments li,
.widget_recent_entries li,
.widget_categories li,
.widget_pages li,
.widget_rss li {
	display: flex;
	padding: 4px 0;
	font-size: 15px;
}

.widget_nav_menu:not(.widget-li-noicon) li::before,
.widget_links:not(.widget-li-noicon) li::before,
.widget_meta:not(.widget-li-noicon) li::before,
.widget_archive:not(.widget-li-noicon) li::before,
.widget_recent_entries:not(.widget-li-noicon) li::before,
.widget_categories:not(.widget-li-noicon) li::before,
.widget_pages:not(.widget-li-noicon) li::before,
.widget_rss:not(.widget-li-noicon) li::before,
.widget_recent_comments:not(.widget-li-noicon) li::before {
	content: "\e7a5";
	font-family: 'font-icons';
	display: inline-block;
	position: relative;
	top: 1px;
	left: 0;
}

.widget_recent_comments li::before {
	content: "\e9b8";
}

.widget_nav_menu li a,
.widget_links li a,
.widget_meta li a,
.widget_archive li a,
.widget_recent_entries li a,
.widget_categories li a,
.widget_pages li a,
.widget_rss li a,
.widget_recent_comments li a {
	display: inline-block;
	padding: 0 3px 0 12px;
	border: none !important;
	color: #444;
}

.widget_nav_menu.widget-li-noicon li a,
.widget_links.widget-li-noicon li a,
.widget_meta.widget-li-noicon li a,
.widget_archive.widget-li-noicon li a,
.widget_recent_entries.widget-li-noicon li a,
.widget_categories.widget-li-noicon li a,
.widget_pages.widget-li-noicon li a,
.widget_rss.widget-li-noicon li a,
.widget_recent_comments.widget-li-noicon li a {
	padding-left: 0;
}

.widget_nav_menu li a:hover,
.widget_links li a:hover,
.widget_meta li a:hover,
.widget_archive li a:hover,
.widget_recent_entries li a:hover,
.widget_categories li a:hover,
.widget_pages li a:hover,
.widget_rss li a:hover,
.widget_recent_comments li a:hover { color: #000; }

.widget_nav_menu > ul > li:first-child,
.widget_links > ul > li:first-child,
.widget_meta > ul > li:first-child,
.widget_archive > ul > li:first-child,
.widget_recent_comments > ul > li:first-child,
.widget_recent_entries > ul > li:first-child,
.widget_categories > ul > li:first-child,
.widget_pages > ul > li:first-child,
.widget_rss > ul > li:first-child { border-top: 0 !important; }

.widget_nav_menu > ul,
.widget_links > ul,
.widget_meta > ul,
.widget_archive > ul,
.widget_recent_comments > ul,
.widget_recent_entries > ul,
.widget_categories > ul,
.widget_pages > ul,
.widget_rss > ul { margin-top: -4px !important; }

.widget_nav_menu ul ul,
.widget_links ul ul,
.widget_meta ul ul,
.widget_archive ul ul,
.widget_recent_comments ul ul,
.widget_recent_entries ul ul,
.widget_categories ul ul,
.widget_pages ul ul,
.widget_rss ul ul { margin-left: 15px; }


/* Widget - Testimonial & Twitter
-----------------------------------------------------------------*/

.widget .testimonial.no-image .testi-image { display: none; }

.widget .testimonial.twitter-scroll .testi-image { margin-right: 10px; }

.widget .testimonial:not(.twitter-scroll) .testi-image,
.widget .testimonial:not(.twitter-scroll) .testi-image a,
.widget .testimonial:not(.twitter-scroll) .testi-image img {
	width: 42px;
	height: 42px;
}

.widget .testimonial.twitter-scroll .testi-image,
.widget .testimonial.twitter-scroll .testi-image a,
.widget .testimonial.twitter-scroll .testi-image img,
.widget .testimonial.twitter-scroll .testi-image i {
	width: 28px;
	height: 28px;
}

.widget .testimonial.twitter-scroll .testi-image i {
	background-color: #EEE;
	line-height: 28px;
	font-size: 0.875rem;
	color: #888;
}

.widget .testimonial p { font-size: 0.875rem; }


/* Widget - Quick Contact Form
-----------------------------------------------------------------*/

.quick-contact-widget.form-widget .form-control,
.quick-contact-widget.form-widget .input-group,
.quick-contact-widget.form-widget .sm-form-control { margin-bottom: 10px; }

.quick-contact-widget.form-widget .input-group .form-control { margin-bottom: 0; }

.quick-contact-widget.form-widget form,
#template-contactform { position: relative; }


/* Newsletter Widget
-----------------------------------------------------------------*/

.subscribe-widget h5 {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 1.5;
}


/* Twitter Feed Widget
-----------------------------------------------------------------*/

.widget-twitter-feed li { margin: 15px 0 0 0; }

.widget-twitter-feed li:first-child { margin-top: 0; }

.widget-twitter-feed small {
	display: block;
	margin-top: 3px;
}

.widget-twitter-feed small a:not(:hover) { color: #999; }

.widget-twitter-feed .twitter-feed:not(.twitter-feed-avatar) a.twitter-avatar { display: none; }

.widget-twitter-feed .twitter-feed.twitter-feed-avatar i.icon-twitter { display: none; }

.widget-twitter-feed .twitter-feed-avatar { margin-left: 44px; }

.widget-twitter-feed .twitter-feed-avatar > li a.twitter-avatar {
	display: block;
	position: absolute;
	left: -44px;
	text-align: center;
	top: 2px;
	width: 32px;
	height: 32px;
}

.twitter-feed-avatar a.twitter-avatar img { border-radius: 50%; }


/* Widget Filter Links
-----------------------------------------------------------------*/

.widget-filter-links ul {
	list-style: none;
	margin-bottom: 0;
}

.widget-filter-links .widget-filter-reset {
	position: absolute;
	top: 0;
	left: auto;
	right: 0;
	font-size: 12px;
	line-height: 22px;
}

.widget:not(:first-child) .widget-filter-reset { top: 50px; }

.widget-filter-links .widget-filter-reset a { color: red !important; }

.widget-filter-links .widget-filter-reset.active-filter { display: none; }

.widget-filter-links li {
	position: relative;
	font-size: 15px;
	line-height: 24px;
}

.widget-filter-links li:not(:first-child) { margin-top: 10px; }

.widget-filter-links li a {
	display: block;
	color: #444;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.widget-filter-links li span {
	position: absolute;
	top: 1px;
	left: auto;
	right: 0;
	font-size: 11px;
	width: 22px;
	height: 22px;
	line-height: 22px;
	text-align: center;
	color: #777;
	background-color: #EEE;
	border-radius: 50%;
}

.widget-filter-links li:hover a { color: #777; }

.widget-filter-links li.active-filter a { font-weight: 700; }

.widget-filter-links li.active-filter span {
	color: #FFF !important;
	background-color: #1ABC9C !important;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}


/* Navigation Tree
-----------------------------------------------------------------*/

.nav-tree {
	position: relative;
	margin-bottom: 40px;
}

.nav-tree ul {
	margin-bottom: 0;
	list-style: none;
}

.nav-tree > ul { margin: -8px 0; }

.nav-tree li { position: relative; }

.nav-tree li a {
	display: block;
	padding: 8px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 2px;
	font-family: 'Poppins', sans-serif;
}

.nav-tree li i {
	font-size: 0.875rem;
	width: 16px;
	text-align: center;
}

.nav-tree li i:not(.icon-angle-down) {
	margin-right: 8px;
	position: relative;
	top: 1px;
}

.nav-tree li a i.icon-angle-down {
	width: auto;
	font-size: 12px;
	margin-left: 2px;
}

.nav-tree li:hover > a,
.nav-tree li.current > a,
.nav-tree li.active > a { color: #1ABC9C !important; }

.nav-tree ul ul { display: none; }

.nav-tree li.current > ul { display: block; }

.nav-tree ul ul a {
	font-size: 12px;
	padding: 6px 0;
	letter-spacing: 1px;
	font-family: 'Lato', sans-serif;
}

.nav-tree ul ul a i.icon-angle-down { font-size: 12px; }

.nav-tree ul ul a { padding-left: 20px; }
.nav-tree ul ul ul a { padding-left: 40px; }
.nav-tree ul ul ul ul a { padding-left: 60px; }
.nav-tree ul ul ul ul ul a { padding-left: 80px; }


/* ----------------------------------------------------------------
	Wedding
-----------------------------------------------------------------*/


.wedding-head {
	position: relative;
	line-height: 1;
	font-size: 80px;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.15);
	font-family: 'Poppins', sans-serif;
}

.wedding-head .first-name,
.wedding-head .last-name,
.wedding-head .and {
	display: inline-block;
	margin-right: 15px;
	font-weight: bold;
	text-align: right;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.wedding-head .last-name {
	margin: 0 0 0 15px;
	text-align: left;
}

.wedding-head .first-name span,
.wedding-head .last-name span {
	display: block;
	margin-top: 10px;
	font-size: 56px;
	font-weight: 400;
	font-style: italic;
	font-family: 'PT Serif', serif;
	text-transform: none;
}

.wedding-head .and {
	margin: 0;
	font-size: 200px;
	font-family: 'Times New Roman', serif;
}


/* ----------------------------------------------------------------
	Styled Paginations
-----------------------------------------------------------------*/


.pagination.pagination-circle .page-item .page-link {
	width: 35px;
	height: 35px;
	line-height: 33px;
	text-align: center;
	padding: 0;
	border-radius: 50%;
}

.pagination.pagination-circle.pagination-lg .page-item .page-link {
	width: 56px;
	height: 56px;
	line-height: 54px;
}

.pagination.pagination-circle.pagination-sm .page-item .page-link {
	width: 26px;
	height: 26px;
	line-height: 24px;
}

/* Pagination Rounded */
.pagination.pagination-rounded .page-item .page-link { border-radius: 4px; }

/* Pagination Margins */
.pagination.pagination-circle .page-item:not(:first-child) .page-link,
.pagination.pagination-rounded .page-item:not(:first-child) .page-link { margin-left: 5px; }

.pagination.pagination-circle.pagination-lg .page-item:not(:first-child) .page-link,
.pagination.pagination-rounded.pagination-lg .page-item:not(:first-child) .page-link { margin-left: 8px; }

.pagination.pagination-circle.pagination-sm .page-item:not(:first-child) .page-link,
.pagination.pagination-rounded.pagination-sm .page-item:not(:first-child) .page-link { margin-left: 3px; }

/* Pagination 3d */
.pagination.pagination-3d .page-item .page-link { border-bottom: 3px solid rgba(0,0,0,0.15) !important; }

/* Pagination Transparent */
.pagination.pagination-transparent .page-item .page-link { border: 0; }

/* Pagination Transparent */
.pagination.pagination-inside-transparent .page-item .page-link:not(:hover) { color: #222; }

/* Pagination Inside Transparent */
.pagination.pagination-inside-transparent .page-item:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)) .page-link { border-left: 0; }

.pagination.pagination-inside-transparent .page-item.active .page-link,
.pagination.pagination-inside-transparent .page-link:hover,
.pagination.pagination-inside-transparent .page-link:focus { border-color: transparent !important; }

/* Pagination Button */
.pagination.pagination-inside-transparent.pagination-button .page-item:not(:first-child):not(:nth-last-child(1)) .page-link { border: 0; }

/* Pagination Pill */
.pagination.pagination-pill .page-item:first-child .page-link {
	border-top-left-radius: 10rem;
	border-bottom-left-radius: 10rem;
}

.pagination.pagination-pill .page-item:last-child .page-link {
	border-top-right-radius: 10rem;
	border-bottom-right-radius: 10rem;
}

/* Pagination primary */
.pagination.pagination-primary .page-item .page-link {
	background-color: #007bff;
	background-color: var(--primary);
	border-color: #007bff;
	border-color: var(--primary);
	color: #FFF;
}

/* Pagination secondary */
.pagination.pagination-secondary .page-item .page-link {
	background-color: #6c757d;
	background-color: var(--secondary);
	border-color: #6c757d;
	border-color: var(--secondary);
	color: #FFF;
}

/* Pagination success */
.pagination.pagination-success .page-item .page-link {
	background-color: #28a745;
	background-color: var(--success);
	border-color: #28a745;
	border-color: var(--success);
	color: #FFF;
}

/* Pagination danger */
.pagination.pagination-danger .page-item .page-link {
	background-color: #dc3545;
	background-color: var(--danger);
	border-color: #dc3545;
	border-color: var(--danger);
	color: #FFF;
}

/* Pagination info */
.pagination.pagination-info .page-item .page-link {
	background-color: #17a2b8;
	background-color: var(--info);
	border-color: #17a2b8;
	border-color: var(--info);
	color: #FFF;
}

/* Pagination dark */
.pagination.pagination-dark .page-item .page-link {
	background-color: #343a40;
	background-color: var(--dark);
	border-color: #343a40;
	border-color: var(--dark);
	color: #FFF;
}

/* Pagination light */
.pagination.pagination-light .page-item .page-link {
	background-color: #f8f9fa;
	background-color: var(--light);
	border-color: #f8f9fa;
	border-color: var(--light);
	color: #222;
}

/* Pagination Bootstrap Background */
.pagination.pagination-primary .page-item.active .page-link,
.pagination.pagination-primary .page-link:hover,
.pagination.pagination-primary .page-link:focus,
.pagination.pagination-secondary .page-item.active .page-link,
.pagination.pagination-secondary .page-link:hover,
.pagination.pagination-secondary .page-link:focus,
.pagination.pagination-success .page-item.active .page-link,
.pagination.pagination-success .page-link:hover,
.pagination.pagination-success .page-link:focus,
.pagination.pagination-danger .page-item.active .page-link,
.pagination.pagination-danger .page-link:hover,
.pagination.pagination-danger .page-link:focus,
.pagination.pagination-info .page-item.active .page-link,
.pagination.pagination-info .page-link:hover,
.pagination.pagination-info .page-link:focus,
.pagination.pagination-light .page-item.active .page-link,
.pagination.pagination-light .page-link:hover,
.pagination.pagination-light .page-link:focus {
	background-color: #222 !important;
	border-color: #222 !important;
	color: #FFF;
}


/* --------------------------------------------------------------
	SWITCH
--------------------------------------------------------------  */

.switch-toggle {
	position: absolute;
	margin-left: -9999px;
	visibility: hidden;
}

.switch-toggle + label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* --------------------------------------------------------------
	SWITCH 1 - ROUND
----------------------------------------------------------------- */
input.switch-toggle-round + label {
	padding: 2px;
	width: 60px;
	height: 30px;
	background-color: #DDD;
	-webkit-border-radius: 15px;
	-o-border-radius: 15px;
	border-radius: 15px;
}

input.switch-toggle-round + label::before,
input.switch-toggle-round + label::after {
	display: block;
	position: absolute;
	top: 1px;
	left: 1px;
	bottom: 1px;
	content: "";
}

input.switch-toggle-round + label::before {
	right: 1px;
	background-color: #F1F1F1;
	-webkit-border-radius: 15px;
	-o-border-radius: 15px;
	border-radius: 15px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.switch-toggle-round + label::after {
	width: 28px;
	background-color: #FFF;
	-webkit-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	-webkit-transition: margin 0.4s;
	-moz-transition: margin 0.4s;
	-o-transition: margin 0.4s;
	transition: margin 0.4s;
}

input.switch-toggle-round:checked + label::before { background-color: #1ABC9C; }
input.switch-toggle-round:checked + label::after { margin-left: 30px; }

/* --------------------------------------------------------------
	SWITCH 1 - ROUND- MINI
----------------------------------------------------------------- */
input.switch-rounded-mini.switch-toggle-round + label {
	padding: 1px;
	width: 32px;
	height: 16px;
	-webkit-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
}

input.switch-rounded-mini.switch-toggle-round + label::before {
	-webkit-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
}

input.switch-rounded-mini.switch-toggle-round + label::after { width: 15px; }
input.switch-rounded-mini.switch-toggle-round:checked + label::after { margin-left: 15px; }

/* --------------------------------------------------------------
	SWITCH 1 - ROUND- LARGE
----------------------------------------------------------------- */
input.switch-rounded-large.switch-toggle-round + label {
	width: 90px;
	height: 45px;
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-rounded-large.switch-toggle-round + label::before {
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-rounded-large.switch-toggle-round + label::after { width: 43px; }
input.switch-rounded-large.switch-toggle-round:checked + label::after { margin-left: 45px; }


/* --------------------------------------------------------------
	SWITCH 1 - ROUND- XLARGE
----------------------------------------------------------------- */
input.switch-rounded-xlarge.switch-toggle-round + label {
	width: 120px;
	height: 60px;
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}

input.switch-rounded-xlarge.switch-toggle-round + label::before {
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}

input.switch-rounded-xlarge.switch-toggle-round + label::after { width: 58px; }
input.switch-rounded-xlarge.switch-toggle-round:checked + label::after { margin-left: 60px; }


/* -----------------------------------------------------------
	SWITCH 2 - ROUND FLAT
-------------------------------------------------------------- */
input.switch-toggle-flat + label {
	padding: 2px;
	width: 60px;
	height: 30px;
	background-color: #DDD;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.switch-toggle-flat + label::before,
input.switch-toggle-flat + label::after {
	display: block;
	position: absolute;
	content: "";
}

input.switch-toggle-flat + label::before {
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;
	background-color: #FFF;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.switch-toggle-flat + label::after {
	top: 4px;
	left: 4px;
	bottom: 4px;
	width: 22px;
	background-color: #DDD;
	-webkit-border-radius: 22px;
	-moz-border-radius: 22px;
	-ms-border-radius: 22px;
	-o-border-radius: 22px;
	border-radius: 22px;
	-webkit-transition: margin 0.4s, background 0.4s;
	-moz-transition: margin 0.4s, background 0.4s;
	-o-transition: margin 0.4s, background 0.4s;
	transition: margin 0.4s, background 0.4s;
}

input.switch-toggle-flat:checked + label { background-color: #1ABC9C; }

input.switch-toggle-flat:checked + label::after {
	margin-left: 30px;
	background-color: #1ABC9C;
}


/* -----------------------------------------------------------
	SWITCH 2 - FLAT - MINI
-------------------------------------------------------------- */
input.switch-flat-mini.switch-toggle-flat + label {
	padding: 1px;
	width: 32px;
	height: 16px;
	-webkit-border-radius: 16px;
	-o-border-radius: 16px;
	border-radius: 16px;
}

input.switch-flat-mini.switch-toggle-flat + label::before {
	top: 1px;
	left: 1px;
	bottom: 1px;
	right: 1px;
	-webkit-border-radius: 16px;
	-o-border-radius: 16px;
	border-radius: 16px;
}

input.switch-flat-mini.switch-toggle-flat + label::after {
	top: 2px;
	left: 2px;
	bottom: 2px;
	width: 12px;
	-webkit-border-radius: 12px;
	-o-border-radius: 12px;
	border-radius: 12px;
}

input.switch-flat-mini.switch-toggle-flat:checked + label::after { margin-left: 16px; }


/* -----------------------------------------------------------
	SWITCH 2 - FLAT - LARGE
-------------------------------------------------------------- */
input.switch-flat-large.switch-toggle-flat + label {
	width: 90px;
	height: 45px;
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-flat-large.switch-toggle-flat + label::before {
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-flat-large.switch-toggle-flat + label::after {
	width: 37px;
	-webkit-border-radius: 37px;
	-o-border-radius: 37px;
	border-radius: 37px;
}

input.switch-flat-large.switch-toggle-flat:checked + label::after { margin-left: 45px; }



/* -----------------------------------------------------------
	SWITCH 2 - FLAT - XLARGE
-------------------------------------------------------------- */
input.switch-flat-xlarge.switch-toggle-flat + label {
	padding: 2px;
	width: 120px;
	height: 60px;
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}

input.switch-flat-xlarge.switch-toggle-flat + label::before {
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}
input.switch-flat-xlarge.switch-toggle-flat + label::after {
	width: 52px;
	-webkit-border-radius: 52px;
	-o-border-radius: 52px;
	border-radius: 52px;
}

input.switch-flat-xlarge.switch-toggle-flat:checked + label::after { margin-left: 60px; }


.show-error-msg .switch + label.error {
	display: block !important;
}

.switch input[type="checkbox"].error + label {
	border: 1px solid red;
}


/* ----------------------------------------------------------------
	Bootstrap Specific
-----------------------------------------------------------------*/

.table .success { color: #3c763d; }

.table .info { color: #31708f; }

.table .warning { color: #8a6d3b; }

.table .danger { color: #a94442; }

.btn-link,
.page-link,
.page-link:hover,
.page-link:focus { color: #1ABC9C; }

.btn-link:hover,
.btn-link:focus { color: #222; }

.boot-tabs { margin-bottom: 20px !important; }

.carousel-control .icon-chevron-left,
.carousel-control .icon-chevron-right {
	position: absolute;
	top: 47.5%;
	z-index: 5;
	display: inline-block;
}

.carousel-control .icon-chevron-left { left: 50%; }

.carousel-control .icon-chevron-right { right: 50%; }

.responsive-utilities th small {
	display: block;
	font-weight: 400;
	color: #999;
}

.show-grid [class^=col-] {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #EEE;
	border: 1px solid #DDD;
}

.img-circle { border-radius: 50% !important; }

.page-item.active .page-link,
.page-link:hover,
.page-link:focus {
	color: #FFF !important;
	background-color: #1ABC9C !important;
	border-color: #1ABC9C !important;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus { background-color: #1ABC9C; }

label.radio,
label.checkbox { padding-left: 20px; }

.input-group-lg > .input-group-addon { line-height: 1; }


/* ----------------------------------------------------------------
	Stretched Layout
-----------------------------------------------------------------*/


.stretched #wrapper {
	width: 100%;
	margin: 0;
	box-shadow: none;
}


/* ----------------------------------------------------------------
	Shape Dividers
-----------------------------------------------------------------*/


.shape-divider {
	position: absolute;
	top: -1px;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 1;
}

.shape-divider[data-position="bottom"] {
	top: auto;
	bottom: -1px;
}

.shape-divider[data-front="true"] {
	z-index: 4;
}

.shape-divider svg {
	position: relative;
	width: 100%;
	width: calc( 100% + 1.3px );
	height: 50px;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
}

.shape-divider[data-position="bottom"],
.shape-divider[data-flip-vertical="true"] {
	transform: rotate(180deg);
}

.shape-divider[data-position="bottom"][data-flip-vertical="true"] {
	transform: rotate(0deg);
}

.shape-divider[data-flip="true"] svg {
	transform: translateX(-50%) rotateY(180deg);
}

.shape-divider-fill {
	fill: #FFF;
	transform-origin: center;
	transform: rotateY(0deg);
}


/* ----------------------------------------------------------------
	Page Transitions
-----------------------------------------------------------------*/

.page-transition #wrapper,
.animsition-overlay {
	position: relative;
	opacity: 0;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.page-transition-wrap,
.css3-spinner {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	text-align: center;
	background-color: #FFF;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-perspective: 1000;
}

.css3-spinner {
	position: absolute;
	z-index: auto;
	background-color: transparent;
}

.css3-spinner > div {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -9px;
	margin-left: 13px;
	width: 18px;
	height: 18px;
	background-color: #DDD;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
	animation: bouncedelay 1.4s infinite ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner .css3-spinner-bounce1 {
	margin-left: -31px;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.css3-spinner .css3-spinner-bounce2 {
	margin-left: -9px;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0.0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
	0%, 80%, 100% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
	} 40% {
	transform: scale(1.0);
	-webkit-transform: scale(1.0);
	}
}


.css3-spinner > .css3-spinner-flipper {
	width: 32px;
	height: 32px;
	margin-top: -16px;
	margin-left: -16px;
	border-radius: 0;
	-webkit-animation: rotateplane 1.2s infinite ease-in-out;
	animation: rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes rotateplane {
	0% { -webkit-transform: perspective(120px) }
	50% { -webkit-transform: perspective(120px) rotateY(180deg) }
	100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
	} 50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
	} 100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

.css3-spinner > .css3-spinner-double-bounce1,
.css3-spinner > .css3-spinner-double-bounce2 {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	border-radius: 50%;
	opacity: 0.6;
	-webkit-animation: cssspinnerbounce 2.0s infinite ease-in-out;
	animation: cssspinnerbounce 2.0s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-double-bounce2 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

@-webkit-keyframes cssspinnerbounce {
	0%, 100% { -webkit-transform: scale(0.0) }
	50% { -webkit-transform: scale(1.0) }
}

@keyframes cssspinnerbounce {
	0%, 100% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
	} 50% {
	transform: scale(1.0);
	-webkit-transform: scale(1.0);
	}
}

.css3-spinner > .css3-spinner-rect1,
.css3-spinner > .css3-spinner-rect2,
.css3-spinner > .css3-spinner-rect3,
.css3-spinner > .css3-spinner-rect4,
.css3-spinner > .css3-spinner-rect5 {
	height: 30px;
	width: 6px;
	margin-top: -15px;
	margin-left: -21px;
	border-radius: 0;
	-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
	animation: stretchdelay 1.2s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-rect2 {
	margin-left: -12px;
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.css3-spinner > .css3-spinner-rect3 {
	margin-left: -3px;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

.css3-spinner > .css3-spinner-rect4 {
	margin-left: 6px;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.css3-spinner > .css3-spinner-rect5 {
	margin-left: 15px;
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
	0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
	20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
	0%, 40%, 100% {
	transform: scaleY(0.4);
	-webkit-transform: scaleY(0.4);
	}  20% {
	transform: scaleY(1.0);
	-webkit-transform: scaleY(1.0);
	}
}


.css3-spinner > .css3-spinner-cube1,
.css3-spinner > .css3-spinner-cube2 {
	width: 16px;
	height: 16px;
	border-radius: 0;
	margin-top: -20px;
	margin-left: -20px;
	-webkit-animation: cubemove 1.8s infinite ease-in-out;
	animation: cubemove 1.8s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-cube2 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

@-webkit-keyframes cubemove {
	25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
	50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
	75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
	100% { -webkit-transform: rotate(-360deg) }
}

@keyframes cubemove {
	25% {
	transform: translateX(42px) rotate(-90deg) scale(0.5);
	-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	} 50% {
	transform: translateX(42px) translateY(42px) rotate(-179deg);
	-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	} 50.1% {
	transform: translateX(42px) translateY(42px) rotate(-180deg);
	-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	} 75% {
	transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	} 100% {
	transform: rotate(-360deg);
	-webkit-transform: rotate(-360deg);
	}
}


.css3-spinner > .css3-spinner-scaler {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	-webkit-animation: scaleout 1.0s infinite ease-in-out;
	animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
	0% { -webkit-transform: scale(0.0) }
	100% {
		-webkit-transform: scale(1.0);
		opacity: 0;
	}
}

@keyframes scaleout {
	0% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
	} 100% {
	transform: scale(1.0);
		-webkit-transform: scale(1.0);
		opacity: 0;
	}
}


.css3-spinner > .css3-spinner-grid-pulse {
	display: -ms-flex;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 48px;
	height: 48px;
	margin-top: -24px;
	margin-left: -24px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-grid-pulse > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	max-width: 12px;
	border-radius: 100%;
	margin: 2px;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	display: inline-block;
	-webkit-animation-name: ball-grid-pulse;
	animation-name: ball-grid-pulse;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0;
	animation-delay: 0;
}

.css3-spinner-grid-pulse > div:nth-child(1) {
	-webkit-animation-delay: 0.73s;
	animation-delay: 0.73s;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
}

.css3-spinner-grid-pulse > div:nth-child(2) {
	-webkit-animation-delay: 0.32s;
	animation-delay: 0.32s;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
}

.css3-spinner-grid-pulse > div:nth-child(3) {
	-webkit-animation-delay: 0.71s;
	animation-delay: 0.71s;
	-webkit-animation-duration: 0.88s;
	animation-duration: 0.88s;
}

.css3-spinner-grid-pulse > div:nth-child(4) {
	-webkit-animation-delay: 0.62s;
	animation-delay: 0.62s;
	-webkit-animation-duration: 1.06s;
	animation-duration: 1.06s;
}

.css3-spinner-grid-pulse > div:nth-child(5) {
	-webkit-animation-delay: 0.31s;
	animation-delay: 0.31s;
	-webkit-animation-duration: 0.62s;
	animation-duration: 0.62s;
}

.css3-spinner-grid-pulse > div:nth-child(6) {
	-webkit-animation-delay: -0.14s;
	animation-delay: -0.14s;
	-webkit-animation-duration: 1.48s;
	animation-duration: 1.48s;
}

.css3-spinner-grid-pulse > div:nth-child(7) {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
	-webkit-animation-duration: 1.47s;
	animation-duration: 1.47s;
}

.css3-spinner-grid-pulse > div:nth-child(8) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	-webkit-animation-duration: 1.49s;
	animation-duration: 1.49s;
}

.css3-spinner-grid-pulse > div:nth-child(9) {
	-webkit-animation-delay: 0.73s;
	animation-delay: 0.73s;
	-webkit-animation-duration: 0.7s;
	animation-duration: 0.7s;
}

@-webkit-keyframes ball-grid-pulse {
	0% {
	-webkit-transform: scale(1);
	transform: scale(1); }

	50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	opacity: 0.7; }

	100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1; }
}

@keyframes ball-grid-pulse {
	0% {
	-webkit-transform: scale(1);
	transform: scale(1); }

	50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	opacity: 0.7; }

	100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1; }
}


.css3-spinner > .css3-spinner-clip-rotate {
	width: 33px;
	height: 33px;
	margin-top: -17px;
	margin-left: -17px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-clip-rotate > div {
	border-radius: 100%;
	border: 2px solid #DDD;
	border-bottom-color: transparent !important;
	height: 33px;
	width: 33px;
	background: transparent !important;
	-webkit-animation: rotate 1s 0s linear infinite;
	animation: rotate 1s 0s linear infinite;
}

@keyframes rotate {
	0% {
	-webkit-transform: rotate(0deg) scale(1);
	transform: rotate(0deg) scale(1); }

	50% {
	-webkit-transform: rotate(180deg) scale(0.6);
	transform: rotate(180deg) scale(0.6); }

	100% {
	-webkit-transform: rotate(360deg) scale(1);
	transform: rotate(360deg) scale(1); }
}


.css3-spinner > .css3-spinner-ball-rotate {
	width: 12px;
	height: 12px;
	margin-top: -6px;
	margin-left: -6px;
	background-color: transparent !important;
	-webkit-animation: ballrotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;
	animation: ballrotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;
}

.css3-spinner-ball-rotate > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	position: relative;
}

.css3-spinner-ball-rotate > div:nth-child(1), .css3-spinner-ball-rotate > div:nth-child(3) {
	width: 12px;
	height: 12px;
	border-radius: 100%;
	content: "";
	position: absolute;
	opacity: 0.7;
}

.css3-spinner-ball-rotate > div:nth-child(1) {
	top: 0px;
	left: -22px;
}

.css3-spinner-ball-rotate > div:nth-child(3) {
	top: 0px;
	left: 22px;
}


@-webkit-keyframes ballrotate {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }

  50% {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg); }

  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg); }
}

@keyframes ballrotate {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }

  50% {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg); }

  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg); }
}


.css3-spinner > .css3-spinner-zig-zag {
	background-color: transparent !important;
	-webkit-transform: translate(-15px, -15px);
	-ms-transform: translate(-15px, -15px);
	transform: translate(-15px, -15px);
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-zig-zag > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	position: absolute;
	margin-left: 15px;
	top: 4px;
	left: -7px;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner-zig-zag > div:first-child {
	-webkit-animation: ball-zig-deflect 1.5s 0s infinite linear;
	animation: ball-zig-deflect 1.5s 0s infinite linear;
}

.css3-spinner-zig-zag > div:last-child {
	-webkit-animation: ball-zag-deflect 1.5s 0s infinite linear;
	animation: ball-zag-deflect 1.5s 0s infinite linear;
}


@-webkit-keyframes ball-zig-deflect {
  17% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  34% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  84% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@keyframes ball-zig-deflect {
  17% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  34% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  84% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@-webkit-keyframes ball-zag-deflect {
  17% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  34% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  84% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@keyframes ball-zag-deflect {
  17% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  34% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  84% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}


.css3-spinner > .css3-spinner-ball-scale-multiple {
	width: 60px;
	height: 60px;
	margin-left: -30px;
	margin-top: -30px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-ball-scale-multiple > div {
	background-color: #DDD;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 60px;
	height: 60px;
	-webkit-animation: ball-scale-multiple 1.25s 0s linear infinite;
	animation: ball-scale-multiple 1.25s 0s linear infinite;
}

.css3-spinner-ball-scale-multiple > div:nth-child(2) {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}

.css3-spinner-ball-scale-multiple > div:nth-child(3) {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}

@-webkit-keyframes ball-scale-multiple {
  0% {
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0; }

  5% { opacity: 1; }

  100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0; }
}

@keyframes ball-scale-multiple {
  0% {
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0; }

  5% { opacity: 1; }

  100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0; }
}


.css3-spinner > .css3-spinner-triangle-path {
	background-color: transparent !important;
	-webkit-transform: translate(-29.994px, -37.50938px);
	-ms-transform: translate(-29.994px, -37.50938px);
	transform: translate(-29.994px, -37.50938px);
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-triangle-path > div {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #DDD;
}

.css3-spinner-triangle-path > div:nth-child(1) {
	-webkit-animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-child(2) {
	-webkit-animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-child(3) {
	-webkit-animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-of-type(1) { top: 50px; }

.css3-spinner-triangle-path > div:nth-of-type(2) { left: 25px; }

.css3-spinner-triangle-path > div:nth-of-type(3) {
	top: 50px;
	left: 50px;
}

@-webkit-keyframes ball-triangle-path-1 {
  33% {
	-webkit-transform: translate(25px, -50px);
	transform: translate(25px, -50px); }

  66% {
	-webkit-transform: translate(50px, 0px);
	transform: translate(50px, 0px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-1 {
  33% {
	-webkit-transform: translate(25px, -50px);
	transform: translate(25px, -50px); }

  66% {
	-webkit-transform: translate(50px, 0px);
	transform: translate(50px, 0px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@-webkit-keyframes ball-triangle-path-2 {
  33% {
	-webkit-transform: translate(25px, 50px);
	transform: translate(25px, 50px); }

  66% {
	-webkit-transform: translate(-25px, 50px);
	transform: translate(-25px, 50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-2 {
  33% {
	-webkit-transform: translate(25px, 50px);
	transform: translate(25px, 50px); }

  66% {
	-webkit-transform: translate(-25px, 50px);
	transform: translate(-25px, 50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@-webkit-keyframes ball-triangle-path-3 {
  33% {
	-webkit-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px); }

  66% {
	-webkit-transform: translate(-25px, -50px);
	transform: translate(-25px, -50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-3 {
  33% {
	-webkit-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px); }

  66% {
	-webkit-transform: translate(-25px, -50px);
	transform: translate(-25px, -50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}


.css3-spinner > .css3-spinner-ball-pulse-sync {
	width: 70px;
	height: 34px;
	margin-left: -35px;
	margin-top: -17px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-ball-pulse-sync > div {
	display: inline-block;
	background-color: #DDD;
	width: 14px;
	height: 14px;
	margin: 10px 3px 0;
	border-radius: 100%;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner-ball-pulse-sync > div:nth-child(0) {
	-webkit-animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(1) {
	-webkit-animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(2) {
	-webkit-animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(3) {
	-webkit-animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
}


@-webkit-keyframes ball-pulse-sync {
  33% {
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	opacity: 0.85;
	}

  66% {
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	opacity: 0.7;
	}

  100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	}
}

@keyframes ball-pulse-sync {
  33% {
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	opacity: 0.85;
	}

  66% {
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	opacity: 0.7;
	}

  100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	}
}


.css3-spinner > .css3-spinner-scale-ripple {
	width: 50px;
	height: 50px;
	margin-left: -25px;
	margin-top: -25px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-scale-ripple > div {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	position: absolute;
	top: 0;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	border: 2px solid #DDD;
	-webkit-animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(.21, .53, .56, .8);
	animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(.21, .53, .56, .8);
}

.css3-spinner-scale-ripple > div:nth-child(0) {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

.css3-spinner-scale-ripple > div:nth-child(1) {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}

.css3-spinner-scale-ripple > div:nth-child(2) {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}

.css3-spinner-scale-ripple > div:nth-child(3) {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}


@-webkit-keyframes ball-scale-ripple-multiple {
  0% {
	-webkit-transform: scale(0.1);
			transform: scale(0.1);
	opacity: 1; }

  70% {
	-webkit-transform: scale(1);
			transform: scale(1);
	opacity: 0.7; }

  100% {
	opacity: 0.0; }
}

@keyframes ball-scale-ripple-multiple {
  0% {
	-webkit-transform: scale(0.1);
			transform: scale(0.1);
	opacity: 1; }

  70% {
	-webkit-transform: scale(1);
			transform: scale(1);
	opacity: 0.7; }

  100% {
	opacity: 0.0; }
}



/* ----------------------------------------------------------------
	HTML5 Youtube Video Backgrounds
-----------------------------------------------------------------*/


.mb_YTVPlayer:focus { outline: 0; }

.mb_YTVPlayer {
	display:block;
	transform:translateZ(0);
	transform-style: preserve-3d;
	perspective: 1000;
	backface-visibility: hidden;
	transform:translate3d(0,0,0);
	animation-timing-function: linear;
}

.mb_YTVPlayer.fullscreen {
	display:block!important;
	position: fixed!important;
	width: 100%!important;
	height: 100%!important;
	top: 0!important;
	left: 0!important;
	margin: 0!important;
	border: none !important;
}

.mb_YTVPlayer.fullscreen .mbYTP_wrapper { opacity:1 !important; }


.mbYTP_wrapper iframe { max-width: 4000px !important; }

.inline_YTPlayer {
	margin-bottom: 20px;
	vertical-align:top;
	position:relative;
	left:0;
	overflow: hidden;
	border-radius: 4px;
	-moz-box-shadow: 0 0 5px rgba(0,0,0,.7);
	-webkit-box-shadow: 0 0 5px rgba(0,0,0,.7);
	box-shadow: 0 0 5px rgba(0,0,0,.7);
	background: rgba(0,0,0,.5);
}

.inline_YTPlayer img {
	border: none!important;
	-moz-transform: none!important;
	-webkit-transform: none!important;
	-o-transform: none!important;
	transform: none!important;
	margin:0!important;
	padding:0!important
}

/* ----------------------------------------------------------------
	Datepicker Component
-----------------------------------------------------------------*/

.datepicker td,
.datepicker th { padding: 5px; }

/* ----------------------------------------------------------------
	Star Ratings Component
-----------------------------------------------------------------*/

.rating-xl { font-size: 3rem !important; }

.rating-lg { font-size: 2.5rem !important; }

.rating-md { font-size: 2rem !important; }

.rating-sm { font-size: 1.75rem !important; }

.rating-xs { font-size: 1.25rem !important; }

.rating-container .star { margin: 0 2px !important; }

.rating-container .filled-stars {
	-webkit-text-stroke: 0px !important;
	text-shadow: none !important;
}


.irs-bar,
.irs-from,
.irs-to,
.irs-single,
.irs-handle > i:first-child,
.irs-handle.state_hover > i:first-child,
.irs-handle:hover > i:first-child { background-color: #1ABC9C !important; }

.irs-from::before,
.irs-to::before,
.irs-single::before { border-top-color: #1ABC9C !important; }




/* ----------------------------------------------------------------
	Custom Animate.css Styles
-----------------------------------------------------------------*/

@-webkit-keyframes fadeInDownSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInDownSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInDownSmall {
	-webkit-animation-name: fadeInDownSmall;
	animation-name: fadeInDownSmall;
}


@-webkit-keyframes fadeInLeftSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInLeftSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInLeftSmall {
	-webkit-animation-name: fadeInLeftSmall;
	animation-name: fadeInLeftSmall;
}


@-webkit-keyframes fadeInRightSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInRightSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInRightSmall {
	-webkit-animation-name: fadeInRightSmall;
	animation-name: fadeInRightSmall;
}


@-webkit-keyframes fadeInUpSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInUpSmall {
	-webkit-animation-name: fadeInUpSmall;
	animation-name: fadeInUpSmall;
}


@-webkit-keyframes fadeOutDownSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}
}

@keyframes fadeOutDownSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}
}

.fadeOutDownSmall {
	-webkit-animation-name: fadeOutDownSmall;
	animation-name: fadeOutDownSmall;
}


@-webkit-keyframes fadeOutLeftSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}
}

@keyframes fadeOutLeftSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}
}

.fadeOutLeftSmall {
	-webkit-animation-name: fadeOutLeftSmall;
	animation-name: fadeOutLeftSmall;
}


@-webkit-keyframes fadeOutRightSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}
}

@keyframes fadeOutRightSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}
}

.fadeOutRightSmall {
	-webkit-animation-name: fadeOutRightSmall;
	animation-name: fadeOutRightSmall;
}


@-webkit-keyframes fadeOutUpSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}
}

@keyframes fadeOutUpSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}
}

.fadeOutUpSmall {
	-webkit-animation-name: fadeOutUpSmall;
	animation-name: fadeOutUpSmall;
}


